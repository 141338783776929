import { FunctionComponent } from "react"
import { ChildrenProps } from "@typedefs/props"

import { useCustomerProfileClientHook } from "./hook"
import { CustomerProfileClientContext } from "./useCustomerProfileContext"

type Props = ChildrenProps

const CustomerProfileClientProvider: FunctionComponent<Props> = ({
  children,
}) => {
  const customerProfileClient = useCustomerProfileClientHook()

  return (
    <CustomerProfileClientContext.Provider value={customerProfileClient}>
      {children}
    </CustomerProfileClientContext.Provider>
  )
}

export default CustomerProfileClientProvider
