import { FunctionComponent } from "react"
import { ChildrenProps } from "@typedefs/props"

import { useForbidAuthedAgentRouteHook } from "./hook"

const ForbidAuthedAgentRoute: FunctionComponent<ChildrenProps> = ({
  children,
}) => {
  useForbidAuthedAgentRouteHook()

  return <>{children}</>
}

export default ForbidAuthedAgentRoute
