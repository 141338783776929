/**
 * Update a native URLSearchParams object with a key than can be string or array of strings
 * Used by search components, to reflect the search state in the URL
 */
export function updateSearchParams(
  params: URLSearchParams,
  key: string,
  values?: string[] | string,
) {
  if (!values) {
    params.delete(key)

    return
  }
  if (Array.isArray(values)) {
    if (values.length === 0) {
      params.delete(key)

      return
    }
    params.delete(key) // needed if the current URL already has the key
    values.forEach((value) => params.append(key, value))

    return
  }
  params.set(key, values as string)
}
