import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAxiosClientContext } from "@components/core/AxiosInstanceProvider/useAxiosClient"
import { useCustomerProfileClientContext } from "@components/core/CustomerProfileClientProvider/useCustomerProfileContext"
import { config } from "@config/index"
import {
  agentAtom,
  agentStatusTypeAtom,
  campaignNameAtom,
  caseIdAtom,
  currentContactIdAtom,
  customerProfileAtom,
  customerProfileIdAtom,
  isCustomerProfileLoadingAtom,
  phoneNumberAtom,
  UndocumentedAgentStateType,
} from "@helpers/atoms"
import { findCurrentContact } from "@helpers/contact"
import { hasValue, isDefined, isNull, isObject } from "@helpers/typeguards"
import { useConnectActionsHook } from "@hooks/connectActions"
import { useQueryAPI } from "@hooks/query-api"
import { useReactQueryOptions } from "@hooks/reactQueryOptions"
import { useCallPanelLogger } from "@hooks/useLogger"
import { useQuery } from "@tanstack/react-query"
import { useAtom, useAtomValue } from "jotai"

import { useManualCallAtom } from "@/hooks/useManualCallAtom"

import { getCaseQueryParams } from "./case_params"
import * as value from "./value"

type Initializing = {
  current: "initializing"
}

type AvailableMode = {
  current: "available"
}

type BusyMode = {
  current: "busy"
  state: {
    caseStatuses: value.Decoder.CaseStatuses | null
    currentCase: value.Decoder.CaseAndActivityFeeds | null
    customerProfile: value.Decoder.CustomerProfile | null
    isLoading: boolean
  }
}

type OfflineMode = {
  current: "offline"
  state: {
    caseStatuses: value.Decoder.CaseStatuses | null
  }
}

type ResetMode = {
  current: "reset"
  state: {
    currentCase: value.Decoder.CaseAndActivityFeeds | null
  }
}

export type AgentDisplayMode =
  | AvailableMode
  | BusyMode
  | Initializing
  | OfflineMode
  | ResetMode

interface CustomPanelHookType {
  displayMode: AgentDisplayMode
  errorMessage?: string
}

const useCustomPanelHook = (): CustomPanelHookType => {
  const { t } = useTranslation()
  const axiosClient = useAxiosClientContext()
  const { searchProfiles } = useCustomerProfileClientContext()
  const { endTask } = useConnectActionsHook()
  const { retry } = useReactQueryOptions()
  const log = useCallPanelLogger()

  useManualCallAtom()

  const agent = useAtomValue(agentAtom)
  const agentStatusType = useAtomValue(agentStatusTypeAtom)
  const currentContactId = useAtomValue(currentContactIdAtom)
  const customerProfileId = useAtomValue(customerProfileIdAtom)

  const caseId = useAtomValue(caseIdAtom)
  const phoneNumber = useAtomValue(phoneNumberAtom)
  const campaignName = useAtomValue(campaignNameAtom)

  const currentContact = useMemo(() => {
    if (isNull(agent) || isNull(currentContactId)) {
      return null
    }

    const foundContact = findCurrentContact({ agent, currentContactId })

    return foundContact ?? null
  }, [agent, currentContactId])

  const currentTaskId = useMemo(() => {
    if (currentContact?.getType() === connect.ContactType.TASK) {
      return currentContact.getContactId()
    }

    return null
  }, [currentContact])

  const contactStatus = currentContact?.getStatus().type

  const [isCustomerProfileLoading, setIsCustomerProfileLoading] = useAtom(
    isCustomerProfileLoadingAtom,
  )

  const [customerProfile, setCustomerProfile] = useAtom(customerProfileAtom)
  const customerProfilePhoneNumber = customerProfile?.PhoneNumber ?? null // unique value

  const [displayMode, setDisplayMode] = useState<AgentDisplayMode>({
    current: "initializing",
  })

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  )

  const queueIsEmptyTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined)
  const loadingTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined)

  const isDataLoading =
    displayMode.current === "busy" && displayMode.state.isLoading

  // Automatically stop the task if the customer profile or case id is not found
  // and move to the next task in the queue
  useEffect(() => {
    const shouldStopTask =
      hasValue(currentTaskId) &&
      contactStatus === connect.ContactStateType.CONNECTED &&
      (isNull(caseId) || isNull(customerProfileId))

    if (shouldStopTask) {
      const message = `No ${
        isNull(caseId) ? "case" : "customer profile"
      } id found for task ${currentTaskId}`

      Promise.resolve(endTask())
        .then(() => log.warn(message))
        .catch((err) => log.error(err))
    }
  }, [caseId, customerProfileId, currentTaskId, contactStatus]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!customerProfileId && !phoneNumber) {
      return
    }

    const storeCustomerProfile = async () => {
      const queryOptions = customerProfileId
        ? {
            DomainName: config.customerProfilesDomain,
            KeyName: "_profileId",
            Values: [customerProfileId],
            AdditionalSearchKeys: phoneNumber
              ? [
                  {
                    KeyName: "_phone",
                    Values: [phoneNumber],
                  },
                ]
              : undefined,
            LogicalOperator: "OR",
          }
        : {
            DomainName: config.customerProfilesDomain,
            KeyName: "_phone",
            Values: phoneNumber ? [phoneNumber] : undefined,
          }

      try {
        // When stopping a task we also might erase the customer profile if there is no duplicate
        // so this query might return an empty array in edge cases.
        const profiles = await searchProfiles(queryOptions)

        const validProfile: value.Decoder.CustomerProfile[] =
          value.Decoder.customerProfilesSchema.parse(profiles)

        if (validProfile.length > 1) {
          log.warn(
            `Warning: Customer with profile id ${customerProfileId} and phone number ${phoneNumber} has more than one customer profile. Will try to select best one.`,
          )
        }
        let selectedProfile: value.Decoder.CustomerProfile
        const populatedProfiles = validProfile.filter((profile) =>
          isDefined(profile?.FirstName),
        )
        if (populatedProfiles.length === 0) {
          selectedProfile = validProfile[0]
          log.warn(
            `No Customer with profile id ${customerProfileId} and phone number ${phoneNumber} had a Name attribute. Defaulting to first profile found.`,
          )
        } else {
          selectedProfile = populatedProfiles[0]
        }

        setCustomerProfile(selectedProfile)
        setIsCustomerProfileLoading(false)
      } catch (err) {
        setCustomerProfile(null)
        setIsCustomerProfileLoading(false)
        log.error(err)

        if (err instanceof Error) {
          if (err.message === "client-not-ready") {
            setErrorMessage(err.message)
          }
        }
      }
    }

    storeCustomerProfile()
  }, [customerProfileId, phoneNumber]) // eslint-disable-line react-hooks/exhaustive-deps

  const queryFn = async () => {
    try {
      const queryParams = getCaseQueryParams({
        campaignName,
        caseId,
        phoneNumber,
      })

      const caseResponse =
        await axiosClient.get<value.Decoder.CaseInformationResponse>(
          `https://${config.apiEndpoint}/case`,
          { params: queryParams },
        )

      const validResponse =
        await value.Decoder.caseInformationSchema.parseAsync(caseResponse.data)

      return validResponse.data
    } catch (err) {
      throw new Error(`Failed to fetch case data: ${(err as Error).message}`, {
        cause: err,
      })
    }
  }

  const isQueryEnabled =
    hasValue(caseId) || (hasValue(campaignName) && hasValue(phoneNumber))

  const {
    data: currentCase,
    isFetching: isCurrentCaseFetching,
    isLoading: isCurrentCaseLoading,
  } = useQuery({
    queryKey: ["currentCase", caseId, phoneNumber],
    queryFn,
    initialData: null,
    enabled: isQueryEnabled,
    retry,
  })

  const {
    data: caseStatuses,
    isFetching,
    isLoading,
  } = useQueryAPI({
    queryKey: ["caseStatuses"],
    apiEndPoint: "/case/statuses",
    schema: value.Decoder.caseStatusesResponseSchema,
    options: { initialData: null },
  })

  const currentCaseId = currentCase?.case._id ?? null
  const isCaseLoading = isCurrentCaseLoading || isCurrentCaseFetching
  const isCaseStatusesLoading = isLoading || isFetching
  const hasCaseStatuses = isObject(caseStatuses)

  useEffect(
    () => {
      const isNotLoading =
        !isCustomerProfileLoading && !isCaseLoading && !isCaseStatusesLoading
      const isLoading = !isNotLoading

      switch (agentStatusType) {
        case connect.AgentStateType.ROUTABLE:
          // hardcoded isLoading = true; when contact ends the inbound call and there is no more contact id
          // we must show the loading screen
          setDisplayMode(() => ({
            current: "busy",
            state: {
              isLoading: currentContactId ? isLoading : true,
              customerProfile,
              currentCase,
              caseStatuses: caseStatuses?.data ?? null,
            },
          }))
          break

        // The busy agent status type seems appear throughout the outbound call
        case UndocumentedAgentStateType.SYSTEM:
        case UndocumentedAgentStateType.ERROR:
          setDisplayMode(() => ({
            current: "busy",
            state: {
              isLoading,
              customerProfile,
              currentCase,
              caseStatuses: caseStatuses?.data ?? null,
            },
          }))
          break

        case connect.AgentStateType.OFFLINE:
          setDisplayMode(() => ({
            current: "offline",
            state: {
              caseStatuses: caseStatuses?.data ?? null,
            },
          }))
          break

        // connect.AgentStateType.INIT
        // connect.AgentStateType.NOT_ROUTABLE
        default:
          setDisplayMode(() => ({
            current: "initializing",
          }))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      agentStatusType,
      currentContactId,
      isCustomerProfileLoading,
      isCaseLoading,
      isCaseStatusesLoading,
      // Replace object references with an unique primitive on the object.
      // case statuses does not change, it's a hardcoded list of statuses. Once non null, should be fine
      currentCaseId,
      customerProfilePhoneNumber,
      hasCaseStatuses,
    ],
  )

  // Start a timer to show the Empty queue view if no tasks are incoming
  useEffect(() => {
    if (agentStatusType === connect.AgentStateType.ROUTABLE) {
      queueIsEmptyTimeoutRef.current = setTimeout(() => {
        if (!currentContactId) {
          setDisplayMode(() => ({ current: "available" }))
          // Stop the timer that should show the panel reset button
          clearTimeout(loadingTimeoutRef.current)
        }
      }, config.emptyQueueOffset)
    }

    return () => {
      clearTimeout(queueIsEmptyTimeoutRef.current)
    }
  }, [agentStatusType, currentContactId])

  // Start a timer to show a reset button if loading takes too long
  useEffect(() => {
    loadingTimeoutRef.current = setTimeout(() => {
      if (isDataLoading) {
        setDisplayMode(() => ({
          current: "reset",
          state: { currentCase },
        }))

        // Stop the timer that should display the empty queue view
        clearTimeout(queueIsEmptyTimeoutRef.current)
      }
    }, config.resetPanelTimeout)

    return () => {
      clearTimeout(loadingTimeoutRef.current)
    }
  }, [isDataLoading, currentCase])

  return {
    displayMode,
    errorMessage:
      errorMessage === "delayed-api-response"
        ? t("errors.ccp.profileResponseTimeout")
        : undefined,
  }
}

export { useCustomPanelHook }
