import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import Icon, { IconName } from "@components/Icon"

interface Props {
  emptyText?: string
  iconName?: IconName
}

const styles = {
  emptyContent:
    "h-[200px] flex flex-col items-center justify-center bg-neutral-100 text-primary-200 font-body-xlarge-bold gap-y-4",
}

const EmptyContainer: FunctionComponent<Props> = ({ emptyText, iconName }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.emptyContent}>
      {iconName && <Icon name={iconName} size="md" />}
      <p>{emptyText || t("global.emptySubtitle")}</p>
    </div>
  )
}

export default EmptyContainer
