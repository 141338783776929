import { idSchema } from "@helpers/zodSchemas"
import { z } from "zod"

namespace Decoder {
  export const userInformationSchema = z.object({
    arn: z.string(),
    email: z.string().email(),
    id: idSchema,
    identity_info: z.object({
      first_name: z.string(),
      last_name: z.string(),
    }),
    phone_config: z.object({
      after_contact_work_time_limit: z.number(),
      auto_accept: z.boolean(),
      desk_phone_number: z.string(),
      phone_type: z.enum(["SOFT_PHONE", "DESK_PHONE"]),
    }),
    security_profile_names: z.string().array().nonempty(),
    // tags: z.object({})
  })

  export type ConnectUserInformation = z.infer<typeof userInformationSchema>

  export const userInformationResponseSchema = z.object({
    data: userInformationSchema,
    reqId: idSchema,
    success: z.string(),
  })

  export type UserInformationResponse = z.infer<
    typeof userInformationResponseSchema
  >
}

export { Decoder }
