import {
  FieldErrors,
  FieldPath,
  FieldValues,
  UseFormClearErrors,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Locale, subHours } from "date-fns"
import { ParseKeys } from "i18next"

import { Lang } from "@/config"
import { getLocale, resolveLanguage } from "@/helpers/i18n"
import { hasValue, isDefined } from "@/helpers/typeguards"
import { useErrorMessage } from "@/hooks/errorMessage"

type DatePickerInput<FieldsType extends FieldValues> = {
  clearErrors?: UseFormClearErrors<FieldsType>
  errorLabel?: ParseKeys
  errors?: FieldErrors<FieldsType>
  label?: ParseKeys
  maxDate?: Date
  minDate?: Date
  name: FieldPath<FieldsType>
  withTime: boolean
}

interface DatePickerHookType {
  dateFormat: string
  error: string | undefined
  filterTime: (date: Date) => boolean
  locale: Locale
  onFocus: VoidFunction
  placeholder: string
  timeCaption: string
  timeFormat: string | undefined
}

const getDateFormat = (language: Lang, withTime: boolean): string => {
  if (language === "ja") {
    return withTime ? "yyyy年MM月dd日 HH:mm" : "yyyy年MM月dd日"
  }

  return withTime ? "yyyy/MM/dd HH:mm" : "yyyy/MM/dd"
}

const useDatePickerHook = <FieldsType extends FieldValues>({
  clearErrors,
  errorLabel,
  errors,
  label,
  maxDate,
  minDate,
  name,
  withTime,
}: DatePickerInput<FieldsType>): DatePickerHookType => {
  const { t } = useTranslation()
  const onFocus = () => {
    clearErrors && clearErrors(name)
  }

  const language = resolveLanguage()
  const dateFormat = getDateFormat(language, withTime)

  const timeFormat = withTime ? "HH:mm" : undefined

  const placeholder = t("global.datepicker.placeholder")

  const timeCaption = t("global.datepicker.timeLabel")

  const error = useErrorMessage(
    name,
    errors,
    isDefined(errorLabel) ? t(errorLabel) : isDefined(label) ? t(label) : name,
  )

  const filterTime = (time: Date) => {
    if (hasValue(minDate) && hasValue(maxDate)) {
      const newMaxDate = subHours(new Date(maxDate), 1)

      return time >= minDate && time <= newMaxDate
    }

    if (hasValue(maxDate)) {
      const newMaxDate = subHours(new Date(maxDate), 1)

      return time <= newMaxDate
    }

    if (hasValue(minDate)) {
      return time >= minDate
    }

    return true
  }

  return {
    onFocus,
    dateFormat,
    timeFormat,
    locale: getLocale(),
    placeholder,
    error,
    timeCaption,
    filterTime,
  }
}

export { useDatePickerHook }
