import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Icon from "@components/Icon"

import { routesConfig } from "@/config/routes"

interface Props {
  errorDescription?: string
  errorMessage: string
  errorStatus: number
}

const styles = {
  pageContainer:
    "w-full h-full px-8 py-36 flex flex-col gap-y-8 text-neutral-700",
  status: "font-body-large-regular",
  message: "font-h1-bold",
  description: "font-body-large-regular",
  linkContainer: "max-w-fit",
  backContainer:
    "flex items-center gap-x-1 p-1 text-primary-300 font-button-small",
}

const NotFoundError: FunctionComponent<Props> = ({
  errorDescription,
  errorMessage,
  errorStatus,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.pageContainer} data-sentry-unmask>
      <div>
        <Icon size="logo-size" name="logo" />
      </div>
      {errorStatus && <p className={styles.status}>{errorStatus}</p>}
      {errorMessage && <h1 className={styles.message}>{errorMessage}</h1>}
      {errorDescription && (
        <p className={styles.description}>{errorDescription}</p>
      )}
      <div className={styles.linkContainer}>
        <Link to={routesConfig.AUTOMATIC_CALL_PANEL}>
          <div className={styles.backContainer}>
            <Icon name="chevron" size="sm" />
            <span>{t("errors.errorPage.goBack")}</span>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default NotFoundError
