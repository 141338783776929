import { z } from "zod"

import { statusSchema } from "@/models/cases"

import { useQueryAPI } from "./query-api"

export type CallStatusDef = z.infer<typeof statusSchema>

export function useFetchAllCallStatuses() {
  return useQueryAPI({
    apiEndPoint: "/case/statuses/v2",
    schema: z.object({
      data: z.array(statusSchema),
    }),
  })
}
