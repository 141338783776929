import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { AlertCircle } from "lucide-react"
import { ZodError } from "zod"

import { cn } from "@/helpers/classNames"

const alertVariants = cva(
  "relative w-full rounded-lg border p-4 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground [&>svg~*]:pl-7",
  {
    variants: {
      variant: {
        default: "bg-background text-foreground",
        destructive:
          "border-danger-100 text-danger-300 dark:border-danger-300 [&>svg]:text-danger-300",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
)

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(alertVariants({ variant }), className)}
    {...props}
  />
))
Alert.displayName = "Alert"

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn("mb-1 font-medium leading-none tracking-tight", className)}
    {...props}
  />
))
AlertTitle.displayName = "AlertTitle"

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("text-sm [&_p]:leading-relaxed", className)}
    {...props}
  />
))
AlertDescription.displayName = "AlertDescription"

/**
 * Used to handle the error state when calling the API with `useQuery*` hooks
 * (not part of shadcn/ui)
 */
export function AlertLoadingError({
  className,
  error,
  title,
}: {
  className?: string
  error: unknown
  title: string
}) {
  return (
    <Alert variant="destructive" className={className}>
      <AlertCircle className="size-5" />
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>
        {error instanceof ZodError && <p>Invalid API response format</p>}
        {error instanceof Error && error.message && <p>{error.message}</p>}
      </AlertDescription>
    </Alert>
  )
}

export { Alert, AlertDescription, AlertTitle }
