import { useEffect, useState } from "react"

import { Button, type ButtonProps } from "@/components/ui/button"

/** React button component that accounts for internal delays in state changes within the iframe.
 * The CCP iframe takes time to figure out its' state change and we need to give it a little bit of time to do so. by adding a timeout
 * we can ensure that state such as connection.isOnHold has been properly updated before proceeding to toggle the hold status.
 *
 * @param delay: delay in milliseconds before the button is disabled
 * @param onClick: function to be called when the button is clicked
 * @param isDisabled: if the button is disabled
 * @param children: button content
 * @param restProps: rest of the props to be passed to the button component
 * @returns
 */
function DelayedClickButton({
  children,
  delay = 1000,
  isDisabled,
  onClick,
  ...restProps
}: ButtonProps & { delay?: number; onClick: () => Promise<void> }) {
  const [status, setStatus] = useState<"disabled" | "init" | "inProgress">(
    "init",
  )

  const isButtonDisabled = status === "disabled"

  const handleClick = async () => {
    setStatus("inProgress")
    await onClick()
    setStatus("disabled")
  }

  useEffect(() => {
    if (isButtonDisabled) {
      setTimeout(() => setStatus("init"), delay)
    }
  }, [isButtonDisabled]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Button
      {...restProps}
      onClick={handleClick}
      isDisabled={isButtonDisabled || isDisabled}
    >
      {children}
    </Button>
  )
}

export { DelayedClickButton }
