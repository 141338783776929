import { FunctionComponent } from "react"
import { Controller } from "react-hook-form"
import { Link } from "react-router-dom"
import Input from "@components/core/Input"
import Icon from "@components/Icon"
import { Button } from "@components/ui/button"
import { cn } from "@helpers/classNames"
import { useFormValidation } from "@hooks/formValidation"
import { TooltipTrigger } from "@radix-ui/react-tooltip"

import { Tooltip, TooltipContent } from "@/components/ui/tooltip"
import { useI18n } from "@/helpers/i18n"

import { Mode, useResetPasswordConfirmHook } from "./hook"

const styles = {
  wrapper: cn("flex min-h-screen justify-center bg-neutral-200 py-20"),
  container: cn(
    "flex size-full min-h-fit flex-col items-center justify-start gap-y-6 bg-neutral-200 px-4 sm:w-2/3 lg:w-2/5",
  ),
  brand: cn("mb-2"),
  textContainer: cn("flex flex-col items-center gap-y-2"),
  title: cn("font-body-xlarge-bold text-neutral-600"),
  subtitle: cn("font-body-medium-regular text-neutral-600"),
  confirmResultContainer: (mode: Mode) =>
    cn(
      "font-h4-bold flex items-center gap-x-1 rounded border px-4 py-6 text-neutral-100",
      mode.current === "success" && "border-success-300 bg-success-200",
      mode.current === "error" && "border-danger-300 bg-danger-200",
    ),
  formContainer: cn(
    "flex w-full min-w-fit flex-col items-center justify-center gap-y-8 rounded border border-neutral-300 bg-neutral-100 p-8 shadow-card",
  ),
  inputsContainer: (isDisabled: boolean) =>
    cn([
      "flex w-full flex-col gap-y-4",
      isDisabled && "pointer-events-none opacity-50",
    ]),
  inputLabel: cn("font-label-small mb-1 text-neutral-600"),
  changeInputTypeIcon: cn("size-4 cursor-pointer text-primary-300"),
  submit: cn("w-full max-w-[240px]"),
  forgotContainer: cn("font-body-medium-regular text-center text-neutral-600"),
  contactManager: cn("font-body-medium-bold"),
  requirementsList: cn(
    "font-body-small-bold flex list-disc flex-col gap-y-1 text-neutral-600",
  ),
  requirementsSubList: cn(
    "font-body-small-bold ml-4 flex list-disc flex-col gap-y-1 text-neutral-600",
  ),
  goBackLink: cn("font-button-small p-1 text-primary-300"),
  infoIcon: cn("text-primary-200"),
  tooltipContainer: cn("flex items-center gap-x-1"),
}

const tooltipText =
  "^ $ * . [ ] { } ( ) ? \" ! @ # % & /  , > < ' : ; | _ ~ ` = + -"

const ResetPasswordConfirm: FunctionComponent = () => {
  const {
    confirmPasswordInputType,
    form,
    isDisabled,
    isLoading,
    isMatchingPassword,
    mode,
    onChangeConfirmPasswordInputType,
    onChangePasswordInputType,
    onSubmit,
    passwordInputType,
  } = useResetPasswordConfirmHook()
  const { t, tAsArray, tMap } = useI18n()

  const { isValidPassword } = useFormValidation()
  const shouldRestartProcess = mode.current === "error"

  const {
    clearErrors,
    control,
    formState: { errors },
  } = form

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.brand}>
          <Icon name="logo" size="logo-size" />
        </div>
        <div className={styles.textContainer} data-sentry-unmask>
          <p className={styles.title}>{t("resetPassword.confirm.title")}</p>
          <p className={styles.subtitle}>
            {t("resetPassword.confirm.subtitle")}
          </p>
          <ul className={styles.requirementsList}>
            {tMap("resetPassword.confirm.bulletPoints", (value, i) => (
              <li key={`${value}-${i}`}>{value}</li>
            ))}
            <ul className={styles.requirementsSubList}>
              {tAsArray("resetPassword.confirm.subBulletPoints").map(
                (value, i, self) => {
                  if (i === self.length - 1) {
                    return (
                      <li key={`${value}-${i}`}>
                        <div className={styles.tooltipContainer}>
                          {value}
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <div>
                                <Icon
                                  name="info"
                                  size="sm"
                                  className={styles.infoIcon}
                                />
                              </div>
                            </TooltipTrigger>
                            <TooltipContent side="bottom">
                              {tooltipText}
                            </TooltipContent>
                          </Tooltip>
                        </div>
                      </li>
                    )
                  } else {
                    return <li key={`${value}-${i}`}>{value}</li>
                  }
                },
              )}
            </ul>
          </ul>
        </div>
        {mode.current === "error" && (
          <div
            className={styles.confirmResultContainer(mode)}
            data-sentry-unmask
          >
            <Icon name="alert" size="sm" />
            <h4>{mode.state.errorMessage}</h4>
          </div>
        )}
        {mode.current === "success" && (
          <div
            className={styles.confirmResultContainer(mode)}
            data-sentry-unmask
          >
            <Icon name="check" size="sm" />
            <h4>{t("resetPassword.confirm.success")}</h4>
          </div>
        )}
        <form className={styles.formContainer} onSubmit={onSubmit}>
          <div className={styles.inputsContainer(isLoading)}>
            <Controller
              control={control}
              rules={{
                required: true,
                validate: { isValidPassword, isMatchingPassword },
              }}
              name="password"
              render={({ field: { name, onBlur, onChange, ref, value } }) => (
                <Input
                  name={name}
                  label="login.passwordLabel"
                  labelClassName={styles.inputLabel}
                  type={passwordInputType}
                  placeholder="login.passwordPlaceholder"
                  onBlur={onBlur}
                  onChange={onChange}
                  errors={errors}
                  clearErrors={clearErrors}
                  forwardedRef={ref}
                  value={value}
                  target={
                    <Icon
                      name={passwordInputType === "text" ? "eye" : "eye-slash"}
                      size="sm"
                      className={styles.changeInputTypeIcon}
                      onClick={onChangePasswordInputType}
                    />
                  }
                  orientation="vertical"
                  targetPosition="right"
                />
              )}
            />
            <Controller
              control={control}
              rules={{
                required: true,
                validate: {
                  isValidPassword,
                  isMatchingPassword,
                },
              }}
              name="confirmPassword"
              render={({ field: { name, onBlur, onChange, ref, value } }) => (
                <Input
                  name={name}
                  label="resetPassword.confirm.confirmPasswordLabel"
                  labelClassName={styles.inputLabel}
                  type={confirmPasswordInputType}
                  placeholder="login.passwordPlaceholder"
                  onBlur={onBlur}
                  onChange={onChange}
                  errors={errors}
                  clearErrors={clearErrors}
                  forwardedRef={ref}
                  value={value}
                  target={
                    <Icon
                      name={
                        confirmPasswordInputType === "text"
                          ? "eye"
                          : "eye-slash"
                      }
                      size="sm"
                      className={styles.changeInputTypeIcon}
                      onClick={onChangeConfirmPasswordInputType}
                    />
                  }
                  orientation="vertical"
                  targetPosition="right"
                />
              )}
            />
          </div>
          {shouldRestartProcess ? (
            <div className={styles.goBackLink} data-sentry-unmask>
              <Link to="/login">
                <span>{t("errors.errorPage.goBack")}</span>
              </Link>
            </div>
          ) : (
            <Button
              size="lg"
              type="submit"
              className={styles.submit}
              isDisabled={isDisabled}
              isLoading={isLoading}
              loadingMessage={t("resetPassword.confirm.loadingText")}
            >
              {t("global.button.resetPassword")}
            </Button>
          )}
        </form>
        <div className={styles.forgotContainer} data-sentry-unmask>
          <p>{t("login.help")}</p>
          <p className={styles.contactManager}>{t("login.contactManager")}</p>
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordConfirm
