import { z } from "zod"

import { useQueryAPI } from "@/hooks/query-api"

export function useFetchAllFilterOptions() {
  return useQueryAPI({
    apiEndPoint: `/campaign-filters/options`,
    schema: CampaignFilterOptionsResponseSchema,
  })
}

const CampaignFilterOptionsResponseSchema = z.object({
  success: z.string(),
  data: z.object({
    department_groups: z.array(z.string()),
    grouped_universities: z.array(
      z.object({
        universities: z.array(z.string()),
        group_name: z.string(),
      }),
    ),
    channels_questions_answers: z.array(
      z.object({
        channel_title: z.string(),
        channel_id: z.number(),
      }),
    ),
    genders: z.array(z.string()),
    majors: z.array(z.string()),
  }),
})

type CampaignFilterOptionsResponse = z.infer<
  typeof CampaignFilterOptionsResponseSchema
>

export type UniversityGroup =
  CampaignFilterOptionsResponse["data"]["grouped_universities"][number]
