import { z } from "zod"

export const invalidDataSchema = z.object({
  message: z.string(),
  row_index: z.optional(z.number()), // no `row_index` for row header errors
  column_index: z.optional(z.number()),
  value: z.optional(z.string()),
})

export type InvalidData = z.infer<typeof invalidDataSchema>

export const uploadResponseDataSchema = z.object({
  invalid_data: z.array(invalidDataSchema),
  total_row_count: z.number(),
  updated_row_count: z.number(),
})

export type UploadResponseData = z.infer<typeof uploadResponseDataSchema>

export const uploadResponseSchema = z.object({
  data: uploadResponseDataSchema,
  success: z.string(),
  reqId: z.string(),
})

export type UploadResponse = z.infer<typeof uploadResponseSchema>
