import { useTranslation } from "react-i18next"

import { ColumnGroupDef, DashboardTable } from "@/components/DashboardTable"
import Spinner from "@/components/Spinner"
import { AlertLoadingError } from "@/components/ui/alert"
import { isUndefined } from "@/helpers/typeguards"

import { formatAgentColumnsFromMetadata, matchAgentName } from "../column-utils"
import { useFetchAgentsPerformance, useFilterParams } from "../hooks"
import OperationRow from "../operation-row"
import SearchLink from "../search-link"
import { Decoder } from "../value"

export function AgentMetricsContent() {
  const [searchParams, updateQueryParams] = useFilterParams()
  useTranslation() // needed to trigger a re-render when the language changes, showing the correct full names

  const { data, error, isError, isLoading } = useFetchAgentsPerformance(
    searchParams.dateRange,
  )

  const formattedMetrics = matchAgentName(
    data?.data.metrics,
    data?.data.agentMetadata || {},
  )

  const displayMetrics = isUndefined(searchParams.agent_id)
    ? formattedMetrics || []
    : formattedMetrics.filter(
        (item) => item.agentId === searchParams.agent_id,
      ) || []

  const agentOptions = (formattedMetrics || []).map((agent) => ({
    value: agent.agentId,
    label: typeof agent.fullName === "string" ? agent.fullName : agent.agentId,
  }))

  return (
    <div>
      <OperationRow
        onDateRangeChange={(val) => updateQueryParams({ dateRange: val })}
        options={agentOptions}
        dateRange={searchParams.dateRange}
        filterId={searchParams.agent_id || null}
        onFilter={(val) => updateQueryParams({ agent_id: val ?? undefined })}
      />
      {isLoading && <Spinner />}
      {isError && (
        <AlertLoadingError error={error} title="Unable to load the metrics" />
      )}
      {data && (
        <AgentPerformanceTable
          metadata={data?.data.metadata}
          parentMetadata={data?.data.parentMetricsMetadata}
          metrics={displayMetrics}
        />
      )}
    </div>
  )
}

function AgentPerformanceTable({
  metadata,
  metrics,
  parentMetadata,
}: {
  metadata: Decoder.MetaData
  metrics: Decoder.DisplayAgentMetric[]
  parentMetadata: Decoder.ParentMetaData
}) {
  const { t } = useTranslation()
  const firstColumn: ColumnGroupDef<Decoder.DisplayAgentMetric> = {
    header: t("performance.table.agentName"),
    cols: [
      {
        id: "agent",
        cell: (data) => (
          <SearchLink pathname={data.agentId}>{data.fullName}</SearchLink>
        ),
      },
    ],
  }
  const columnGroups =
    formatAgentColumnsFromMetadata<Decoder.DisplayAgentMetric>(
      metadata,
      parentMetadata,
      firstColumn,
    )

  return (
    <DashboardTable
      getItemId={(item) => item.agentId}
      columnGroups={columnGroups}
      items={metrics}
    />
  )
}
