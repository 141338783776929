import { FunctionComponent } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import ResetPasswordConfirm from "./Confirm"
import ResetPasswordRequest from "./Request"

const PasswordReset: FunctionComponent = () => {
  return (
    <Routes>
      <Route index element={<Navigate replace to="request" />} />
      <Route path="/request" element={<ResetPasswordRequest />} />

      <Route path="confirm">
        <Route index element={<Navigate replace to="/login" />} />
        <Route path=":verificationCode" element={<ResetPasswordConfirm />} />
      </Route>
    </Routes>
  )
}

export default PasswordReset
