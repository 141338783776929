const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

/** Equivalent of Lodash times() function */
export function times(n: number): number[] {
  return Array.from({ length: n }, (_, i) => i)
}

const throwErrorOnDelay = (delay: number) =>
  new Promise((_, reject) => {
    setTimeout(reject, delay, "delayed-api-response")
  })

/** Creates a duplicate-free version of an array */
export function unique<T>(array: T[]): T[] {
  return Array.from(new Set(array))
}

export { sleep, throwErrorOnDelay }
