import { Route, Routes } from "react-router-dom"

import { AdminRoute } from "@/components/core/Root/admin-route"

import { CreateCampaignPage } from "./edit/create-campaign-page"
import { EditCampaignPage } from "./edit/edit-campaign-page"
import { CampaignsListPage } from "./list/campaigns-list-page"
import { StudentDetailsPage } from "./student-details/student-details-page"
import { ViewCampaignPage } from "./view/view-campaign-page"

export function CampaignsRoot() {
  return (
    <Routes>
      <Route index element={<CampaignsListPage />} />
      <Route
        path="/create"
        element={
          <AdminRoute>
            <CreateCampaignPage />
          </AdminRoute>
        }
      />
      <Route path="/:campaign_id" element={<ViewCampaignPage />} />
      <Route
        path="/:campaign_id/edit"
        element={
          <AdminRoute>
            <EditCampaignPage />
          </AdminRoute>
        }
      />
      <Route
        path="/:campaign_id/students/:student_id"
        element={<StudentDetailsPage />}
      />
    </Routes>
  )
}
