import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { IconName } from "@components/Icon"
import { agentStatusTypeAtom } from "@helpers/atoms"
import { useAtomValue } from "jotai"

import { routesConfig } from "@/config/routes"
import { useAgentProfile } from "@/hooks/agent-profile"

type Route = {
  icon?: IconName
  isEnabled: () => boolean
  name: string
  path: string
  searchParams?: string
}

interface SidebarType {
  isHiddenSidebar: boolean
  navigationRoutes: Route[]
  pathName: string
}

const useSidebarHook = (): SidebarType => {
  const { t } = useTranslation()
  const { isManager } = useAgentProfile()

  const location = useLocation()

  const navigationRoutes: Route[] = [
    {
      name: t("sidebarMenu.performance"),
      path: "performance",
      icon: "dashboard",
      isEnabled: () => true,
    },
    {
      name: t("sidebarMenu.campaigns"),
      path: "campaigns",
      icon: "campaigns",
      isEnabled: () => true, // only create and edit routes are protected
    },
    {
      name: t("sidebarMenu.syncAgents"),
      path: "sync-agents",
      icon: "user-setting",
      isEnabled: () => isManager,
    },
    {
      name: t("sidebarMenu.callPanel"),
      path: routesConfig.AUTOMATIC_CALL_PANEL,
      icon: "workspace",
      isEnabled: () => true,
    },
  ]

  const agentStatusType = useAtomValue(agentStatusTypeAtom)

  const isHiddenSidebar =
    agentStatusType === "init" ||
    (agentStatusType !== "offline" &&
      location.pathname === routesConfig.AUTOMATIC_CALL_PANEL)

  return {
    navigationRoutes,
    pathName:
      location.pathname === routesConfig.AUTOMATIC_CALL_PANEL
        ? location.pathname
        : location.pathname.slice(1),
    isHiddenSidebar,
  }
}

export { useSidebarHook }
