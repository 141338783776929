import { useTranslation } from "react-i18next"

export function useI18n() {
  const { t } = useTranslation()

  const tAsArray = (key: string) => {
    const messages = t(key, { returnObjects: true })
    if (!Array.isArray(messages)) {
      throw new Error(`Expected array of strings for "${key}"`)
    }

    return messages as string[]
  }

  const tMap = (
    key: string,
    mapper: (message: string, index: number) => React.ReactNode,
  ) => {
    const messages = tAsArray(key)

    return messages.map(mapper)
  }

  return { t, tAsArray, tMap }
}
