/* eslint-disable react-refresh/only-export-components */
import { createContext, useContext } from "react"
import toast from "react-hot-toast"
import { useLifecycles, useUpdate } from "react-use"
import { setTag } from "@sentry/react"

import { config } from "@/config"

import { AppUpdateChecker } from "./app-update-checker"
import { NotificationUpdateAvailable } from "./notification-update-available"

const pollingInterval = config.checkAppUpdatePollingInterval
const repeatInterval = config.checkAppUpdateRepeatInterval
const updateChecker = new AppUpdateChecker(pollingInterval, repeatInterval)

export type AppMeta = {
  date: string
  version?: string
}
const AppVersionContext = createContext<AppMeta | null>(null)

export function useAppVersion() {
  return useContext(AppVersionContext)
}

export function AppVersionProvider({
  children,
}: {
  children: React.ReactNode
}) {
  useUpdateChecker()

  return (
    <AppVersionContext.Provider value={updateChecker.initialMeta}>
      {children}
    </AppVersionContext.Provider>
  )
}

function useUpdateChecker() {
  const triggerRender = useUpdate()

  const notify = () => {
    toast(
      (t) => (
        <NotificationUpdateAvailable
          onIgnore={() => {
            updateChecker.skip()
            toast.dismiss(t.id)
          }}
          onUpdate={() => window.location.reload()}
        />
      ),
      {
        duration: Infinity,
        position: "top-center",
        id: "app-update",
      },
    )
  }
  useLifecycles(
    async () => {
      const meta = await updateChecker.init(notify)
      if (meta) {
        setTag("version", meta.version)
        setTag("release_date", meta.date)
      }
      triggerRender()
    },
    () => {
      updateChecker.destroy()
    },
  )
}
