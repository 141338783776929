import { useState } from "react"
import { useTranslation } from "react-i18next"
import { SlidersHorizontalIcon } from "lucide-react"

import { Button } from "@/components/ui/button"

import { QueuePicker } from "../pickers/queue-picker"
import { CreateEditPageLayout } from "../shared/create-edit-page-layout"
import { useCreateCampaignState } from "./edit-filters-hooks"
import { FiltersPanel } from "./filters-panel"
import { PreviewPanel } from "./preview-panel"
import { PriorityFiltersPanel } from "./priority-filters-panel"

export function CreateCampaignPage() {
  const { t } = useTranslation()
  const [queue, setQueue] = useState<string | null>(null)

  const {
    addFilter,
    addPriorityFilter,
    filters,
    handleSave,
    isAddedFilter,
    isAddedPriorityFilter,
    mutation,
    priorityFilters,
    removeFilter,
    removePriorityFilter,
    setFilters,
    setPriorityFilters,
  } = useCreateCampaignState(queue)

  const showEmptyState = Object.keys(filters).length === 0

  return (
    <CreateEditPageLayout
      header={t("campaigns.create.title")}
      actionBar={
        <ActionBar
          isSaving={mutation.isPending}
          onSave={queue ? handleSave : undefined}
          queue={queue}
          setQueue={setQueue}
        />
      }
      filtersArea={
        <FiltersPanel
          campaign_id={queue}
          filters={filters}
          setFilters={setFilters}
          addFilter={addFilter}
          removeFilter={removeFilter}
          isAddedFilter={isAddedFilter}
        />
      }
      previewArea={
        showEmptyState ? (
          <EmptyState />
        ) : (
          <PreviewPanel filters={filters} priorityFilters={priorityFilters} />
        )
      }
      priorityFiltersArea={
        <PriorityFiltersPanel
          campaign_id={queue}
          filters={filters}
          priorityFilters={priorityFilters}
          setPriorityFilters={setPriorityFilters}
          addFilter={addPriorityFilter}
          removeFilter={removePriorityFilter}
          isAddedFilter={isAddedPriorityFilter}
        />
      }
    />
  )
}

function ActionBar({
  isSaving,
  onSave,
  queue,
  setQueue,
}: {
  isSaving: boolean
  onCancel?: () => void
  onSave?: () => void
  queue: string | null
  setQueue: (queue: string) => void
}) {
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-4">
      <QueuePicker value={queue} onChange={setQueue} />
      <Button
        variant="default"
        isDisabled={!onSave || isSaving}
        onClick={onSave}
        isLoading={isSaving}
      >
        {t("global.button.save")}
      </Button>
    </div>
  )
}

function EmptyState() {
  const { t } = useTranslation()

  return (
    <div className="flex h-full items-center justify-center rounded border">
      <div className="flex flex-col items-center gap-4 text-center text-xl text-primary-300">
        <SlidersHorizontalIcon size={48} />
        {t("campaigns.preview.emptyState")}
      </div>
    </div>
  )
}
