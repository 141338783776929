import { useTranslation } from "react-i18next"

import { useModal } from "@/components/core/Root/modal-root"
import { DropdownMenuItem } from "@/components/ui/dropdown-menu"

import { getCampaignStatus } from "../api/campaigns"
import { Campaign } from "../campaigns-types"
import { DialogPauseCampaign } from "./dialog-pause-campaign"
import { DialogResumeCampaign } from "./dialog-resume-campaign"

export function PauseResumeCampaignMenuItems({
  campaign,
  onEdit,
}: {
  campaign: Campaign
  onEdit: (campaign_id: string) => void
}) {
  const { t } = useTranslation()
  const { showModal } = useModal()

  const status = getCampaignStatus(campaign)
  const canPause = status === "active"
  const canResume = status === "paused"

  async function handlePause() {
    const result = await showModal((close) => (
      <DialogPauseCampaign close={close} campaign={campaign} />
    ))
    if (result) onEdit(campaign.campaign_id)
  }

  async function handleResume() {
    const result = await showModal((close) => (
      <DialogResumeCampaign close={close} campaign={campaign} />
    ))
    if (result) onEdit(campaign.campaign_id)
  }

  return (
    <>
      {canPause && (
        <DropdownMenuItem onClick={handlePause}>
          {t("campaigns.actions.pause")}
        </DropdownMenuItem>
      )}
      {canResume && (
        <DropdownMenuItem onClick={handleResume}>
          {t("campaigns.actions.resume")}
        </DropdownMenuItem>
      )}
    </>
  )
}
