import { Controller, useForm, UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import * as callViewValue from "@/components/CallView/value"
import { useAxiosClientContext } from "@/components/core/AxiosInstanceProvider/useAxiosClient"
import DatePicker from "@/components/core/DatePicker"
import TextArea from "@/components/core/TextArea"
import { Button } from "@/components/ui/button"
import {
  DialogBody,
  DialogBodyError,
  DialogBodySuccess,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form"
import { SelectWithOptions } from "@/components/ui/select"
import { ActivityFeedEntry } from "@/pages/CustomConnectPanel/value"

export function DialogEditComment({
  activityFeedEntry,
  caseId,
  close,
  groupId,
}: {
  activityFeedEntry: ActivityFeedEntry.ActivityFeedEntryType
  caseId: string
  close: (value: boolean) => void
  groupId: string
}) {
  const { t } = useTranslation()

  const memo = activityFeedEntry.comment?.comment || ""
  const status = activityFeedEntry.status_event?.event_name || ""
  const { reason, scheduled_at } = activityFeedEntry.task_contact || {}

  const form = useForm<callViewValue.Decoder.DecoderType>({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      memo,
      scheduledCallDate: scheduled_at,
      status,
      reason: reason || "-",
    },
  })

  const mutation = useMutationEditComment(caseId, groupId)

  const handleSave = async (event: React.FormEvent) => {
    event.preventDefault()
    const memo = form.getValues("memo")
    mutation.mutate(memo)
  }

  return (
    <DialogContent isPending={mutation.isPending} className="max-w-2xl">
      <form onSubmit={handleSave}>
        <DialogHeader>
          <DialogTitle>
            {t("campaigns.details.actions.editComment.label")}
          </DialogTitle>
        </DialogHeader>
        {(mutation.isIdle || mutation.isPending) && (
          <>
            <DialogBody>
              <div className="flex flex-col gap-4">
                <HistoryForm form={form} isPending={mutation.isPending} />
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => close(false)}
                isDisabled={mutation.isPending}
              >
                {t("global.button.cancel")}
              </Button>
              <Button type="submit" isLoading={mutation.isPending}>
                {t("global.button.save")}
              </Button>
            </DialogFooter>
          </>
        )}
        {mutation.isSuccess && (
          <>
            <DialogBodySuccess>
              {t("campaigns.details.actions.editComment.success")}
            </DialogBodySuccess>
            <DialogFooter>
              <Button variant="outline" onClick={() => close(false)}>
                {t("global.button.close")}
              </Button>
            </DialogFooter>
          </>
        )}
        {mutation.isError && (
          <>
            <DialogBodyError>Unable to edit the history</DialogBodyError>
            <DialogFooter>
              <Button variant="outline" onClick={() => close(false)}>
                {t("global.button.close")}
              </Button>
            </DialogFooter>
          </>
        )}
      </form>
    </DialogContent>
  )
}

function useMutationEditComment(caseId: string, groupId: string) {
  const axiosClient = useAxiosClientContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (comment: string) => {
      return await axiosClient.put(`/case/${caseId}/comment/${groupId}`, {
        comment,
      })
    },
    onSuccess: () => {
      // wait for invalidation to complete before showing the success state
      // https://tkdodo.eu/blog/mastering-mutations-in-react-query#awaited-promises
      return queryClient.invalidateQueries({
        queryKey: ["/case"],
      })
    },
  })
}

function HistoryForm({
  form,
  isPending,
}: {
  form: UseFormReturn<callViewValue.Decoder.DecoderType>
  isPending: boolean
}) {
  const { t } = useTranslation()

  const {
    clearErrors,
    control,
    formState: { errors },
  } = form

  return (
    <Form {...form}>
      <FormField
        control={form.control}
        name="status"
        label={t("callResult.status")}
        disabled
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("callResult.status")}</FormLabel>
            <FormControl>
              <SelectWithOptions
                options={[{ value: field.value, label: field.value }]}
                {...field}
                placeholder={t("dashboard.selectOptions.selectPlaceholder")}
                size="lg"
                isSentryUnmask
              />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="reason"
        label={t("callResult.reason")}
        disabled
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("callResult.reason")}</FormLabel>
            <FormControl>
              <SelectWithOptions
                options={[{ value: field.value, label: field.value }]}
                {...field}
                placeholder={t("dashboard.selectOptions.selectPlaceholder")}
                size="lg"
                isSentryUnmask
              />
            </FormControl>
          </FormItem>
        )}
      />

      <Controller
        name="scheduledCallDate"
        control={form.control}
        render={({ field: { name, onBlur, onChange, value } }) => {
          return (
            <DatePicker
              name={name}
              label="callResult.scheduleCall"
              onBlur={onBlur}
              onChange={onChange}
              orientation="vertical"
              value={value}
              disabled
            />
          )
        }}
      />

      <Controller
        name="memo"
        control={control}
        render={({ field: { name, onBlur, onChange, ref, value } }) => {
          return (
            <TextArea
              name={name}
              placeholder="callResult.placeholder"
              orientation="vertical"
              label="callResult.memo"
              onBlur={onBlur}
              onChange={onChange}
              errors={errors}
              clearErrors={clearErrors}
              forwardedRef={ref}
              value={value}
              maxLength={500}
              disabled={isPending}
            />
          )
        }}
      />
    </Form>
  )
}
