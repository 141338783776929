import { useList } from "react-use"

import { FilterSelectionChannel, QuestionAnswerItem } from "../campaigns-types"

export function useChannelsState(initialChannels: FilterSelectionChannel[]) {
  const [channelList, channelState] =
    useList<FilterSelectionChannel>(initialChannels)

  const setChannelId = (index: number) => (value: number) => {
    channelState.updateAt(index, { channel_id: value, questionsAnswers: [] })
  }

  const removeChannel = (index: number) => () => {
    channelState.removeAt(index)
  }

  const onChangeQuestionAnswers =
    (index: number) => (questionsAnswers: QuestionAnswerItem[]) => {
      channelState.updateAt(index, { ...channelList[index], questionsAnswers })
    }

  function addChannel(channel_id: number) {
    channelState.push({ channel_id, questionsAnswers: [] })
  }

  return {
    channels: channelList,
    setChannelId,
    removeChannel,
    onChangeQuestionAnswers,
    addChannel,
  }
}
