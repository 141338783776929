import { AxiosError } from "axios"

interface ReactQueryOptionsType {
  retry: (failureCount: number, error: unknown) => boolean
}

/**
 * This hook provides the following:
 * `retry` - React query uses this function in order to determine the behavior for retrying a failed request.
 * It is passed to every `useQuery` and `useMutation` hook. We also handle error logging of react query errors in this
 * function because the retry function provides the number of failed attempts as an argument.
 * Thus, we can log the errors to sentry only if the error is not a retry (e.g. authentication errors logged at
 * the second failed attempt while other errors are logged at the third failed attempt).

 * Important update: the "refetch token + retry" logic is now handled at the Axios level, via interceptors.
 */
const useReactQueryOptions = (): ReactQueryOptionsType => {
  const retry = (failureCount: number, error: unknown): boolean => {
    return failureCount < 3 && shouldBeRetriedError(error)
  }

  return { retry }
}

/** Only retry:
 * - "transient errors", that are temporary and may resolve soon after they occur
 * - Connectivity issues
 **/
function shouldBeRetriedError(error: unknown): boolean {
  const shouldRetryErrorStatuses = [
    408, // Request Timeout
    429, // Too Many Requests
    504, // Gateway Timeout
  ]

  if (error instanceof AxiosError) {
    if (!error.response) {
      // connectivity issue, also happens when calling wrong end-points

      return true
    }

    return shouldRetryErrorStatuses.includes(error.response.status)
  }

  return false
}

export { useReactQueryOptions }
