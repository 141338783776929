import { useMemo } from "react"
import {
  agentAtom,
  currentContactStatusTypeAtom,
  currentContactTypeAtom,
} from "@helpers/atoms"
import { getTaskAttributes } from "@helpers/contact"
import { caseIdRegexp } from "@helpers/zodSchemas"
import * as connectPanelValue from "@pages/CustomConnectPanel/value"
import { useAtomValue } from "jotai"

interface ContactDataHookType {
  callType: "inbound" | "outbound"
  campaignId: string | null
  hasTask: boolean
  isInboundCall: boolean
  isTask: boolean
  isVoiceCallWithError: boolean
  isVoiceContact: boolean
  studentId: string | null
  voiceContactId: string | null
}

/**
 * React hook that returns the various contact information required to POST a call report
 * for the current contact.
 * @param currentCase - the current case connected to the contact fetched from the database
 *
 * @returns ContactDataHookType - object containing the relevant contact information
 */
const useContactDataHook = (
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null,
): ContactDataHookType => {
  const agent = useAtomValue(agentAtom)
  const currentContactType = useAtomValue(currentContactTypeAtom)
  const currentContactStatusType = useAtomValue(currentContactStatusTypeAtom)
  const taskAttributes = getTaskAttributes(agent)

  const voiceContact = agent?.getContacts(connect.ContactType.VOICE)[0]
  const callType = voiceContact?.isInbound() ? "inbound" : "outbound"
  const voiceContactId = voiceContact?.getContactId() ?? null
  const isVoiceContact = currentContactType === connect.ContactType.VOICE
  const isTask = currentContactType === connect.ContactType.TASK
  const isVoiceCallWithError =
    currentContactType === connect.ContactType.VOICE &&
    currentContactStatusType === connect.ContactStateType.ERROR

  const studentIdFromTask = taskAttributes?.student_id?.value ?? null
  const rawCaseId = taskAttributes?.case_id?.value
  const hasTask = Boolean(agent?.getContacts(connect.ContactType.TASK).length)

  const campaignIdFromTask = useMemo(() => {
    if (!rawCaseId) {
      return null
    }

    const params = caseIdRegexp.exec(rawCaseId)
    const campaignId = params?.groups?.campaign

    return campaignId ?? null
  }, [rawCaseId])

  const studentId = currentCase?.case.student_id ?? studentIdFromTask
  const campaignId = currentCase?.case.campaign_id ?? campaignIdFromTask

  return {
    voiceContactId,
    callType,
    studentId,
    campaignId,
    isVoiceContact,
    isVoiceCallWithError,
    isTask,
    isInboundCall: voiceContact?.isInbound() ?? false,
    hasTask,
  }
}

export { useContactDataHook }
