import * as React from "react"

import { cn } from "@/helpers/classNames"

export type BasicTextareaProps =
  React.TextareaHTMLAttributes<HTMLTextAreaElement>

/**
 * Basic <Textarea> from shadcn/ui,
 * renamed to avoid any confusion with the extising `TextArea` in the `components/core`
 * */
export const BasicTextarea = React.forwardRef<
  HTMLTextAreaElement,
  BasicTextareaProps
>(({ className, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        "flex min-h-[80px] w-full rounded-md border border-input px-6 pb-2 pt-4 ring-offset-background placeholder:text-neutral-400 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:bg-neutral-200 disabled:text-neutral-400",
        className,
      )}
      ref={ref}
      {...props}
    />
  )
})
BasicTextarea.displayName = "BasicTextarea"
