import { memo } from "react"
import { Controller, UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"
import * as callViewValue from "@components/CallView/value"
import DatePicker from "@components/core/DatePicker"
import TextArea from "@components/core/TextArea"
import { Button } from "@components/ui/button"
import { cn } from "@helpers/classNames"
import * as connectPanelValue from "@pages/CustomConnectPanel/value"
import { addDays } from "date-fns"

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { SelectWithOptions } from "@/components/ui/select"

import { CallReportModeType } from "../CallView"
import { useCallReportStatelessForm } from "./call-report-stateless-form-hook"
import { ACWFormStatus } from "./hook"

const styles = {
  acwContainer: (className: string | undefined) =>
    cn([
      "col-span-full flex flex-col gap-y-6 lg:col-span-2 lg:row-span-1",
      className,
    ]),
  title: "font-h3-regular text-neutral-600",
  formSplitLine: "flex gap-x-4 gap-y-2 flex-wrap",
  selectContainer: "w-48 max-w-1/2 flex-grow",
}

interface Props {
  callReportMode: CallReportModeType
  caseStatuses: connectPanelValue.Decoder.CaseStatuses | null
  className?: string
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  form: UseFormReturn<callViewValue.Decoder.DecoderType>
  inModal?: boolean
  isLoadingAgent: boolean
  isNewItem: boolean
  onSave: (data: callViewValue.Decoder.DecoderType) => void
  status: ACWFormStatus
  title: string | null
}

/**
 * Avoid re-rendering the form when it's not needed, skipping updates related to AWS stream of events
 */
export const CallReportStatelessForm = memo(
  CallReportStatelessFormBase,
  propsAreEqual,
)

function propsAreEqual(prevProps: Props, nextProps: Props) {
  return (
    prevProps.currentCase?.case._id === nextProps.currentCase?.case._id &&
    prevProps.callReportMode === nextProps.callReportMode &&
    prevProps.isLoadingAgent === nextProps.isLoadingAgent &&
    prevProps.isNewItem === nextProps.isNewItem &&
    prevProps.status === nextProps.status &&
    prevProps.caseStatuses === nextProps.caseStatuses
  )
}

/**
 * Extract AWS Connect logic, keeping only the form logic, to be able to test this form in isolation
 */
export function CallReportStatelessFormBase({
  callReportMode,
  caseStatuses,
  className,
  currentCase,
  form,
  inModal = false,
  isLoadingAgent,
  isNewItem,
  onSave,
  status,
  title,
}: Props) {
  const { t } = useTranslation()

  const {
    clearErrors,
    control,
    formState: { errors },
  } = form

  const {
    clearDateField,
    handleSubmit,
    isDatePickerEnabled,
    options,
    validDate,
    validateCallScheduling,
  } = useCallReportStatelessForm({
    caseStatuses,
    currentCase,
    form,
    onSave,
    status,
    callReportMode,
  })

  const isDisabled =
    callReportMode === "skip-call" || callReportMode === "add-status"
      ? false
      : status === "disabled"
  const minDate = new Date()
  const maxDate = addDays(minDate, 6)

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className={styles.acwContainer(className)}>
        {title && <h3 className={styles.title}>{title}</h3>}
        <FormField
          control={form.control}
          rules={{ required: true }}
          name="status"
          label={t("callResult.status")}
          disabled={isDisabled}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("callResult.status")}</FormLabel>
              <FormControl>
                <SelectWithOptions
                  options={options.status}
                  {...field}
                  placeholder={t("dashboard.selectOptions.selectPlaceholder")}
                  size="lg"
                  isSentryUnmask
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          rules={{ required: !!options.reason.length }}
          name="reason"
          label={t("callResult.reason")}
          disabled={isDisabled}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("callResult.reason")}</FormLabel>
              <FormControl>
                <SelectWithOptions
                  options={options.reason}
                  {...field}
                  placeholder={t("dashboard.selectOptions.selectPlaceholder")}
                  size="lg"
                  isSentryUnmask
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Controller
          name="scheduledCallDate"
          control={control}
          rules={{
            required: isDatePickerEnabled,
            validate: {
              wrongDate: (value) => validDate(value, isDatePickerEnabled),
              validStartDateTime: (value) =>
                validateCallScheduling(value, isDatePickerEnabled),
            },
          }}
          render={({ field: { name, onBlur, onChange, value } }) => {
            return (
              <DatePicker
                name={name}
                label="callResult.scheduleCall"
                onBlur={onBlur}
                onChange={onChange}
                clearErrors={clearErrors}
                errors={errors}
                orientation="vertical"
                value={value}
                minDate={minDate}
                maxDate={maxDate}
                withTime={true}
                isClearable={true}
                clearField={clearDateField}
                disabled={!isDatePickerEnabled}
                required={isDatePickerEnabled}
              />
            )
          }}
        />

        <Controller
          name="memo"
          control={control}
          render={({ field: { name, onBlur, onChange, ref, value } }) => {
            return (
              <TextArea
                name={name}
                placeholder="callResult.placeholder"
                orientation="vertical"
                label="callResult.memo"
                onBlur={onBlur}
                onChange={onChange}
                errors={errors}
                clearErrors={clearErrors}
                forwardedRef={ref}
                value={value}
                maxLength={500}
                disabled={isDisabled}
              />
            )
          }}
        />
        {!inModal && (
          <Button
            type="submit"
            className="w-32"
            isDisabled={isLoadingAgent || isDisabled}
            loadingMessage={t("global.button.savingReport")}
          >
            {isNewItem ? t("global.button.save") : t("global.button.update")}
          </Button>
        )}
      </form>
    </Form>
  )
}
