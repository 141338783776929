import { useTranslation } from "react-i18next"

import { Button } from "@/components/ui/button"

type Props = {
  onIgnore: () => void
  onUpdate: () => void
}

export function NotificationUpdateAvailable({ onIgnore, onUpdate }: Props) {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-4">
      <p>{t("updateCheckerNotification.title")}</p>
      <p className="text-gray-500 text-sm font-normal">
        {t("updateCheckerNotification.description")}
      </p>
      <div className="flex justify-end gap-2">
        <Button type="button" variant="outline" onClick={onIgnore}>
          {t("updateCheckerNotification.ignore")}
        </Button>
        <Button type="button" onClick={onUpdate}>
          {t("updateCheckerNotification.update")}
        </Button>
      </div>
    </div>
  )
}
