import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "@tanstack/react-query"
import { isNull } from "lodash"

import { useCleanUpManualCalling } from "@/components/CallPanel/ACWButtons/hook"
import * as callViewValue from "@/components/CallView/value"
import { useAxiosClientContext } from "@/components/core/AxiosInstanceProvider/useAxiosClient"
import { SingleClickButton } from "@/components/SingleClickButton"
import { Button } from "@/components/ui/button"
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { config } from "@/config"
import { CallReportError, createCallReportError } from "@/helpers/error"
import { Timer } from "@/hooks/callDurationTimer"
import { useProcessingTimeHook } from "@/hooks/callProcessingTime"
import { useConnectActionsHook } from "@/hooks/connectActions"
import { useContactDataHook } from "@/hooks/contactData"
import { useCallPanelLogger } from "@/hooks/useLogger"

import { CaseWithActivityFeed } from "../student-details-api"

export default function LeavingConfirmation({
  currentCase,
  onClose,
  timer,
}: {
  currentCase: CaseWithActivityFeed | null
  onClose: (val: boolean) => void
  timer: Timer | null
}) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const axiosClient = useAxiosClientContext()
  const log = useCallPanelLogger()
  const { closeContact, hangUpPhoneCall } = useConnectActionsHook()
  const cleanup = useCleanUpManualCalling()
  const { getProcessingTime } = useProcessingTimeHook()
  const { callType, campaignId, isVoiceContact, studentId, voiceContactId } =
    useContactDataHook(currentCase)

  const sendCallReportMutation = useMutation({
    mutationFn: async () => {
      if (isNull(studentId)) {
        throw new Error("[abandon-manual-call] studentId is not found")
      }

      if (isNull(campaignId)) {
        throw new Error("[abandon-manual-call]  campaignId is not found")
      }
      const createdAt = new Date()
      const processingTime = getProcessingTime()
      const callContact = voiceContactId
        ? {
            call_duration_seconds: timer
              ? timer.seconds + timer.minutes * 60 + timer.hours * 3600
              : null,
            contact_id: voiceContactId,
            created_at: createdAt,
            type: callType,
          }
        : undefined
      const callReportData = {
        campaign_id: campaignId,
        student_id: studentId,
        status_event: {
          event_name: "そのまま",
          created_at: createdAt,
          processing_duration_seconds: processingTime,
          call_attempted: true,
        },
        call_contact: callContact,
        stop_most_recent_task: false,
      }
      try {
        const response = await axiosClient.post(
          `https://${config.apiEndpoint}/call-reports`,
          callReportData,
        )
        const validResponse =
          await callViewValue.Decoder.saveReportSchema.parseAsync(response.data)

        if (!validResponse.success.length) {
          throw new Error("[go-offline] Empty response from call report API")
        }

        return validResponse
      } catch (err) {
        throw createCallReportError(err, callReportData)
      }
    },
  })

  const handleNavigate = async () => {
    try {
      setIsLoading(true)
      if (voiceContactId) {
        await hangUpPhoneCall()
      }
      await sendCallReportMutation.mutateAsync()
      cleanup()
      if (isVoiceContact && voiceContactId) {
        await closeContact()
      }
    } catch (err) {
      if (err instanceof CallReportError) {
        log.error(err, { callReportData: err.callReportData })
      } else {
        log.error(err)
      }
    } finally {
      setIsLoading(false)
      onClose(true)
    }
  }

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          {t("global.modals.manualCallLeaveConfirm.title")}
        </DialogTitle>
      </DialogHeader>
      <DialogBody>
        <p>{t("global.modals.manualCallLeaveConfirm.description")}</p>
        <p className="mt-2 font-bold">
          {t("global.modals.manualCallLeaveConfirm.confirmation")}
        </p>
      </DialogBody>
      <DialogFooter>
        <Button variant="outline" onClick={() => onClose(false)}>
          {t("global.button.cancel")}
        </Button>
        <SingleClickButton isLoading={isLoading} onClick={handleNavigate}>
          {t("global.modals.manualCallLeaveConfirm.submit")}
        </SingleClickButton>
      </DialogFooter>
    </DialogContent>
  )
}
