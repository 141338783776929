import { SentryLogger } from "@helpers/sentryLogger"
import { setTag } from "@sentry/react"

import {
  handleAgentRefresh,
  handleAgentStateChange,
  handleSoftphoneError,
} from "./agent_events"
import { checkResetSession } from "./connect_session"
import {
  handleAfterCallWork,
  handleContactDestroyed,
  handleMissedContact,
  initNewContact,
} from "./contact_events"
import {
  AgentEventsInputType,
  ContactEventsInputType,
  InitErrorHandlerInputType,
} from "./types"

const log = new SentryLogger()

/**
 * Helper method to initialize error handlers for the CCP iframe
 * Will reset the CCP authentication to an error state
 *
 */
const initErrorHandlers =
  ({ setCCPMode }: InitErrorHandlerInputType) =>
  () => {
    connect.core.onAuthFail(() => {
      setCCPMode({
        current: "ERROR",
        state: { errorMessage: "ccpOauthError" },
      })
    })

    connect.core.onAccessDenied(() => {
      setCCPMode({
        current: "ERROR",
        state: { errorMessage: "ccpAccessDenied" },
      })
    })

    connect.core.onIframeRetriesExhausted(() => {
      setCCPMode({
        current: "ERROR",
        state: { errorMessage: "ccpIframeRetriesExhausted" },
      })
    })

    connect.core.onCTIAuthorizeRetriesExhausted(() => {
      setCCPMode({
        current: "ERROR",
        state: { errorMessage: "ccpCTIAuthorizeRetriesExhausted" },
      })
    })

    connect.core.onAuthorizeRetriesExhausted(() => {
      setCCPMode({
        current: "ERROR",
        state: { errorMessage: "ccpAuthorizeRetriesExhausted" },
      })
    })
  }

/**
 * Function to subscribe to agent events; This code runs only inside the AWS Connect CCP Iframe
 *
 * @param agentRef - the agent instance stored in a ref so that the iframe can access it
 * @param refreshConnectSession - helper function to refresh the AWS Connect token when the agent spends more than 10 hours online.
 * @param setAgent - helper function to set the agent instance in the jotai atom and in the ref
 * @param setModalMode - function to display a modal (e.g. in case of an error inside the iframe)
 *
 * @param agent - the agent instance pushed by AWS Connect. Refreshes very often and is triggering rapid re-renders in react.
 * Use with care. Recommended to extract primitive values from the agent instance in React components.
 *
 * @returns void
 */
const initAgentEventHandlers = ({
  agentRef,
  refreshConnectSession,
  setAgent,
  setModalMode,
}: AgentEventsInputType) => {
  connect.agent((agent: connect.Agent): void => {
    try {
      setAgent(agent)

      const agentSnapshot = agent.toSnapshot()
      setTag(
        "connect_agent_username",
        // @ts-expect-error - snapshot is not an Agent instance type but a Record<string, any>
        agentSnapshot?.agentData?.configuration?.username,
      )

      // Agent event handlers
      agent.onRefresh(handleAgentRefresh(setAgent))
      agent.onStateChange(handleAgentStateChange({ refreshConnectSession }))
      agent.onSoftphoneError(handleSoftphoneError({ setModalMode, agentRef }))
    } catch (error) {
      log.error(error, { agent: agent.toSnapshot() })
    }
  })
}

/**
 * Function to subscribe to contact events; This code runs only inside the AWS Connect CCP Iframe
 *
 * @param agentRef - the agent instance stored in a ref so that the iframe can access it
 * @param contactIdRef - the contact id stored in a ref so that the iframe can access it
 * @param eraseContactDurations - helper function to remove the call duration and call processing time from local storage when moving to the next contact
 * @param initCustomerProfileLoading - helper function to set a loading state when receiving a new contact; this will be used to handle a new customer profile request
 * @param refreshConnectSession - helper function to refresh the AWS Connect token when the agent spends more than 10 hours online.
 * @param resetIsMuted - helper function to put the muted value back to false before moving to the next contact
 * @param resetStateForNextContact - helper function to reset values store in state and in local storage before moving to the next contact
 * @param setCurrentContactId - helper function to store the contact id of the incoming new contact in both a jotai atom and a ref
 *
 * @returns void
 */
const initContactEventHandlers = ({
  agentRef,
  initCustomerProfileLoading,
  refreshConnectSession,
  resetIsMuted,
  resetStateForNextContact,
  setCurrentContactId,
}: ContactEventsInputType) => {
  connect.contact(async (contact: connect.Contact): Promise<void> => {
    try {
      // Put logic to auto-accept the incoming task first.
      // This is done to prevent agents from hearing a call sound while the agent has an incoming task, when their connection is slow.
      await initNewContact({
        contact,
        agentRef,
        initCustomerProfileLoading,
        setCurrentContactId,
      })

      const shouldResetSession = checkResetSession()
      if (shouldResetSession) {
        refreshConnectSession()
      }

      contact.onACW(handleAfterCallWork({ agentRef }))
      contact.onEnded((contact) => {
        try {
          resetIsMuted()
        } catch (error) {
          const agent = agentRef.current

          log.error(error, {
            contact: contact.toSnapshot(),
            agent: agent?.toSnapshot(),
          })
        }
      })
      contact.onMissed(handleMissedContact({ agentRef }))
      contact.onDestroy(
        handleContactDestroyed({
          agentRef,
          setCurrentContactId,
          resetStateForNextContact,
        }),
      )
    } catch (error) {
      const agent = agentRef.current

      log.error(error, {
        contact: contact.toSnapshot(),
        agent: agent?.toSnapshot(),
      })
    }
  })
}

export { initAgentEventHandlers, initContactEventHandlers, initErrorHandlers }
