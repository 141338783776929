import z from "zod"

import { useQueryAPI } from "@/hooks/query-api"

import {
  PhoneNumberValidationResult,
  phoneNumberValidationResultSchema,
} from "../campaigns-types"

export function useValidatePhoneNumbers(phoneNumbers: string[]) {
  return useQueryAPI({
    apiEndPoint: "/students/phone-numbers/validate",
    data: {
      phone_numbers: phoneNumbers,
    },
    method: "POST",
    schema: z.object({ data: z.array(phoneNumberValidationResultSchema) }),
  })
}

export function isValidationError(result: PhoneNumberValidationResult) {
  return result.validation_status !== "SUCCESS"
}

export type ValidationResult = PhoneNumberValidationResult & {
  line: number
}

export function addLineNumbers(
  results: PhoneNumberValidationResult[],
): ValidationResult[] {
  return results.map((result, index) => ({
    ...result,
    line: index + 1,
  }))
}
