import { FunctionComponent, MouseEvent, PropsWithChildren } from "react"
import AcceptCall from "@assets/accept-call.svg?react"
import Alert from "@assets/alert.svg?react"
import Arrow from "@assets/arrow.svg?react"
import Calendar from "@assets/calendar.svg?react"
import Campaign from "@assets/campaign.svg?react"
import Check from "@assets/check.svg?react"
import Chevron from "@assets/chevron.svg?react"
import ChevronLeft from "@assets/chevron-left.svg?react"
import Available from "@assets/circle-green.svg?react"
import Offline from "@assets/circle-red.svg?react"
import Clear from "@assets/clear.svg?react"
import Close from "@assets/close.svg?react"
import Dashboard from "@assets/dashboard.svg?react"
import Dot from "@assets/dot.svg?react"
import DotError from "@assets/dot-error.svg?react"
import DotPrimary from "@assets/dot-primary.svg?react"
import DotSuccess from "@assets/dot-success.svg?react"
import DotWarning from "@assets/dot-warning.svg?react"
import Download from "@assets/download.svg?react"
import EndCall from "@assets/end-call.svg?react"
import Eye from "@assets/eye.svg?react"
import EyeSlash from "@assets/eye-slash.svg?react"
import EmptyFolder from "@assets/folder-empty.svg?react"
import HoldCall from "@assets/hold-call.svg?react"
import Info from "@assets/info.svg?react"
import Logo from "@assets/logo.svg?react"
import Medal from "@assets/medal.svg?react"
import MuteCall from "@assets/mute-call.svg?react"
import ResumeCall from "@assets/resume-call.svg?react"
import Search from "@assets/search.svg?react"
import Spinner from "@assets/spinner.svg?react"
import Students from "@assets/students.svg?react"
import UnmuteCall from "@assets/unmute-call.svg?react"
import Upload from "@assets/upload.svg?react"
import User from "@assets/user.svg?react"
import UserSetting from "@assets/user-setting.svg?react"
import VoiceCall from "@assets/voice-call.svg?react"
import Workspace from "@assets/workspace.svg?react"
import { cn } from "@helpers/classNames"

const iconNames = [
  "user",
  "user-setting",
  "campaigns",
  "dashboard",
  "students",
  "folder-empty",
  "arrow",
  "workspace",
  "search",
  "chevron",
  "calendar",
  "clear",
  "offline",
  "available",
  "medal",
  "dot",
  "end-call",
  "accept-call",
  "hold-call",
  "mute-call",
  "dot-warning",
  "dot-error",
  "dot-success",
  "dot-primary",
  "unmute-call",
  "resume-call",
  "eye",
  "eye-slash",
  "alert",
  "close",
  "logo",
  "info",
  "spinner",
  "voice-call",
  "download",
  "check",
  "upload",
  "chevron-left",
] as const

export type IconName = (typeof iconNames)[number]

type IconSize =
  | "lg"
  | "logo-size"
  | "md"
  | "sm"
  | "spinner"
  | "xl"
  | "xsm"
  | "xxl"
  | "xxsm"

interface Props {
  className?: string
  isDisabled?: boolean
  name: IconName
  onClick?: (event: MouseEvent<HTMLImageElement>) => void
  size?: IconSize
}

const getIconClassName = ({
  className,
  isDisabled,
  size,
}: {
  className?: string
  isDisabled: boolean
  size?: IconSize
}) => {
  let iconSize = ""

  switch (size) {
    case "xxsm":
      iconSize = "w-1 h-1"
      break
    case "xsm":
      iconSize = "w-2 h-2"
      break
    case "sm":
      iconSize = "w-4 h-4"
      break

    case "md":
      iconSize = "w-8 h-8"
      break

    case "lg":
      iconSize = "w-12 h-12"
      break

    case "xl":
      iconSize = "w-20 h-20"
      break

    case "xxl":
      iconSize = "w-24 h-24"
      break

    case "logo-size":
      iconSize = "w-[140px] h-[40px]"
      break

    case "spinner":
      iconSize = "w-6 h-6"
      break

    default:
      iconSize = "w-5-1/2 h-5-1/2"
  }

  return cn([iconSize, isDisabled && "pointer-events-none", className])
}

const Icon: FunctionComponent<Props> = ({
  className,
  isDisabled = false,
  name,
  onClick,
  size,
}) => {
  const iconClassName = getIconClassName({ className, size, isDisabled })

  const iconProps = {
    className: iconClassName,
    onClick,
  }

  switch (name) {
    case "user":
      return (
        <div {...iconProps}>
          <User />
        </div>
      )
    case "user-setting":
      return (
        <div {...iconProps}>
          <UserSetting />
        </div>
      )
    case "campaigns":
      return (
        <div {...iconProps}>
          <Campaign />
        </div>
      )
    case "dashboard":
      return (
        <div
          {...iconProps}
          className={cn([iconProps.className, "h-[16px] w-[20px]"])}
        >
          <Dashboard />
        </div>
      )
    case "download":
      return (
        <div {...iconProps}>
          <Download />
        </div>
      )
    case "students":
      return (
        <div {...iconProps}>
          <Students />
        </div>
      )
    case "folder-empty":
      return (
        <div {...iconProps}>
          <EmptyFolder />
        </div>
      )
    case "arrow":
      return (
        <div {...iconProps}>
          <Arrow />
        </div>
      )
    case "workspace":
      return (
        <div {...iconProps}>
          <Workspace />
        </div>
      )
    case "search":
      return (
        <div {...iconProps}>
          <Search />
        </div>
      )
    case "chevron":
      return (
        <div {...iconProps}>
          <Chevron />
        </div>
      )
    case "calendar":
      return (
        <div {...iconProps}>
          <Calendar />
        </div>
      )
    case "clear":
      return (
        <div {...iconProps}>
          <Clear />
        </div>
      )
    case "offline":
      return (
        <div {...iconProps}>
          <Offline />
        </div>
      )
    case "available":
      return (
        <div {...iconProps}>
          <Available />
        </div>
      )
    case "medal":
      return (
        <div {...iconProps}>
          <Medal />
        </div>
      )
    case "dot":
      return (
        <div {...iconProps}>
          <Dot />
        </div>
      )

    case "dot-warning":
      return (
        <div {...iconProps}>
          <DotWarning />
        </div>
      )
    case "dot-error":
      return (
        <div {...iconProps}>
          <DotError />
        </div>
      )
    case "dot-success":
      return (
        <div {...iconProps}>
          <DotSuccess />
        </div>
      )
    case "dot-primary":
      return (
        <div {...iconProps}>
          <DotPrimary />
        </div>
      )

    case "end-call":
      return (
        <div {...iconProps}>
          <EndCall />
        </div>
      )
    case "accept-call":
      return (
        <div {...iconProps}>
          <AcceptCall color="currentColor" />
        </div>
      )
    case "hold-call":
      return (
        <div {...iconProps}>
          <HoldCall />
        </div>
      )

    case "resume-call":
      return (
        <div {...iconProps}>
          <ResumeCall />
        </div>
      )

    case "mute-call":
      return (
        <div {...iconProps}>
          <MuteCall />
        </div>
      )

    case "unmute-call":
      return (
        <div {...iconProps}>
          <UnmuteCall />
        </div>
      )

    case "eye":
      return (
        <div {...iconProps}>
          <Eye />
        </div>
      )

    case "eye-slash":
      return (
        <div {...iconProps}>
          <EyeSlash />
        </div>
      )

    case "alert":
      return (
        <div {...iconProps}>
          <Alert />
        </div>
      )
    case "close":
      return (
        <div {...iconProps}>
          <Close />
        </div>
      )
    case "logo":
      return (
        <div {...iconProps}>
          <Logo />
        </div>
      )

    case "info":
      return (
        <div {...iconProps}>
          <Info />
        </div>
      )

    case "spinner":
      return (
        <div {...iconProps}>
          <Spinner />
        </div>
      )

    case "voice-call":
      return (
        <div {...iconProps}>
          <VoiceCall />
        </div>
      )

    case "check":
      return (
        <div {...iconProps}>
          <Check />
        </div>
      )

    case "upload":
      return (
        <div {...iconProps}>
          <Upload />
        </div>
      )

    case "chevron-left":
      return (
        <div {...iconProps}>
          <ChevronLeft />
        </div>
      )

    default:
      return null
  }
}

export default Icon

export function SuccessCircleIcon() {
  return (
    <CircleIcon className="bg-success-100">
      <Icon name="check" className="size-5 text-success-300" />
    </CircleIcon>
  )
}

export function ErrorCircleIcon() {
  return (
    <CircleIcon className="bg-danger-100">
      <Icon name="alert" className="size-5 text-danger-300" />
    </CircleIcon>
  )
}

export function CircleIcon({
  children,
  className,
}: PropsWithChildren<{ className: string }>) {
  return (
    <div
      className={cn(
        "flex size-7 shrink-0 items-center justify-center rounded-full",
        className,
      )}
    >
      {children}
    </div>
  )
}
