import { RecordType, z } from "zod"

namespace Decoder {
  const baseMetricsItemSchema = z.object({
    metricData: z.record(z.number().nullable()),
  })

  export const campaignMetricsItemSchema = baseMetricsItemSchema.extend({
    campaignId: z.string(),
  })

  export const agentMetricsItemSchema = baseMetricsItemSchema.extend({
    agentId: z.string(),
  })

  export const bilingualStringSchema = z.object({
    en: z.string(),
    ja: z.string(),
  })

  const agentInfoMetadataItemSchema = z
    .object({
      FirstName: z.string().nullable(),
      LastName: z.string().nullable(),
      Username: z.string().nullable(),
    })
    .nullable()

  export const metadataItemSchema = z.object({
    name: bilingualStringSchema,
    additionalInfo: bilingualStringSchema,
    isInterval: z.boolean(),
    metricId: z.string(),
    parentId: z.string(),
    metricType: bilingualStringSchema,
  })

  export const campaignMetricsResponseSchema = z.object({
    data: z.object({
      metrics: z.array(campaignMetricsItemSchema),
      metadata: z.record(metadataItemSchema),
      parentMetricsMetadata: z.record(bilingualStringSchema),
    }),
  })

  export const campaignBreakdownMetricsResponseSchema = z.object({
    data: z.object({
      metrics: z.array(campaignMetricsItemSchema),
      metadata: z.record(metadataItemSchema),
      parentMetricsMetadata: z.record(bilingualStringSchema),
      agentMetadata: z.record(agentInfoMetadataItemSchema).nullable(),
    }),
  })

  export const agentMetricsResponseSchema = z.object({
    data: z.object({
      agentMetadata: z.record(agentInfoMetadataItemSchema),
      metrics: z.array(agentMetricsItemSchema),
      metadata: z.record(metadataItemSchema),
      parentMetricsMetadata: z.record(bilingualStringSchema),
    }),
  })

  export const csvExportResponseSchema = z.object({
    data: z.string(),
  })

  export type BaseMetricsItem = z.infer<typeof baseMetricsItemSchema>
  export type CampaignMetricItem = z.infer<typeof campaignMetricsItemSchema>
  export type ParentMetaData = RecordType<
    string,
    z.infer<typeof bilingualStringSchema>
  >
  export type MetaDataItem = z.infer<typeof metadataItemSchema>
  export type MetaData = RecordType<string, MetaDataItem>
  export type CampaignMetricsResponse = z.infer<
    typeof campaignMetricsResponseSchema
  >
  export type AgentMetricsResponse = z.infer<typeof agentMetricsResponseSchema>
  export type AgentMetricsItem = z.infer<typeof agentMetricsItemSchema>
  export type AgentInfoMetadata = RecordType<
    string,
    z.infer<typeof agentInfoMetadataItemSchema>
  >
  export type CellRendererKey = "average" | "ratio" | "total"

  export type DisplayAgentMetric = Decoder.AgentMetricsItem & {
    fullName: JSX.Element | string
  }
}

export { Decoder }
