import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Timer } from "@components/CallPanel/hook"
import * as callViewValue from "@components/CallView/value"
import { useAxiosClientContext } from "@components/core/AxiosInstanceProvider/useAxiosClient"
import { config } from "@config/index"
import {
  inManualCallAtom,
  isCustomerProfileLoadingAtom,
  pseudoACWAtom,
} from "@helpers/atoms"
import { isNull } from "@helpers/typeguards"
import { useProcessingTimeHook } from "@hooks/callProcessingTime"
import { useConnectActionsHook } from "@hooks/connectActions"
import { useContactDataHook } from "@hooks/contactData"
import { useReactQueryOptions } from "@hooks/reactQueryOptions"
import { useToast } from "@hooks/toastHook"
import { useCallPanelLogger } from "@hooks/useLogger"
import * as connectPanelValue from "@pages/CustomConnectPanel/value"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useAtomValue, useSetAtom } from "jotai"
import { RESET } from "jotai/utils"

import { CallReportError, createCallReportError } from "@/helpers/error"
import { useCallDurationData } from "@/hooks/callDuration"
import { contactStorageService } from "@/services/localStorageService"

interface ACWButtonsHookInput {
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  getFormValues: () => callViewValue.Decoder.DecoderType
  isInboundCall: boolean
  timer: Timer | null
}

interface ACWButtonsHookType {
  isLoading: boolean
  isPseudoACW: boolean
  isVoiceContact: boolean
  onClick: (callAttempted: boolean, isManualCall?: boolean) => Promise<void>
}

const useACWButtonsHook = ({
  currentCase,
  getFormValues,
  isInboundCall,
  timer,
}: ACWButtonsHookInput): ACWButtonsHookType => {
  const setIsCustomerProfileLoading = useSetAtom(isCustomerProfileLoadingAtom)
  const isPseudoACW = useAtomValue(pseudoACWAtom)
  const inManualCall = useAtomValue(inManualCallAtom)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const log = useCallPanelLogger()
  const { error: toastError } = useToast()
  const { t } = useTranslation()
  const axiosClient = useAxiosClientContext()
  const { retry } = useReactQueryOptions()

  const { closeContact, endTask } = useConnectActionsHook()
  const { getProcessingTime } = useProcessingTimeHook(inManualCall)
  const cleanUp = useCleanUpManualCalling()

  const {
    callType,
    campaignId,
    hasTask,
    isVoiceContact,
    studentId,
    voiceContactId,
  } = useContactDataHook(currentCase)

  const { mutateAsync } = useMutation({
    mutationFn: async (params: {
      callAttempted: boolean
      validData: callViewValue.Decoder.DecoderType
    }) => {
      if (isNull(studentId)) {
        throw new Error("[call-report] studentId is not found")
      }

      if (isNull(campaignId)) {
        throw new Error("[call-report] campaignId is not found")
      }

      const { callAttempted, validData } = params
      const createdAt = new Date()
      const eventName = validData.reason || validData.status

      const callDurationSeconds: number | null = timer
        ? timer.seconds + timer.minutes * 60 + timer.hours * 3600
        : null

      const processingTime = getProcessingTime()

      const comment = validData.memo
        ? {
            comment: validData.memo,
            created_at: createdAt,
          }
        : undefined

      const callContact = voiceContactId
        ? {
            call_duration_seconds: callDurationSeconds,
            contact_id: voiceContactId,
            created_at: createdAt,
            type: callType,
          }
        : undefined
      const callReportData = {
        campaign_id: campaignId,
        student_id: studentId,
        status_event: {
          event_name: eventName,
          created_at: createdAt,
          processing_duration_seconds: processingTime,
          call_attempted: callAttempted,
        },
        comment,
        scheduled_call_datetime: validData.scheduledCallDate,
        call_contact: callContact,
        stop_most_recent_task:
          eventName === "そのまま" ? false : isInboundCall || inManualCall,
      }
      try {
        const response = await axiosClient.post(
          `https://${config.apiEndpoint}/call-reports`,
          callReportData,
        )

        if (voiceContactId && isNull(callDurationSeconds)) {
          log.warn(
            "[call-report] A voice call was reported but no call duration was provided",
            { currentCase, voiceContactId },
          )
        }

        const validResponse =
          await callViewValue.Decoder.saveReportSchema.parseAsync(response.data)

        if (!validResponse.success.length) {
          throw new Error("[call-report] Empty response from call report API")
        }

        return validResponse
      } catch (err) {
        throw createCallReportError(err, callReportData)
      }
    },
    retry,
  })

  const sendCallReport = async (callAttempted: boolean) => {
    try {
      const data = getFormValues()
      const validData = await callViewValue.Decoder.schema.parseAsync(data)

      await mutateAsync({ validData, callAttempted })
    } catch (error) {
      // TODO: On failure display an error message to the agent before allowing the agent to
      // save the contact details and click again to move on to the next contact
      // See onClick function below
      if (error instanceof CallReportError) {
        log.error(error, { callReportData: error.callReportData })
      } else {
        log.error(error)
      }
    }
  }

  // Send call report and move to the next contact in the queue
  const onClick = async (callAttempted = true) => {
    setIsLoading(() => true)

    await sendCallReport(callAttempted)

    try {
      if (inManualCall) {
        // Cleanup the contact state in local storage after the call report was posted
        // for all manual calls
        cleanUp()
      }

      if (isVoiceContact) {
        await closeContact()
      }
    } catch (err) {
      if (err instanceof Error) {
        log.error(`Voice call ACW: Failed to close contact: ${err.message}`)
      }

      toastError(t("errors.callPanel.nextContactError"))
    }

    try {
      if (hasTask) {
        await endTask()
      }

      if (isInboundCall) {
        setIsCustomerProfileLoading(true)
      }
    } catch (err) {
      if (err instanceof Error) {
        log.error(
          `Voice call ACW: Failed to move to the next task due to error: ${err.message}`,
        )
      }

      toastError(t("errors.callPanel.nextContactError"))
    }

    setIsLoading(() => false)
  }

  return {
    isLoading,
    onClick,
    isPseudoACW,
    isVoiceContact,
  }
}

function useCleanUpManualCalling() {
  const setPseudoACW = useSetAtom(pseudoACWAtom)
  const setInManualCall = useSetAtom(inManualCallAtom)
  const { clearProcessingTimeData } = useProcessingTimeHook()
  const { clearCallDurationData } = useCallDurationData()

  const queryClient = useQueryClient()

  const cleanUp = () => {
    setPseudoACW(RESET)
    setInManualCall(RESET)
    contactStorageService.removeCallReport()
    clearProcessingTimeData()
    clearCallDurationData()
    queryClient.invalidateQueries({
      queryKey: ["/case"],
    })
  }

  return cleanUp
}

export { useACWButtonsHook, useCleanUpManualCalling }
