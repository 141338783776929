import * as React from "react"
import * as LabelPrimitive from "@radix-ui/react-label"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/helpers/classNames"

const labelVariants = cva(
  "font-label-medium text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
  {
    variants: {
      variant: {
        disabled: "text-neutral-400",
      },
    },
  },
)

type Props = React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & {
  disabled?: boolean
  // Following props are added to shadcn/ui component
  required?: boolean
  variant?: "disabled"
}

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  Props & VariantProps<typeof labelVariants>
>(({ children, className, required, variant, ...props }, ref) => {
  return (
    <LabelPrimitive.Root
      ref={ref}
      className={cn(labelVariants({ variant }), className)}
      {...props}
    >
      {children}
      {required && <span className="text-danger-300">*</span>}
    </LabelPrimitive.Root>
  )
})
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
