import { useTranslation } from "react-i18next"

import { StudentDetailsTabContent } from "@/components/ContactProfileView/history-tabs"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"

import { CaseWithActivityFeed } from "../student-details-api"
import { ActivityTable } from "./activity-table"

export function StudentHistorySection({
  caseData,
}: {
  caseData: CaseWithActivityFeed
}) {
  const historyItems = caseData?.activity_feed || []
  const studentCase = caseData.case

  return (
    <ActivityFeed
      historyTab={
        <ActivityTable caseId={studentCase?._id} items={historyItems} />
      }
      detailsTab={
        studentCase ? (
          <StudentDetailsTabContent studentCase={studentCase} />
        ) : null
      }
    />
  )
}

function ActivityFeed({
  detailsTab,
  historyTab,
}: {
  detailsTab: React.ReactNode
  historyTab: React.ReactNode
}) {
  const { t } = useTranslation()

  return (
    <Tabs defaultValue="history" className="w-full">
      <TabsList>
        <TabsTrigger value="history">
          {t("caseHistory.tabs.history")}
        </TabsTrigger>
        <TabsTrigger value="detailedInformation">
          {t("caseHistory.tabs.detailedInformation")}
        </TabsTrigger>
      </TabsList>
      <TabsContent value="history" className="mt-0 bg-neutral-100">
        {historyTab}
      </TabsContent>
      <TabsContent value="detailedInformation" className="my-0 bg-neutral-100">
        {detailsTab}
      </TabsContent>
    </Tabs>
  )
}
