import { resolveLanguage } from "./i18n"

/**
 * Format a person full name according to the language, first name first or last name first
 */
export function formatFullName(
  firstName: string | null,
  lastName: string | null,
): string {
  if (!firstName && !lastName) {
    return "-"
  }

  const language = resolveLanguage()

  return language === "ja"
    ? `${lastName} ${firstName}`.trim()
    : `${firstName} ${lastName}`.trim()
}
