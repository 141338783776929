import { useTranslation } from "react-i18next"

import { Button } from "@/components/ui/button"
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"

import { FilterSelectionChannel } from "../campaigns-types"
import { ChannelsEditor } from "./channels-editor"
import { useChannelsState } from "./channels-state"

const debug = false

export function DialogEditChannels({
  campaign_id,
  channels: initialChannels,
  close,
  restrictionChannels,
}: {
  availableChannelIds?: number[]
  campaign_id: string | null
  channels: FilterSelectionChannel[]
  close: (updatedChannels: FilterSelectionChannel[] | null) => void
  restrictionChannels?: FilterSelectionChannel[]
}) {
  const { t } = useTranslation()
  const channelsState = useChannelsState(initialChannels)

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    close(channelsState.channels)
  }

  const availableChannelIds = (restrictionChannels || []).map(
    (channel) => channel.channel_id,
  )

  return (
    <DialogContent className="max-w-4xl">
      <form onSubmit={handleSubmit}>
        <DialogHeader>
          <DialogTitle>
            {campaign_id || t("campaigns.create.title")} •{" "}
            {t("campaigns.filters.channels.label")}
          </DialogTitle>
        </DialogHeader>
        <DialogBody className="h-[500px] overflow-y-auto">
          <ChannelsEditor
            channelsState={channelsState}
            availableChannelIds={availableChannelIds}
          />
          {debug && (
            <>
              <p>Input</p>
              {JSON.stringify(channelsState.channels)}
            </>
          )}
        </DialogBody>
        <DialogFooter>
          <Button type="button" variant="outline" onClick={() => close(null)}>
            {t("global.button.cancel")}
          </Button>
          <Button type="submit" variant="default">
            {t("campaigns.filters.channels.apply")}
          </Button>
        </DialogFooter>
      </form>
    </DialogContent>
  )
}
