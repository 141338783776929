import { CampaignMetricsResponse } from "./metrics"

/** Extract the number of most recent statuses related to a given campaign as a Map object */
export function parseCampaignMetricStatuses(
  response: CampaignMetricsResponse,
  campaignId: string,
) {
  const { metadata, metrics } = response.data

  const campaignMetrics = metrics.find(
    (metric) => metric.campaignId === campaignId,
  )

  const map = new Map<string, { count: number }>()

  if (campaignMetrics) {
    Object.entries(campaignMetrics.metricData).forEach(([key, value]) => {
      const statusId = metadata[key]?.statusId
      map.set(statusId, {
        count: value || 0,
      })
    })
  }

  return map
}
