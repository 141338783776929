import { useState } from "react"
import { useTranslation } from "react-i18next"
import { EllipsisIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"

import { FilterSelectionUniversity } from "../campaigns-types"
import { FilterControlLabel } from "../pickers/generic-filter-picker"
import { useUniversityGroups } from "./universities-hooks"
import { UniversityFilterEditor } from "./university-filter-editor"

export function UniversityFilterControl({
  filters,
  onEdit,
}: {
  filters: FilterSelectionUniversity[]
  onEdit: () => void
}) {
  const { flattenFilters } = useUniversityGroups()
  const pickedUniversities = flattenFilters(filters)

  return (
    <Button
      onClick={onEdit}
      variant="outline"
      className="w-campaign-filter justify-between"
      size="sm"
    >
      <FilterControlLabel filterKey="university" labels={pickedUniversities} />
      <EllipsisIcon className="size-4 opacity-50" />
    </Button>
  )
}

export function DialogUniversityFilter({
  campaign_id,
  close,
  filters: initialFilters,
  restrictionFilters,
}: {
  campaign_id: string | null
  close: (value: FilterSelectionUniversity[] | null) => void
  filters: FilterSelectionUniversity[]
  restrictionFilters?: FilterSelectionUniversity[]
}) {
  const { t } = useTranslation()

  const [filters, setFilters] =
    useState<FilterSelectionUniversity[]>(initialFilters)

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    close(filters)
  }

  return (
    <DialogContent className="max-w-6xl">
      <form onSubmit={handleSubmit}>
        <DialogHeader>
          <DialogTitle>
            {campaign_id || t("campaigns.create.title")} •{" "}
            {t("campaigns.filters.university.label")}
          </DialogTitle>
        </DialogHeader>
        <DialogBody className="min-h-[500px]">
          <UniversityFilterEditor
            filters={filters}
            setFilters={setFilters}
            restrictionFilters={restrictionFilters}
          />
        </DialogBody>
        <DialogFooter>
          <Button type="button" variant="outline" onClick={() => close(null)}>
            {t("global.button.cancel")}
          </Button>
          <Button type="submit" variant="default">
            {t("campaigns.filters.channels.apply")}
          </Button>
        </DialogFooter>
      </form>
    </DialogContent>
  )
}
