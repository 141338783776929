import * as callViewValue from "@components/CallView/value"
import {
  caseIdSchema,
  connectUserIdSchema,
  datetimeSchema,
  idSchema,
} from "@helpers/zodSchemas"
import { z } from "zod"

import { Status, statusMap } from "@/models/cases"

// TODO: Needs the exhaustive list of variants
const partyTypeSchema = z.enum(["INDIVIDUAL", "BUSINESS"])

namespace AgentInfo {
  export const agentInfoSchema = z.object({
    first_name: z.string().nullable(),
    last_name: z.string().nullable(),
    email: z.string().nullable(),
  })

  export type AgentInfoType = z.infer<typeof agentInfoSchema>

  export type EncoderInputType = {
    email: string | null
    firstName: string | null
    lastName: string | null
  }

  export const encode = ({
    email,
    firstName,
    lastName,
  }: EncoderInputType): AgentInfoType => {
    return {
      first_name: firstName ?? null,
      last_name: lastName ?? null,
      email: email ?? null,
    }
  }
}

namespace CallContact {
  const contactTypeSchema = z.enum(["inbound", "outbound"])

  export type ContactType = z.infer<typeof contactTypeSchema>

  const callContactIdSchema = z.object({
    connect_user_id: connectUserIdSchema,
    contact_id: idSchema,
    created_at: datetimeSchema,
    group_id: idSchema,
    type: contactTypeSchema,
  })

  export const callContactWithAgentInfoSchema = callContactIdSchema.merge(
    AgentInfo.agentInfoSchema,
  )

  export type CallContactType = z.infer<typeof callContactWithAgentInfoSchema>

  export const encode = (
    contactType: ContactType,
    agentInfo: AgentInfo.EncoderInputType,
  ): CallContactType => {
    return {
      connect_user_id: "",
      contact_id: "",
      created_at: new Date(),
      group_id: "",
      type: contactType,
      ...AgentInfo.encode(agentInfo),
    }
  }
}

namespace Comment {
  const commentSchema = z.object({
    comment: z.string(),
    connect_user_id: connectUserIdSchema,
    created_at: datetimeSchema,
    group_id: idSchema,
  })

  export const commentWithAgentInfoSchema = commentSchema.merge(
    AgentInfo.agentInfoSchema,
  )

  export type CommentType = z.infer<typeof commentWithAgentInfoSchema>

  export const encode = (
    comment: string | null | undefined,
    agentInfo: AgentInfo.EncoderInputType,
  ): CommentType => {
    return {
      comment: comment ?? "",
      connect_user_id: "",
      created_at: new Date(),
      group_id: "",
      ...AgentInfo.encode(agentInfo),
    }
  }
}

namespace StatusEvent {
  const statusEventSchema = z.object({
    connect_user_id: connectUserIdSchema,
    created_at: datetimeSchema,
    event_name: z.nativeEnum(statusMap),
    group_id: idSchema,
  })

  export const statusEventWithAgentInfoSchema = statusEventSchema.merge(
    AgentInfo.agentInfoSchema,
  )

  export type StatusEventType = z.infer<typeof statusEventWithAgentInfoSchema>

  export const encode = (
    eventName: string | null | undefined,
    agentInfo: AgentInfo.EncoderInputType,
  ): StatusEventType => {
    if (!eventName) {
      throw new Error("Event name is required")
    }

    return {
      connect_user_id: "",
      created_at: new Date(),
      event_name: eventName as Status,
      group_id: "",
      ...AgentInfo.encode(agentInfo),
    }
  }
}

namespace TaskContact {
  const taskContactIdSchema = z.object({
    connect_user_id: connectUserIdSchema,
    contact_id: idSchema,
    created_at: datetimeSchema,
    group_id: idSchema,
    reason: z.string().nullish(),
    scheduled_at: datetimeSchema.nullish(),
  })

  export const taskContactWithAgentInfoSchema = taskContactIdSchema.merge(
    AgentInfo.agentInfoSchema,
  )

  export type TaskContactType = z.infer<typeof taskContactWithAgentInfoSchema>

  export const encode = (
    reason: string | null | undefined,
    agentInfo: AgentInfo.EncoderInputType,
  ): TaskContactType => {
    if (!reason) {
      throw new Error("Reason is required")
    }

    return {
      connect_user_id: "",
      contact_id: "",
      created_at: new Date(),
      group_id: "",
      reason,
      ...AgentInfo.encode(agentInfo),
    }
  }
}

namespace ActivityFeedEntry {
  export const activityFeedSchema = z.object({
    call_contact: CallContact.callContactWithAgentInfoSchema.nullish(),
    comment: Comment.commentWithAgentInfoSchema.nullish(),
    status_event: StatusEvent.statusEventWithAgentInfoSchema.nullish(),
    task_contact: TaskContact.taskContactWithAgentInfoSchema.nullish(),
    campaign_id: z.string(),
  })

  export type ActivityFeedEntryType = z.infer<typeof activityFeedSchema>

  export const encode = (
    data: callViewValue.Decoder.DecoderType,
    agentInfo: AgentInfo.EncoderInputType,
    campaignId: string,
    contactType: CallContact.ContactType | undefined = "outbound",
  ): ActivityFeedEntryType => {
    return {
      call_contact: CallContact.encode(contactType, agentInfo),
      comment: Comment.encode(data.memo, agentInfo),
      status_event: StatusEvent.encode(data.reason || data.status, agentInfo),
      task_contact: TaskContact.encode(data.reason || data.status, agentInfo),
      campaign_id: campaignId,
    }
  }
}

namespace Decoder {
  export const hiddenActivityReason: readonly string[] = [
    "scheduled",
    "renewed",
    "recovery",
  ] as const

  const selectedTextualQuestionSchema = z.object({
    answer: z.string().nullable(),
    question: z.string().nullable(),
  })

  export type SelectedTextualQuestion = z.infer<
    typeof selectedTextualQuestionSchema
  >

  const selectedTextualQuestionsSchema = selectedTextualQuestionSchema.array()

  const selectedQuestionSchema = z.object({
    answers: z.string().array().nullable(),
    question: z.string().nullable(),
  })

  export type SelectedQuestion = z.infer<typeof selectedQuestionSchema>

  const selectedQuestionsSchema = selectedQuestionSchema.array()

  export const caseSchema = z.object({
    _id: caseIdSchema,
    call_contact_ids: CallContact.callContactWithAgentInfoSchema.array(),
    campaign_id: z.string(),
    comments: Comment.commentWithAgentInfoSchema.array(),
    created_at: datetimeSchema,
    current_status: z.nativeEnum(statusMap),
    no_call: z.boolean(),
    processed_data: z
      .object({
        selected_questions: selectedQuestionsSchema,
        selected_textual_questions: selectedTextualQuestionsSchema,
      })
      .optional(),
    status_events: StatusEvent.statusEventWithAgentInfoSchema.array(),
    student_id: idSchema,
    task_contact_ids: TaskContact.taskContactWithAgentInfoSchema.array(),
    updated_at: datetimeSchema,
  })

  export const caseAndActivityFeedSchema = z.object({
    case: caseSchema,
    activity_feed: ActivityFeedEntry.activityFeedSchema.array(),
    global_activity_feed: ActivityFeedEntry.activityFeedSchema
      .array()
      .optional(), // made optional as it's not used anywhere.
  })

  export const caseInformationSchema = z.object({
    data: caseAndActivityFeedSchema,
    reqId: idSchema,
    success: z.string(),
  })

  export const caseInformationResponseSchema = z.object({
    data: caseInformationSchema,
    status: z.number(),
  })

  export type Case = z.infer<typeof caseSchema>
  export type CaseAndActivityFeeds = z.infer<typeof caseAndActivityFeedSchema>
  export type CaseInformationResponse = z.infer<typeof caseInformationSchema>

  const attributesSchema = z
    .object({
      _id: z.string().nullable(),
      Department: z.string().nullable(),
      EncourageInsertedAt: z.string().nullable(),
      FirstNameKana: z.string().nullable(),
      GraduationYear: z.string().nullable(),
      LastNameKana: z.string().nullable(),
      legacy_id: z.string().nullable(),
      Major: z.string().nullable(),
      University: z.string().nullable(),
    })
    .transform((attributes) => {
      return {
        ...attributes,
        EncourageInsertedAt: attributes?.EncourageInsertedAt
          ? new Date(attributes?.EncourageInsertedAt)
          : null,
      }
    })

  export const customerProfileSchema = z
    .object({
      AccountNumber: z.string().nullable(),
      AdditionalInformation: z.string().nullable(),
      Attributes: attributesSchema.optional(),
      BirthDate: z.string().nullable(),
      BusinessEmailAddress: z.string().nullable(),
      BusinessName: z.string().nullable(),
      BusinessPhoneNumber: z.string().nullable(),
      EmailAddress: z.string().nullable(),
      FirstName: z.string().nullable(),
      Gender: z.string().nullable(),
      HomePhoneNumber: z.string().nullable(),
      LastName: z.string().nullable(),
      MiddleName: z.string().nullable(),
      MobilePhoneNumber: z.string().nullable(),
      PartyType: partyTypeSchema.nullable(),
      PersonalEmailAddress: z.string().nullable(),
      PhoneNumber: z.string(),
      ProfileId: z.string(),
    })
    .transform((profile) => {
      return {
        ...profile,
        AccountNumber: profile.AccountNumber
          ? parseInt(profile.AccountNumber)
          : null,
        BirthDate: profile.BirthDate ? new Date(profile.BirthDate) : null,
      }
    })

  export const customerProfilesSchema = z.array(customerProfileSchema).min(1)

  export type CustomerProfile = z.infer<typeof customerProfileSchema>

  export const caseStatusesSchema = z.object({
    callAgain: z.string().array(),
    out: z.string().array(),
    root: z.string().array(),
    skip: z.string().array(),
  })

  export type CaseStatuses = z.infer<typeof caseStatusesSchema>

  export const caseStatusesResponseSchema = z.object({
    data: caseStatusesSchema,
    reqId: idSchema,
    success: z.string(),
  })

  export type CaseStatusesResponse = z.infer<typeof caseStatusesSchema>
}

export { ActivityFeedEntry, Decoder }
