import { unique } from "@/helpers/other"

import {
  UniversityGroup,
  useFetchAllFilterOptions,
} from "../api/filter-options"
import { FilterSelectionUniversity } from "../campaigns-types"

export function useFetchUniversityGroups() {
  const { data, error, isLoading } = useFetchAllFilterOptions()

  const groups = data?.data?.grouped_universities || []

  return { groups, error, isLoading }
}

export function useUniversityGroups() {
  const { error, groups, isLoading } = useFetchUniversityGroups()

  function flattenFilters(filters: FilterSelectionUniversity[]) {
    return aggregateUniversities(filters, groups)
  }

  return { flattenFilters, isLoading, error }
}

export function aggregateUniversities(
  filters: FilterSelectionUniversity[],
  universityGroups: UniversityGroup[],
) {
  const findUniversitiesByGroup = (group: string) => {
    const foundGroup = universityGroups.find(
      ({ group_name }) => group_name === group,
    )

    return foundGroup?.universities || []
  }

  const universitiesFromList = filters
    .filter(({ type }) => type === "university")
    .flatMap((filter) => filter.values)
  const universitiesFromGroups = filters
    .filter(({ type }) => type === "group")
    .flatMap((filter) => {
      return filter.values.flatMap((group) => findUniversitiesByGroup(group))
    })

  return unique([...universitiesFromList, ...universitiesFromGroups])
}
