import { useTranslation } from "react-i18next"

import { DateRange } from "@/components/ui/calendar"
import { Combobox, ComboboxOptions } from "@/components/ui/combobox"
import { RangePicker } from "@/components/ui/datepicker"

function OperationRow({
  dateRange,
  filterId,
  onDateRangeChange,
  onFilter,
  operationButton,
  options,
}: {
  dateRange?: DateRange
  filterId: string | null
  onDateRangeChange: (dateRange: DateRange | undefined) => void
  onFilter: (filterId: string | null) => void
  operationButton?: React.ReactNode
  options: ComboboxOptions<string>[]
}) {
  const { t } = useTranslation()

  return (
    <div className="my-4 flex flex-row justify-between">
      <div className="flex flex-row gap-4">
        <RangePicker date={dateRange} onChange={onDateRangeChange} />
        <Combobox<string, true>
          size="lg"
          options={options}
          value={filterId}
          onChange={onFilter}
          placeholder={t("performance.placeholder.search")}
          allowReset={true}
        />
      </div>
      {operationButton}
    </div>
  )
}

export default OperationRow
