import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import Icon from "@components/Icon"
import { ChevronDown } from "lucide-react"

const styles = {
  loadingContainer:
    "flex items-center justify-start min-w-36 gap-x-2 font-body-medium-bold text-neutral-700",
  agentContainer: "flex flex-row items-center gap-x-2",
  agentTextWrapper: "flex flex-col items-start",
  userKind: "text-neutral-500 font-label-small",
  userName: "text-neutral-700 font-body-medium-bold",
  icon: "w-10 h-10",
}

interface Props {
  agentKind?: string
  agentName: string
  isLoading?: boolean
}

const AgentProfile: FunctionComponent<Props> = ({
  agentKind,
  agentName,
  isLoading = false,
}) => {
  const { t } = useTranslation()

  return isLoading ? (
    <div className={styles.loadingContainer}>
      <Icon name="spinner" size="md" />
      <span data-sentry-unmask>{t("global.loadingProfile")}</span>
    </div>
  ) : (
    <div className={styles.agentContainer}>
      <Icon name="user" className={styles.icon} size="lg" />
      <div className={styles.agentTextWrapper}>
        <span className={styles.userKind} data-sentry-unmask>
          {agentKind && t(`header.${agentKind}`)}
        </span>
        <span className={styles.userName}>{agentName}</span>
      </div>
      <ChevronDown className="size-5" />
    </div>
  )
}

export default AgentProfile
