import React from "react"

/**
 *  Layout shared by both "default" and "call" views:
 * - The "aside" (with the call buttons) is positioned on the right side on desktop screens.
 * - On smaller screens, the "aside" is positioned at the top.
 */
export function TwoColsLayout({
  aside,
  main,
}: {
  aside: React.ReactNode
  main: React.ReactNode
}) {
  return (
    <div className="flex flex-col-reverse gap-4 lg:flex-row">
      <div className="lg:grow">{main}</div>
      <aside className="lg:w-[350px] lg:shrink-0 lg:grow-0 xl:w-[480px]">
        {aside}
      </aside>
    </div>
  )
}
