import { useTranslation } from "react-i18next"
import { z } from "zod"

import { AlertLoadingError } from "@/components/ui/alert"
import { Combobox } from "@/components/ui/combobox"
import { Skeleton } from "@/components/ui/skeleton"
import { useQueryAPI } from "@/hooks/query-api"

type Props = {
  onChange: (value: string) => void
  value: string | null
}

export function QueuePicker({ onChange, value }: Props) {
  const { t } = useTranslation()
  const { data, error, isLoading } = useFetchAvailableQueues()

  if (isLoading) {
    return (
      <div
        className={
          "flex h-[40px] w-96 items-center rounded-md border bg-neutral-100 px-3"
        }
      >
        <Skeleton className="h-4 w-full" />
      </div>
    )
  }

  if (error) {
    return (
      <AlertLoadingError
        error={error}
        title="Unable to load the list of available queues"
      />
    )
  }

  const options =
    data?.data?.map((queue: string) => ({
      label: queue,
      value: queue,
    })) || []

  return (
    <div>
      <Combobox
        options={options}
        onChange={onChange}
        value={value}
        size="lg"
        placeholder={t("campaigns.create.queuePlaceholder")}
      />
    </div>
  )
}

function useFetchAvailableQueues() {
  return useQueryAPI({
    apiEndPoint: "/campaign-filters/queues",
    params: { exclude_existing_campaigns: true },
    schema: QueueListResponseSchema,
  })
}

const QueueListResponseSchema = z.object({
  data: z.array(z.string()),
})
