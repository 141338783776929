import { isString } from "./typeguards"

const phonePrefixRegexp = /^(?<Prefix>(\+|00)?\d{2}[-\s]?)0?(?<PhoneNumber>.*)$/
const splitPhoneRegexp = /^(?<FormattedPhoneNumber>\d{2}-\d{4}-\d{4})$/
const nonDigitsRegexp = /\D/g

const splitPhoneNumber = (phone: string): string => {
  const match = phone.match(splitPhoneRegexp)
  const isFormatted = !!match?.groups?.FormattedPhoneNumber

  if (isFormatted) {
    return phone
  }

  const cleanedPhoneNumber = phone.replace(nonDigitsRegexp, "").split("")

  if (cleanedPhoneNumber.length < 2) {
    return phone
  }

  let formattedPhoneNumber = ""

  formattedPhoneNumber += `${cleanedPhoneNumber.slice(0, 2).join("")}-`

  cleanedPhoneNumber.splice(0, 2)

  while (cleanedPhoneNumber.length > 0) {
    if (cleanedPhoneNumber.length > 4) {
      formattedPhoneNumber += `${cleanedPhoneNumber.slice(0, 4).join("")}-`
      cleanedPhoneNumber.splice(0, 4)
    } else {
      formattedPhoneNumber += cleanedPhoneNumber.join("")
      break
    }
  }

  return formattedPhoneNumber
}

export const removePhonePrefix = (phone: string): string => {
  const match = phone.match(phonePrefixRegexp)

  if (!match || !match.groups) {
    return phone
  }

  return match?.groups?.PhoneNumber
}

export const formatPhoneNumber = (
  phoneNumber: string | null | undefined,
): string => {
  if (!phoneNumber) {
    return ""
  }

  const numberWithoutPrefix = removePhonePrefix(phoneNumber)

  const splitNumber = splitPhoneNumber(numberWithoutPrefix)

  return splitNumber
}

/**
 * Extract phone number from the voice contact in the provided agent instance.
 * If no agent, return null. If no contact or call, return null. If anonymous caller has
 * custom attribute specifying the number use that, otherwise return null.
 *
 * @param agent - The current agent object
 * @returns the phone number as a string, or null
 */
export const getContactPhoneNumber = (
  agent: connect.Agent | null,
): string | null => {
  if (!agent) {
    return null
  }

  const contact = agent.getContacts(connect.ContactType.VOICE)[0]

  if (!contact) {
    return null
  }

  const connection = contact.getInitialConnection()
  const endpoint = connection.getEndpoint()
  let phoneNumber = endpoint.phoneNumber

  if (phoneNumber == "anonymous") {
    const attributes = contact.getAttributes()
    phoneNumber = attributes?.customer_phone_number?.value
    if (!isString(phoneNumber)) {
      return null
    }
  }

  return phoneNumber
}
