/* eslint-disable react-refresh/only-export-components */
import { createContext, PropsWithChildren, useContext } from "react"

import { resolveLanguage } from "@/helpers/i18n"
import { Status } from "@/models/cases"

import { CallStatusDef, useFetchAllCallStatuses } from "./call-statuses"

type StatusesContextType = {
  getStatusDef: (status: Status) => CallStatusDef | undefined
  getStatusesByUseCase: (
    useCase: CallStatusDef["usageContext"][number],
  ) => CallStatusDef[]
  isLoading: boolean
  statuses: CallStatusDef[]
}

const StatusesContext = createContext<StatusesContextType | null>(null)

export function CallStatusesProvider({ children }: PropsWithChildren) {
  const { data, isLoading } = useFetchAllCallStatuses()

  const allStatuses = data?.data || []

  function getStatusDef(id: Status) {
    return allStatuses.find((status) => status.id === id)
  }

  function getStatusesByUseCase(
    useCase: CallStatusDef["usageContext"][number],
  ) {
    return allStatuses.filter((status) => status.usageContext.includes(useCase))
  }

  const state = {
    getStatusesByUseCase,
    statuses: allStatuses,
    isLoading,
    getStatusDef,
  }

  return (
    <StatusesContext.Provider value={state}>
      {children}
    </StatusesContext.Provider>
  )
}

export function useCallStatuses() {
  const state = useContext(StatusesContext)
  if (!state) {
    throw new Error("Must be used within a CallStatusesProvider")
  }

  return state
}

export function useCallStatusLabel(status: Status) {
  const state = useCallStatuses()
  if (state.isLoading) return "..."

  const language = resolveLanguage()

  const statusDef = state.getStatusDef(status)

  return statusDef ? statusDef[language] : status + ` [Not found]`
}
