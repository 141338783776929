import { useTranslation } from "react-i18next"
import { CallReportStatelessForm } from "@components/ACWForm/call-report-stateless-form"
import * as callViewValue from "@components/CallView/value"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { Button } from "@/components/ui/button"
import {
  DialogBody,
  DialogBodyError,
  DialogBodySuccess,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { config } from "@/config"
import { useCallPanelLogger } from "@/hooks/useLogger"
import { CaseWithActivityFeed } from "@/pages/Campaigns/student-details/student-details-api"
import { useCustomPanelHook } from "@/pages/CustomConnectPanel/hook"

import { useACWForm } from "../CallView/hook"
import { useAxiosClientContext } from "../core/AxiosInstanceProvider/useAxiosClient"

type Props = {
  close: (confirmed: boolean) => void
}

export function DialogConfirmBackToCallMode({ close }: Props) {
  const { t } = useTranslation()

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{t("global.modals.abandonCallReport.title")}</DialogTitle>
      </DialogHeader>
      <DialogBody>
        {t("global.modals.abandonCallReport.description")}
      </DialogBody>
      <DialogFooter>
        <Button variant="outline" onClick={() => close(false)}>
          {t("global.button.cancel")}
        </Button>
        <Button onClick={() => close(true)}>
          {t("global.button.confirm")}
        </Button>
      </DialogFooter>
    </DialogContent>
  )
}

export function StatusUpdateModal({
  onClose,
  studentCase,
}: {
  onClose: (value: boolean) => void
  studentCase: CaseWithActivityFeed
}) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const log = useCallPanelLogger()
  const form = useACWForm()
  const axiosClient = useAxiosClientContext()
  const { displayMode } = useCustomPanelHook()

  const sendCallReportMutation = useMutation({
    mutationFn: async (formData: callViewValue.Decoder.DecoderType) => {
      try {
        const createdAt = new Date()
        const comment = formData.memo
          ? {
              comment: formData.memo,
              created_at: createdAt,
            }
          : undefined
        const response = await axiosClient.post(
          `https://${config.apiEndpoint}/call-reports`,
          {
            campaign_id: studentCase.case.campaign_id,
            student_id: studentCase.case.student_id,
            status_event: {
              event_name: formData.reason || formData.status,
              created_at: createdAt,
              call_attempted: false,
              processing_duration_seconds: 0,
            },
            comment,
            scheduled_call_datetime: formData.scheduledCallDate,
            call_contact: undefined,
            stop_most_recent_task: true,
          },
        )

        const validResponse =
          await callViewValue.Decoder.saveReportSchema.parseAsync(response.data)

        if (!validResponse.success.length) {
          throw new Error("[update-status] Empty response from call report API")
        }

        return "validResponse"
      } catch (err) {
        throw new Error(
          `[update-status] Failed to update status: ${(err as Error).message}`,
          { cause: err },
        )
      }
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: ["/case"],
      })
    },
  })

  const handleSubmit = async () => {
    try {
      const formData = form.getValues()
      await sendCallReportMutation.mutateAsync(formData)
    } catch (err) {
      if (err instanceof Error) {
        log.error(err)
      }
    }
  }

  const caseStatuses =
    displayMode.current === "busy" || displayMode.current === "offline"
      ? displayMode.state.caseStatuses
      : null

  return (
    <DialogContent isPending={sendCallReportMutation.isPending}>
      <DialogHeader>
        {t("campaigns.dialogs.statusUpdate.addTitle")}
      </DialogHeader>
      {(sendCallReportMutation.isIdle || sendCallReportMutation.isPending) && (
        <>
          <DialogBody>
            <CallReportStatelessForm
              caseStatuses={caseStatuses}
              currentCase={studentCase}
              form={form}
              isLoadingAgent={false}
              callReportMode={"add-status"}
              onSave={handleSubmit}
              isNewItem={false}
              status="normal_ACW"
              inModal={true}
              title={null}
            />
          </DialogBody>
          <DialogFooter>
            <Button variant="outline" onClick={() => onClose(false)}>
              {t("global.button.cancel")}
            </Button>
            <Button
              disabled={!form.formState.isValid}
              onClick={handleSubmit}
              isLoading={sendCallReportMutation.isPending}
            >
              {t("campaigns.dialogs.statusUpdate.addButton")}
            </Button>
          </DialogFooter>
        </>
      )}
      {sendCallReportMutation.isSuccess && (
        <>
          <DialogBodySuccess>
            {t("campaigns.dialogs.statusUpdate.successDescription")}
          </DialogBodySuccess>
          <DialogFooter>
            <Button variant="outline" onClick={() => onClose(false)}>
              {t("global.button.close")}
            </Button>
          </DialogFooter>
        </>
      )}
      {sendCallReportMutation.isError && (
        <DialogBodyError>
          {t("campaigns.dialogs.statusUpdate.failedDescription")}
        </DialogBodyError>
      )}
    </DialogContent>
  )
}
