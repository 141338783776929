import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { ChevronLeft } from "lucide-react"

import { ColumnGroupDef } from "@/components/DashboardTable"
import { AlertLoadingError } from "@/components/ui/alert"
import { Combobox } from "@/components/ui/combobox"
import { RangePicker } from "@/components/ui/datepicker"
import { SentryLogger } from "@/helpers/sentryLogger"
import { isNull, isUndefined } from "@/helpers/typeguards"

import { formatColumnsFromMetadata, matchAgentName } from "../column-utils"
import {
  useFetchCampaignPerformance,
  useFetchCampaignPerformanceByAgent,
  useFilterParams,
} from "../hooks"
import SearchLink from "../search-link"
import { StatefulTable } from "../stateful-table"
import { Decoder } from "../value"

const sentryLogger = new SentryLogger()

export function DetailsByAgent() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { campaign_id } = useParams<{ campaign_id: string }>()
  const [searchParams, updateQueryParams] = useFilterParams()

  if (!campaign_id) {
    navigate("/performance/campaigns")
  }

  const agentsFetchResult = useFetchCampaignPerformanceByAgent(
    campaign_id,
    searchParams.dateRange,
  )
  const campaignFetchResult = useFetchCampaignPerformance(
    searchParams.dateRange,
    campaign_id,
  )

  return (
    <main className="flex h-full flex-col gap-y-4 px-8 pt-10">
      <SearchLink
        pathname="/performance/campaigns"
        className="flex cursor-pointer flex-row items-center"
      >
        <ChevronLeft />
        <span>{t("performance.tabs.campaign")}</span>
      </SearchLink>
      <div className="flex items-center justify-between">
        <h1 className="font-h2-bold text-neutral-700">{campaign_id}</h1>
      </div>
      <RangePicker
        date={searchParams.dateRange}
        onChange={(val) => updateQueryParams({ dateRange: val })}
      />
      <MetricsContent
        filterId={searchParams.agent_id || null}
        onFilter={(val) => updateQueryParams({ agent_id: val ?? undefined })}
        agentListResult={agentsFetchResult}
        campaignResult={campaignFetchResult}
      />
    </main>
  )
}

function MetricsContent({
  agentListResult,
  campaignResult,
  filterId,
  onFilter,
}: {
  agentListResult: ReturnType<typeof useFetchCampaignPerformanceByAgent>
  campaignResult: ReturnType<typeof useFetchCampaignPerformance>
  filterId: string | null
  onFilter: (val: string | null) => void
}) {
  const { t } = useTranslation()
  const [columnError, setColumnError] = useState<string | null>(null)

  const {
    data: agentListData,
    error: agentListError,
    isError: isAgentListError,
    isLoading: isAgentListLoading,
  } = agentListResult
  const {
    data: campaignData,
    error: campaignError,
    isError: isCampaignError,
    isLoading: isCampaignLoading,
  } = campaignResult

  // TODO refactor: side effects such as logging should be moved to effect hooks
  const campaignColumns = (() => {
    if (isUndefined(campaignData) || isUndefined(campaignData.data)) {
      return []
    }

    try {
      return formatColumnsFromMetadata(
        campaignData.data.parentMetricsMetadata,
        campaignData.data.metadata,
      )
    } catch (error) {
      if (error instanceof Error) {
        sentryLogger.error(error.message)
        setColumnError(error.message)
      }

      return []
    }
  })()

  const formattedMetrics = agentListData
    ? matchAgentName(
        agentListData.data?.metrics,
        agentListData.data?.agentMetadata || {},
      )
    : []

  // TODO refactor: side effects such as logging should be moved to effect hooks
  const agentTableColumns = (() => {
    if (isUndefined(agentListData) || isUndefined(agentListData.data)) {
      return []
    }

    const agentFirstColumn: ColumnGroupDef<Decoder.DisplayAgentMetric> = {
      header: t("performance.table.agentName"),
      cols: [
        {
          id: "fullName",
          subHeader: "",
          cell: (record) => <>{record.fullName}</>,
        },
      ],
    }

    try {
      return [
        agentFirstColumn,
        ...formatColumnsFromMetadata(
          agentListData?.data?.parentMetricsMetadata,
          agentListData?.data?.metadata,
        ),
      ]
    } catch (error) {
      if (error instanceof Error) {
        sentryLogger.error(error.message)
        setColumnError(error.message)
      }

      return []
    }
  })()

  const agentsOptions =
    formattedMetrics?.map((agent) => ({
      value: agent.agentId,
      label:
        typeof agent.fullName === "string" ? agent.fullName : agent.agentId,
    })) || []

  const displayAgentList = isNull(filterId)
    ? formattedMetrics
    : formattedMetrics.filter((item) => item.agentId === filterId)

  if (columnError) {
    return (
      <AlertLoadingError
        error={new Error()}
        title={t("performance.error.invalidFormat")}
      />
    )
  }

  return (
    <>
      <StatefulTable
        isLoading={isCampaignLoading}
        error={isCampaignError ? (campaignError as Error) : null}
        columnGroups={campaignColumns}
        items={campaignData?.data.metrics || []}
        getItemId={(item) => item.campaignId}
      />
      <h4 className="font-h4-bold text-neutral-700">
        {t("performance.detail.agentTitle")}
      </h4>
      <Combobox<string, true>
        size="lg"
        options={agentsOptions}
        value={filterId}
        onChange={onFilter}
        placeholder={t("performance.placeholder.search")}
        allowReset={true}
      />
      {
        <StatefulTable
          isLoading={isAgentListLoading}
          error={isAgentListError ? (agentListError as Error) : null}
          columnGroups={agentTableColumns}
          items={displayAgentList}
          getItemId={(item) => item.agentId}
        />
      }
    </>
  )
}
