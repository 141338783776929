import { MouseEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns"
import { Calendar as CalendarIcon, X } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Calendar, DateRange } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { getLocale } from "@/helpers/i18n"
import { cn } from "@/lib/utils"

const DatePicker = ({
  date,
  onChange,
  placeholder,
}: {
  date: Date | undefined
  onChange: (date: Date | undefined) => void
  placeholder?: string
}) => {
  const { t } = useTranslation()
  const locale = getLocale()

  const handleReset = (e: MouseEvent) => {
    e.stopPropagation()
    onChange(undefined)
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[280px] justify-between text-left font-normal",
            !date && "text-muted-foreground",
          )}
        >
          <CalendarIcon className="mr-2 size-4" />
          <span className="flex flex-1 justify-start">
            {date
              ? format(date, "PPP", { locale })
              : placeholder || t("global.datepicker.placeholder")}
          </span>
          <X className="ml-2 size-4 cursor-pointer" onClick={handleReset} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          locale={locale}
          mode="single"
          selected={date}
          onSelect={(date: Date | undefined) => onChange(date)}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}

const RangePicker = ({
  className,
  date,
  onChange,
  placeholder,
}: {
  className?: string
  date: DateRange | undefined
  onChange: (range: DateRange | undefined) => void
  placeholder?: string
}) => {
  const { t } = useTranslation()
  const locale = getLocale()
  const [openPopover, setOpenPopover] = useState(false)
  const [localDate, setLocalDate] = useState<DateRange | undefined>(date)

  useEffect(() => {
    setLocalDate(date)
  }, [date])

  const handleReset = (e: MouseEvent) => {
    e.stopPropagation()
    setLocalDate(undefined)
    onChange(undefined)
  }

  const handleQuickSelection = (type: string) => {
    const today = new Date()
    let range
    switch (type) {
      case "today":
        range = { from: startOfDay(today), to: endOfDay(today) }
        break
      case "thisWeek":
        range = { from: startOfWeek(today), to: endOfWeek(today) }
        break
      case "thisMonth":
      default:
        range = { from: startOfMonth(today), to: endOfMonth(today) }
    }
    setLocalDate(range)
  }

  const handleRangeSelect = (range: DateRange | undefined) => {
    setLocalDate(range)
  }

  const handleApply = () => {
    if (localDate?.from && localDate?.to) {
      setOpenPopover(false)
      onChange({
        from: startOfDay(localDate.from),
        to: endOfDay(localDate.to),
      })
    }
  }

  const handleCalendarToggle = (open: boolean) => {
    if (!open) {
      setLocalDate(date)
    }
    setOpenPopover(open)
  }

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover open={openPopover} onOpenChange={handleCalendarToggle}>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[300px] justify-between text-left font-normal",
              !date && "text-muted-foreground",
            )}
          >
            <CalendarIcon className="mr-2 size-4" />
            <span className="flex flex-1 justify-start">
              {localDate?.from ? (
                localDate.to ? (
                  <>
                    {format(localDate.from, "P", { locale })} -{" "}
                    {format(localDate.to, "P", { locale })}
                  </>
                ) : (
                  format(localDate.from, "P", { locale })
                )
              ) : (
                placeholder || t("global.datepicker.rangePlaceholder")
              )}
            </span>
            <X className="ml-2 size-4 cursor-pointer" onClick={handleReset} />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <div className="flex gap-6 text-primary-300">
            <Button
              variant={"ghost"}
              onClick={() => handleQuickSelection("today")}
            >
              {t("global.datepicker.today")}
            </Button>
            <Button
              variant={"ghost"}
              onClick={() => handleQuickSelection("thisWeek")}
            >
              {t("global.datepicker.thisWeek")}
            </Button>
            <Button
              variant={"ghost"}
              onClick={() => handleQuickSelection("thisMonth")}
            >
              {t("global.datepicker.thisMonth")}
            </Button>
          </div>
          <Calendar
            initialFocus
            locale={locale}
            mode="range"
            defaultMonth={date?.from}
            selected={localDate}
            onSelect={(range: DateRange | undefined) =>
              handleRangeSelect(range)
            }
            numberOfMonths={2}
          />
          <div className="flex justify-end p-2">
            <Button
              onClick={handleApply}
              size="sm"
              variant="outline"
              disabled={!localDate?.from || !localDate?.to}
            >
              {t("global.button.apply")}
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export { DatePicker, RangePicker }
