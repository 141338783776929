import { useTranslation } from "react-i18next"
import { LineChartIcon, X } from "lucide-react"

import Icon from "@/components/Icon"
import { AlertLoadingError } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer"

import { useFetchStatusStats } from "../api/metrics"

type Props = {
  campaign_id: string
  total: number
}

export function StatusStatsDrawer({ campaign_id, total }: Props) {
  const { t } = useTranslation()

  return (
    <Drawer direction="right">
      <DrawerTrigger asChild>
        <Button variant="outline" size="sm">
          <LineChartIcon className="size-4" />
          {t("campaigns.details.viewStatuses")}
        </Button>
      </DrawerTrigger>
      <DrawerContent className="left-auto right-0 top-0 mt-0 h-screen w-[500px] rounded-none">
        <DrawerHeader>
          <div className="flex items-center justify-between">
            <DrawerTitle>{t("campaigns.details.viewStatuses")}</DrawerTitle>
            <DrawerClose asChild>
              <Button
                variant="ghost"
                size="icon"
                className="relative -right-2 -top-2"
              >
                <X className="size-6" />
              </Button>
            </DrawerClose>
          </div>
        </DrawerHeader>
        {/* Show a scrollbar if needed on smaller screens, to scroll through the 19 statuses */}
        <div className="overflow-y-auto">
          <FetchStatusList campaign_id={campaign_id} />
        </div>
        <DrawerFooter className="bg-neutral-200 pb-16 text-lg">
          <div className="flex items-center justify-between">
            <div>{t("campaigns.list.studentCount")}:</div>
            <div className="text-2xl">
              {t("global.peopleCount", { count: total })}
            </div>
          </div>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

function FetchStatusList({ campaign_id }: Pick<Props, "campaign_id">) {
  const { t } = useTranslation()

  const { error, isLoading, statuses } = useFetchStatusStats(campaign_id)

  if (error) {
    return (
      <AlertLoadingError
        className="mx-4"
        error={error}
        title="Failed to load status stats"
      />
    )
  }

  if (isLoading) {
    return (
      <div className="mx-4 flex justify-center rounded border p-4">
        <Icon name="spinner" size="lg" />
      </div>
    )
  }

  return statuses.length === 0 ? (
    <div className="m-4 flex h-36 items-center justify-center rounded border">
      {t("global.emptySubtitle")}
    </div>
  ) : (
    <StatusList statuses={statuses} />
  )
}

function StatusList({
  statuses,
}: {
  statuses: { count: number; label: string }[]
}) {
  return (
    <div className="divide-y">
      {statuses.map(({ count, label }) => (
        <div key={label} className="flex justify-between px-4 py-2">
          <div>{label}:</div>
          <div>{count}</div>
        </div>
      ))}
    </div>
  )
}
