import { EllipsisIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Skeleton } from "@/components/ui/skeleton"

import { useFetchAllFilterOptions } from "../api/filter-options"
import { FilterSelectionChannel } from "../campaigns-types"
import { FilterControlLabel } from "../pickers/generic-filter-picker"

export function ChannelsControl({
  channels,
  onEdit,
}: {
  channels: FilterSelectionChannel[]
  onEdit: () => void
}) {
  const { getChannelName, isLoading } = useLookupChannelDetails()
  const channelNames = channels.map((channel) =>
    getChannelName(channel.channel_id),
  )

  return (
    <Button
      variant="outline"
      onClick={onEdit}
      className="w-campaign-filter justify-between"
      size="sm"
    >
      {isLoading ? (
        <Skeleton className="h-4 w-full" />
      ) : (
        <FilterControlLabel filterKey="channels" labels={channelNames} />
      )}
      <EllipsisIcon className="size-4 opacity-50" />
    </Button>
  )
}

function useLookupChannelDetails() {
  const { data, isLoading } = useFetchAllFilterOptions()

  const channels = data?.data?.channels_questions_answers || []

  function getChannelName(channel_id: number) {
    const channel = channels.find(
      (channel) => channel.channel_id === channel_id,
    )

    return channel?.channel_title || channel_id.toString()
  }

  return { getChannelName, isLoading }
}
