import { useState } from "react"
import { useTranslation } from "react-i18next"
import { LoaderCircle } from "lucide-react"

import { useAxiosClientContext } from "@/components/core/AxiosInstanceProvider/useAxiosClient"
import { useModal } from "@/components/core/Root/modal-root"
import { Button } from "@/components/ui/button"
import { DateRange } from "@/components/ui/calendar"
import { RangePicker } from "@/components/ui/datepicker"
import {
  DialogBody,
  DialogBodyError,
  DialogBodySuccess,
  DialogBodyWithIcon,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@/components/ui/dialog"

type ExportModalStatus = "error" | "initial" | "loading" | "success"

type InitialModalBodyProps = {
  dateRange: DateRange | undefined
  onClose: (res: boolean) => void
  onDateRangeChange: (dateRange: DateRange | undefined) => void
  onSubmit: () => void
}

const InitialModalBody = ({
  dateRange,
  onClose,
  onDateRangeChange,
  onSubmit,
}: InitialModalBodyProps) => {
  const { t } = useTranslation()

  return (
    <>
      <DialogHeader />
      <DialogBody className="flex flex-col gap-8">
        <h3 className="text-xl font-medium text-neutral-700">
          {t("performance.export.confirmTitle")}
        </h3>
        <div className="flex flex-col gap-6">
          <div>
            <div className="text-sm text-neutral-500">
              {t("performance.export.dateSelectText")}
            </div>
            <RangePicker date={dateRange} onChange={onDateRangeChange} />
          </div>
          <div>
            <div className="text-sm text-neutral-500">
              {t("performance.export.campaignConfirmText")}
            </div>
            <div>{t("performance.export.allCampaigns")}</div>
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button variant={"outline"} onClick={() => onClose(false)}>
          {t("global.button.cancel")}
        </Button>
        <Button onClick={onSubmit}>
          {t("performance.export.downloadRequestButton")}
        </Button>
      </DialogFooter>
    </>
  )
}

const LoadingModalBody = () => {
  const { t } = useTranslation()

  return (
    <>
      <DialogHeader>
        <div className="text-xl font-medium text-neutral-700">
          {t("performance.export.loadingTitle")}
        </div>
      </DialogHeader>
      <DialogBodyWithIcon
        icon={<LoaderCircle className="size-5 animate-spin text-primary-400" />}
      >
        <div className="text-sm text-neutral-500">
          {t("performance.export.loadingDescription")}
        </div>
      </DialogBodyWithIcon>
    </>
  )
}

const SuccessModalBody = ({
  handleDownload,
}: {
  handleDownload: () => void
}) => {
  const { t } = useTranslation()

  return (
    <>
      <DialogHeader />
      <DialogBodySuccess>
        <div className="flex flex-col gap-4">
          <div className="text-xl font-medium text-neutral-700">
            {t("performance.export.successTitle")}
          </div>
          <div className="text-neutral-500">
            {t("performance.export.successDescription")}
          </div>
        </div>
      </DialogBodySuccess>
      <DialogFooter>
        <Button onClick={handleDownload}>
          {t("performance.export.downloadButton")}
        </Button>
      </DialogFooter>
    </>
  )
}

const ErrorModalBody = ({ error }: { error?: string }) => {
  const { t } = useTranslation()

  return (
    <>
      <DialogHeader>
        <div className="text-xl font-medium text-neutral-700">
          {t("errors.login.unknownError")}
        </div>
      </DialogHeader>
      <DialogBodyError>
        <div className="text-neutral-500">{error}</div>
      </DialogBodyError>
    </>
  )
}

function ExportModal({
  initDateRange,
  onClose,
}: {
  initDateRange?: DateRange
  onClose: (res: boolean) => void
}) {
  const axiosClient = useAxiosClientContext()

  const [status, setStatus] = useState<ExportModalStatus>("initial")
  const [dateRange, setDateRange] = useState<DateRange | undefined>(
    initDateRange,
  )
  const [downloadURL, setDownloadURL] = useState<string | undefined>(undefined)
  const [error, setError] = useState<string | undefined>(undefined)

  const handleRequest = async () => {
    setStatus("loading")
    try {
      const res = await axiosClient.get(
        "metrics/campaigns/summary/export?language=ja",
        {
          params: {
            date_from: dateRange?.from,
            date_to: dateRange?.to,
          },
        },
      )
      if (res.data && typeof res.data.data === "string") {
        setStatus("success")
        setDownloadURL(res.data.data)
      } else {
        throw new Error(res.data?.message || "Unknown error")
      }
    } catch (e) {
      setStatus("error")
      setError((e as Error).message || "Unknown error")
    }
  }

  const handleDownload = () => {
    if (downloadURL) {
      window.open(downloadURL, "_blank")
    }
    onClose(true)
  }

  return (
    <DialogContent isPending={status === "loading"}>
      {status === "initial" && (
        <InitialModalBody
          onClose={onClose}
          onSubmit={handleRequest}
          dateRange={dateRange}
          onDateRangeChange={setDateRange}
        />
      )}
      {status === "loading" && <LoadingModalBody />}
      {status === "error" && <ErrorModalBody error={error} />}
      {status === "success" && (
        <SuccessModalBody handleDownload={handleDownload} />
      )}
    </DialogContent>
  )
}

function ExportButton({ initDateRange }: { initDateRange?: DateRange }) {
  const { t } = useTranslation()
  const { showModal } = useModal()

  const showExportModal = async () => {
    await showModal((close) => (
      <ExportModal initDateRange={initDateRange} onClose={close} />
    ))
  }

  return (
    <>
      <Button onClick={showExportModal}>
        {t("performance.export.csvDownload")}
      </Button>
    </>
  )
}

export default ExportButton
