export function truncateList(
  items: string[],
  numberOfCharacters: number,
  separator = ", ",
) {
  const visibleItems: string[] = []
  const hiddenItems: string[] = []
  let isExtraItem = false

  items.forEach((item) => {
    if (isExtraItem) {
      return hiddenItems.push(item)
    }
    if ([...visibleItems, item].join(separator).length > numberOfCharacters) {
      hiddenItems.push(item)
      const isTruncatedElement =
        visibleItems.join(separator).length + item.length > numberOfCharacters
      if (isTruncatedElement) {
        const truncatedText = item.slice(
          0,
          numberOfCharacters - visibleItems.join(separator).length,
        )
        visibleItems.push(truncatedText + "...")
      }
      isExtraItem = true

      return
    }
    visibleItems.push(item)
  })

  return { visibleItems, hiddenItems }
}
