import { Badge } from "@/components/ui/badge"
import { TooltipWrapper } from "@/components/ui/tooltip"
import { useI18n } from "@/helpers/i18n"

import { CampaignStatus } from "../campaigns-types"

type Props = { status: CampaignStatus }

export function CampaignBadge({ status }: Props) {
  const { t, tMap } = useI18n()

  const label = t(`campaigns.statuses.${status}.label`)

  switch (status) {
    case "active":
      return <Badge variant="success">{label}</Badge>
    case "paused":
      return <Badge variant="danger">{label}</Badge>

    case "stopping":
    case "starting":
      return <Badge variant="inactive">{label}</Badge>
    case "starting-failure":
    case "stopping-failure":
      return (
        <TooltipWrapper
          tooltip={tMap(
            `campaigns.statuses.${status}.explanation`,
            (message, i) => (
              <p key={i}>{message}</p>
            ),
          )}
        >
          <Badge variant="inactive">{label}</Badge>
        </TooltipWrapper>
      )
  }
}
