import { ChangeEvent } from "react"
import { FieldValues } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Label from "@components/core/Label"
import { cn } from "@helpers/classNames"
import { isDefined } from "@helpers/typeguards"
import { useErrorMessage } from "@hooks/errorMessage"
import {
  FormInputProps,
  ForwardedRefProps,
  HTMLPassedProps,
  Orientation,
} from "@typedefs/props"

type AutocompleteMode = "off" | "on"

const styles = {
  container: (orientation: Orientation, isDisabled: boolean) =>
    cn([
      "font-body-medium-regular flex w-full",
      orientation === "horizontal"
        ? "flex-row items-baseline gap-x-3"
        : "flex-col gap-y-2",
      isDisabled && "pointer-events-none text-neutral-400",
    ]),
  textAreaContainer: (isDisabled: boolean, hasError: boolean) =>
    cn([
      "rounded border",
      isDisabled ? "bg-neutral-200" : "bg-neutral-100",
      hasError ? "border-danger-300" : "border-neutral-300",
    ]),
  textArea: (isDisabled: boolean) =>
    cn([
      "h-20 w-full cursor-pointer rounded px-6 pb-2 pt-4 placeholder:text-neutral-400 focus:outline-none",
      isDisabled ? "bg-neutral-200" : "bg-neutral-100",
      !isDisabled && "text-neutral-700",
    ]),
  textAreaFooter: (isDisabled: boolean) =>
    cn([
      "font-form-regular flex justify-end border-t border-neutral-300 bg-neutral-200 p-2",
      !isDisabled && "text-neutral-600",
    ]),
  error: "text-danger-300 font-paragraph-small-regular",
}

interface Props<FieldsType extends FieldValues>
  extends HTMLPassedProps<"value">,
    FormInputProps<FieldsType>,
    ForwardedRefProps<HTMLTextAreaElement> {
  autocomplete?: AutocompleteMode
  maxLength?: number
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void
}

const TextArea = <FieldsType extends FieldValues>({
  autocomplete = "off",
  className,
  clearErrors,
  disabled: isDisabled = false,
  errorLabel,
  errors,
  forwardedRef,
  inputClassName,
  label,
  labelClassName,
  maxLength,
  name,
  onBlur,
  onChange,
  onFocus,
  orientation = "horizontal",
  placeholder,
  required: isRequired = false,
  value,
}: Props<FieldsType>) => {
  const { t } = useTranslation()

  const error = useErrorMessage(
    name,
    errors,
    isDefined(errorLabel) ? t(errorLabel) : isDefined(label) ? t(label) : name,
  )

  return (
    <div className={cn([styles.container(orientation, isDisabled), className])}>
      {isDefined(label) && (
        <Label
          label={label}
          labelClassName={labelClassName}
          required={isRequired}
          isDisabled={isDisabled}
        />
      )}
      <div className={styles.textAreaContainer(isDisabled, isDefined(error))}>
        <textarea
          name={name}
          className={cn([styles.textArea(isDisabled), inputClassName])}
          autoComplete={autocomplete}
          placeholder={isDefined(placeholder) ? t(placeholder) : undefined}
          onChange={onChange}
          onBlur={() => onBlur && onBlur()}
          onFocus={(event) => {
            clearErrors && clearErrors(name)
            onFocus && onFocus(event)
          }}
          value={value}
          ref={forwardedRef}
          disabled={isDisabled}
        />
        <div className={styles.textAreaFooter(isDisabled)}>
          {typeof value === "string" && (
            <span>{`${value.length}/${maxLength ?? "~"}`}</span>
          )}
        </div>
      </div>
      {isDefined(error) && (
        <div className={styles.error} data-sentry-unmask>
          {error}
        </div>
      )}
    </div>
  )
}

export default TextArea
