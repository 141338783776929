import { FunctionComponent } from "react"
import Icon from "@components/Icon"

interface Props {
  errorDescription?: string
  errorMessage: string
  errorStatus?: number
}

const styles = {
  pageContainer:
    "w-full h-full px-8 py-36 flex flex-col gap-y-8 text-neutral-700 items-center",
  icon: "text-danger-200",
  errorMsgContainer: "flex flex-col justify-center",
  status: "font-body-large-regular",
  message: "font-h1-bold",
  description: "font-body-large-regular",
}

const RouteError: FunctionComponent<Props> = ({
  errorDescription,
  errorMessage,
  errorStatus,
}) => {
  return (
    <div className={styles.pageContainer} data-sentry-unmask>
      <Icon size="lg" name="alert" className={styles.icon} />
      <div className={styles.errorMsgContainer}>
        {errorStatus && <p className={styles.status}>{errorStatus}</p>}
        {errorMessage && <h1 className={styles.message}>{errorMessage}</h1>}
        {errorDescription && (
          <p className={styles.description}>{errorDescription}</p>
        )}
      </div>
    </div>
  )
}

export default RouteError
