import { useLifecycles } from "react-use"
import { useCognitoTimeoutContext } from "@components/core/CognitoTimeoutProvider/useCognitoTimeoutContext"
import { cognitoModeAtom } from "@helpers/atoms"
import { useAuthHook } from "@hooks/auth"
import { CognitoMode } from "@typedefs/modes"
import { useAtomValue } from "jotai"

// TODO: Setting the mode should be a state machine instead.
// Replace with an useReducer maybe
type UseProtectedRouteHookType = {
  cognitoMode: CognitoMode
}

const useProtectedRouteHook = (): UseProtectedRouteHookType => {
  const cognitoMode = useAtomValue(cognitoModeAtom)
  const timerIdRef = useCognitoTimeoutContext()
  const { checkAuth } = useAuthHook()

  // Validate access token on page render and start a timer to refresh it
  // useEffect with cleanup function
  useLifecycles(
    () => checkAuth(),
    () => clearTimeout(timerIdRef.current),
  )

  return { cognitoMode }
}

export { useProtectedRouteHook }
