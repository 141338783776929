import { createContext, MutableRefObject, useContext } from "react"
import { isNull } from "@helpers/typeguards"

// A timeout for the Cognito access token refresh
type CognitoTimeoutContextType = MutableRefObject<NodeJS.Timeout | undefined>

const CognitoTimeoutContext = createContext<CognitoTimeoutContextType | null>(
  null,
)

const useCognitoTimeoutContext = (): CognitoTimeoutContextType => {
  const context = useContext(CognitoTimeoutContext)

  if (isNull(context)) {
    throw new Error(
      "CognitoTimeoutContext must be instantiated in the Root component",
    )
  }

  return context
}

export {
  CognitoTimeoutContext,
  type CognitoTimeoutContextType,
  useCognitoTimeoutContext,
}
