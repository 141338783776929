import { z } from "zod"

import { resolveLanguage } from "@/helpers/i18n"
import { useFetchAllCallStatuses } from "@/hooks/call-statuses"
import { useQueryAPI } from "@/hooks/query-api"

import { parseCampaignMetricStatuses } from "./parse-campaign-metric-statuses"

const messageSchema = z.object({ en: z.string(), ja: z.string() })

const metaItemSchema = z.object({
  name: messageSchema,
  additionalInfo: messageSchema,
  metricType: messageSchema,
  isInterval: z.boolean(),
  metricId: z.string(),
})

export const metricMetaSchema = z.record(metaItemSchema)

const statusMetricMetaSchema = z.record(
  metaItemSchema.extend({
    statusId: z.string(),
  }),
)

export const campaignMetricsResponseSchema = z.object({
  data: z.object({
    metrics: z.array(
      z.object({
        campaignId: z.string(),
        metricData: z.record(z.number().nullable()),
      }),
    ),
    metadata: statusMetricMetaSchema,
  }),
})

export type CampaignMetricsResponse = z.infer<
  typeof campaignMetricsResponseSchema
>

/**
 * Fetch the list of count by statuses in the right order and with the right labels
 * by combining 2 different API calls
 */
export function useFetchStatusStats(campaign_id: string) {
  const {
    error: errorMetrics,
    isLoading: isLoadingMetrics,
    statusesMap,
  } = useFetchCampaignStatusesViewData(campaign_id)

  const {
    data: allStatusesData,
    error: errorStatuses,
    isLoading: isLoadingStatuses,
  } = useFetchAllCallStatuses()

  const language = resolveLanguage()

  const statuses =
    statusesMap && allStatusesData?.data
      ? allStatusesData?.data
          .filter((item) => statusesMap.has(item.id)) // filter out statuses not included in metrics response (E.g. `sonomama`)
          .map((item) => ({
            id: item.id,
            label: item[language],
            count: statusesMap.get(item.id)?.count || 0,
          }))
      : []

  return {
    statuses,
    isLoading: isLoadingMetrics || isLoadingStatuses,
    error: errorMetrics || errorStatuses,
  }
}

function useFetchCampaignStatusesViewData(campaign_id: string) {
  const { data, ...rest } = useQueryAPI({
    apiEndPoint: "/metrics/campaign/status",
    params: {
      campaign_id,
    },
    schema: campaignMetricsResponseSchema,
  })

  const statusesMap = data
    ? parseCampaignMetricStatuses(data, campaign_id)
    : null

  return { data, statusesMap, ...rest }
}

export function extractMetricDescription(
  metadata: z.infer<typeof metricMetaSchema>,
  metricId: string,
) {
  const metric = metadata[metricId]
  const language = resolveLanguage()

  return metric ? metric.additionalInfo[language] : "<No description>"
}
