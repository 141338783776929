import { FunctionComponent, memo } from "react"
import { cn } from "@helpers/classNames"

import { useCustomerProfilesHook } from "./hook"

const styles = {
  container: (isHidden: boolean | undefined) => {
    return cn(["h-[500px] w-full", isHidden && "hidden"])
  },
}

interface CustomerProfileProps {
  isHidden?: boolean
}

const CustomerProfilesPanel: FunctionComponent<CustomerProfileProps> = ({
  isHidden,
}) => {
  const { appContainer, ref } = useCustomerProfilesHook()

  return (
    <div
      id={appContainer}
      ref={ref}
      className={styles.container(isHidden)}
    ></div>
  )
}

const MemoizedCustomerProfilesPanel = memo(CustomerProfilesPanel)

export default MemoizedCustomerProfilesPanel
