import { ValidateResult } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { hasValue, isNullish } from "@helpers/typeguards"
import { isAfter, isValid } from "date-fns"

interface UseValidationHookType {
  isHiragana: (value: string) => ValidateResult
  isKanji: (value: string) => ValidateResult
  isValidPassword: (value: string) => ValidateResult
  validDate: (
    value: Date | null | undefined,
    required: boolean,
  ) => ValidateResult
  validEmailAddress: (value: string) => ValidateResult
  validStartDateTime: (
    value: Date | null | undefined,
    required: boolean,
  ) => ValidateResult
}

const lowercaseRegex = /[a-z]/
const uppercaseRegex = /[A-Z]/
const hasNumberRegex = /\d/
const specialCharsRegex = /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]/
const invalidCharsRegexp = /[^\w^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]/
const hiraganaRegex = /^[\u3040-\u309F]+$/
const kanjiRegexp = /[\u4e00-\u9faf]/
const emailRegexp =
  /^[\w!#$%&'*+\-/=?^`{|}~]+(?:\.[\w!#$%&'*+\-/=?^`{|}~]+)*@(?:[a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]{1,63}\.[a-zA-Z]{2,63}$/

const useFormValidation = (): UseValidationHookType => {
  const { t } = useTranslation()

  const validMinLength = (value: string): boolean => value.length >= 8

  const validMaxLength = (value: string): boolean => value.length < 100

  const containsLowerCasedChar = (value: string): boolean =>
    lowercaseRegex.test(value)

  const containsUpperCasedChar = (value: string): boolean =>
    uppercaseRegex.test(value)

  const containsNumbers = (value: string): boolean => hasNumberRegex.test(value)

  const containsSpecialChars = (value: string): boolean =>
    specialCharsRegex.test(value)

  const containsInvalidChars = (value: string): boolean =>
    invalidCharsRegexp.test(value)

  const isHiragana = (value: string): ValidateResult =>
    hiraganaRegex.test(value)

  const isKanji = (userInput: string): ValidateResult =>
    kanjiRegexp.test(userInput)

  const isValidEmail = (value: string): ValidateResult =>
    emailRegexp.test(value)

  const isValidPassword = (value: string): ValidateResult => {
    if (
      !validMinLength(value) ||
      !validMaxLength(value) ||
      !containsLowerCasedChar(value) ||
      !containsUpperCasedChar(value) ||
      !containsNumbers(value) ||
      !containsSpecialChars(value) ||
      containsInvalidChars(value)
    ) {
      return t("errors.login.passwordFormat")
    }

    return true
  }

  const validDate = (
    value: Date | null | undefined,
    required = false,
  ): ValidateResult => {
    if (!required && isNullish(value)) {
      return true
    }

    if (hasValue(value)) {
      return isValid(value)
    }

    return false
  }

  const validStartDateTime = (
    value: Date | null | undefined,
    required = false,
  ): ValidateResult => {
    if (isNullish(value)) {
      return !required
    }

    const now = new Date()

    return isAfter(value, now)
  }

  // Using g flag results in error when running test multiple times https://stackoverflow.com/a/9275499
  const validEmailAddress = (value: string): ValidateResult => {
    if (!isValidEmail(value)) {
      return t("errors.login.emailFormat")
    }

    return true
  }

  return {
    isValidPassword,
    isHiragana,
    isKanji,
    validDate,
    validEmailAddress,
    validStartDateTime,
  }
}

export { useFormValidation }
