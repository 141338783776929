import { useEffect } from "react"
import {
  currentContactStatusTypeAtom,
  currentContactTypeAtom,
} from "@helpers/atoms"
import { useAtomValue } from "jotai"

interface CallButtonnsInputType {
  isInboundCall: boolean
  setHasCalled: (hasCalled: boolean) => void
}

interface CallButtonsHookType {
  currentContactType: connect.ContactType | null
}

const useConnectedCallButtonsHook = ({
  isInboundCall,
  setHasCalled,
}: CallButtonnsInputType): CallButtonsHookType => {
  const currentContactType = useAtomValue(currentContactTypeAtom)
  const currentContactStatusType = useAtomValue(currentContactStatusTypeAtom)
  const isConnectedOutboundVoiceCall =
    currentContactStatusType === connect.ContactStateType.CONNECTED &&
    currentContactType === connect.ContactType.VOICE &&
    !isInboundCall

  // First time when the outbound call is connected set a boolean to hide the
  // call buttons when switching to the underlying task (after the call's ACW)
  useEffect(() => {
    if (isConnectedOutboundVoiceCall) {
      setHasCalled(true)
    }
  }, [isConnectedOutboundVoiceCall]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    currentContactType,
  }
}

export { useConnectedCallButtonsHook }
