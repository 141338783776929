import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"

import { Alert } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import {
  DialogBody,
  DialogBodySuccess,
  DialogFooter,
} from "@/components/ui/dialog"

type Props = PropsWithChildren<{
  action: React.ReactNode
  close: (value: boolean) => void
  errorMessage: React.ReactNode
  status: "error" | "idle" | "pending" | "success" // React Query mutation statuses
  successMessage: React.ReactNode
}>

/** A generic Dialog component to handle campaign mutations */
export function DialogMutation({
  action,
  children,
  close,
  errorMessage,
  status,
  successMessage,
}: Props) {
  const { t } = useTranslation()

  return (
    <>
      {(status === "idle" || status === "pending") && (
        <>
          <DialogBody>{children}</DialogBody>
          <DialogFooter>
            <Button
              type="button"
              onClick={() => close(false)}
              variant="outline"
              isDisabled={status === "pending"}
            >
              {t("global.button.cancel")}
            </Button>
            {action}
          </DialogFooter>
        </>
      )}

      {status === "success" && (
        <>
          <DialogBodySuccess>{successMessage}</DialogBodySuccess>
          <DialogFooter>
            <Button type="button" onClick={() => close(true)} variant="outline">
              {t("global.button.close")}
            </Button>
          </DialogFooter>
        </>
      )}

      {status === "error" && (
        <>
          <DialogBody>
            <Alert variant="destructive">{errorMessage}</Alert>
          </DialogBody>
          <DialogFooter>
            <Button
              type="button"
              onClick={() => close(false)}
              variant="outline"
            >
              {t("global.button.close")}
            </Button>
          </DialogFooter>
        </>
      )}
    </>
  )
}
