import { z } from "zod"

import { useQueryAPI } from "@/hooks/query-api"
import { useFetchCampaign } from "@/pages/Campaigns/api/campaigns"

const getQueueARNSchema = z.object({
  data: z.object({
    queue_name: z.string(),
    queue_arn: z.string(),
    is_enabled: z.boolean(),
  }),
})

const useGetEnabledCallConditions = (queueName: string) => {
  const fetchQueueARNQuery = useQueryAPI({
    apiEndPoint: `/aws-connect/queue-arn/${queueName}`,
    schema: getQueueARNSchema,
  })

  const fetchCampaignQuery = useFetchCampaign(queueName)

  return { fetchCampaignQuery, fetchQueueARNQuery }
}

export { useGetEnabledCallConditions }
