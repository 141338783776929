import { initReactI18next } from "react-i18next"
import i18n, { InitOptions } from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import { en } from "./en"
import { ja } from "./ja"

const translations = { en, ja }

export async function initI18n(options: InitOptions) {
  const initOptions: InitOptions = {
    debug: import.meta.env.DEV && !(import.meta.env.MODE === "test"),
    interpolation: { escapeValue: false },
    resources: translations,
    ...options,
  }

  await i18n.use(LanguageDetector).use(initReactI18next).init(initOptions)
}
