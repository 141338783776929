import { StrictMode } from "react"
import ReactDOM from "react-dom/client"

import Root from "./components/core/Root"

import "./helpers/i18n"
import "amazon-connect-streams"
import "amazon-connect-taskjs"
import "amazon-connect-customer-profiles"

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <Root />
  </StrictMode>,
)
