import { z } from "zod"

namespace Encoder {
  export interface EncoderType {
    email: string
  }

  export const defaultValues: EncoderType = {
    email: "",
  }
}

namespace Decoder {
  export const schema = z.object({
    email: z.string().email().min(6),
  })
}

export { Decoder, Encoder }
