import { Toaster as RawToaster } from "react-hot-toast"

const styles = {
  container: "font-body-large-bold",
}

const Toaster = () => {
  return (
    <RawToaster
      position="top-right"
      toastOptions={{
        className: styles.container,
        success: {
          style: {
            color: "#44C1A8", // success-300
            background: "#DDF7F2", // success-100,
          },
          iconTheme: {
            primary: "#44C1A8",
            secondary: "#FFFFFF", // neutral-100
          },
        },
        error: {
          style: {
            color: "#C94769", // danger-300
            background: "#F7DDE4", // danger-100
          },
          iconTheme: {
            primary: "#C94769",
            secondary: "#FFFFFF", // neutral-100
          },
        },
      }}
    />
  )
}

export { Toaster }
