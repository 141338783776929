import { useState } from "react"

import { useAxiosClientContext } from "@/components/core/AxiosInstanceProvider/useAxiosClient"
import { parseDataFromAPI } from "@/hooks/query-api"
import { useLogger } from "@/hooks/useLogger"

import { UploadResponse, uploadResponseSchema } from "./import-csv-schemas"

type Step = "error" | "initial" | "processing" | "success" // a basic state machine with 4 steps

export function useImportCSV(campaign_id: string) {
  const axiosClient = useAxiosClientContext()
  const [step, setStep] = useState<Step>("initial")
  const [file, setFile] = useState<File | null>(null)
  const [results, setResults] = useState<UploadResponse | null>(null)
  const log = useLogger()

  async function upload() {
    setStep("processing")
    try {
      const formData = new FormData()
      formData.append("file", file as Blob)

      const { data } = await axiosClient.post(
        "/students/upload-status-csv",
        formData,
        {
          params: {
            campaign_id,
          },
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )

      const results = await parseDataFromAPI(data, uploadResponseSchema)

      setResults(results)
      setStep("success")

      return data
    } catch (error) {
      log.error(error, { file: file?.name, campaign_id })
      setStep("error")
    }
  }

  function reset() {
    setFile(null)
    setStep("initial")
  }

  function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFile(e.target.files?.[0] || null)
  }

  return { onFileChange, upload, file, step, results, reset }
}
