import { useTranslation } from "react-i18next"

import { PageBreadcrumb } from "@/components/ui/breadcrumb"

/** Layout shared by Create and Edit Campaign pages */
export function CreateEditPageLayout({
  actionBar,
  campaign_id,
  filtersArea,
  header,
  previewArea,
  priorityFiltersArea,
}: {
  actionBar?: React.ReactNode
  campaign_id?: string
  filtersArea?: React.ReactNode
  header: React.ReactNode
  previewArea: React.ReactNode
  priorityFiltersArea?: React.ReactNode
}) {
  const { t } = useTranslation()

  return (
    <main className="pt-10">
      <div className="container flex flex-col gap-y-6">
        <PageBreadcrumb
          items={[
            {
              label: t("campaigns.details.viewList"),
              path: "/campaigns",
            },
            ...(campaign_id
              ? [
                  {
                    label: campaign_id,
                    path: `/campaigns/${campaign_id}`,
                  },
                  {
                    label: t("campaigns.edit.breadcrumb"),
                  },
                ]
              : [
                  {
                    label: t("campaigns.create.breadcrumb"),
                  },
                ]),
          ]}
        />
        <div className="flex justify-between">
          <h1 className="font-h2-bold text-neutral-700">{header}</h1>
          {actionBar}
        </div>
        <div className="flex w-full gap-6">
          <aside className="flex shrink-0 basis-72 flex-col gap-8">
            {filtersArea}
            {priorityFiltersArea}
          </aside>
          <div className="grow overflow-x-hidden">{previewArea}</div>
        </div>
      </div>
    </main>
  )
}
