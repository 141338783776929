import { useSearchParams } from "react-router-dom"

export type SortOptions = {
  direction: "asc" | "desc"
  id: string
} | null

export function useTableSorting(defaultSortOptions: SortOptions = null) {
  const [params, setParams] = useSearchParams()

  const sortKey = params.get("sort_key")
  const sortOptions: SortOptions = sortKey
    ? {
        id: sortKey,
        direction: params.get("sort_direction") === "desc" ? "desc" : "asc",
      }
    : defaultSortOptions

  function toggleSort(key: string) {
    const nextSortDirection = toggleSortDirection(
      defaultSortOptions?.direction,
      sortOptions?.direction,
    )
    onChange(key, nextSortDirection)
  }

  function onChange(key: string, direction: "asc" | "desc" | null) {
    if (!direction) {
      resetSort()
    } else {
      updateSort(key, direction)
    }
  }

  function updateSort(key: string, direction: "asc" | "desc") {
    setParams((current) => {
      const updated = new URLSearchParams(current)

      updated.set("sort_key", key)
      updated.set("sort_direction", direction)

      return updated
    })
  }

  function resetSort() {
    setParams((current) => {
      const updated = new URLSearchParams(current)
      updated.delete("sort_key")
      updated.delete("sort_direction")

      return updated
    })
  }

  return { sortOptions, toggleSort }
}

function toggleSortDirection(
  defaultSortDirection?: "asc" | "desc" | null,
  sortState?: "asc" | "desc" | null,
) {
  if (sortState === null) return "asc"
  if (sortState === "asc") return "desc"

  if (defaultSortDirection === "desc") {
    return "asc" // special case: the default sort cannot be reset
  }

  return null
}
