import { z } from "zod"

namespace Encoder {
  export interface EncoderType {
    confirmPassword: string
    password: string
  }

  export const defaultValues: EncoderType = {
    password: "",
    confirmPassword: "",
  }
}

namespace Decoder {
  export const schema = z.object({
    password: z.string(),
    confirmPassword: z.string(),
  })

  export const emailSchema = z.string().email().min(6)
}

export { Decoder, Encoder }
