import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import CustomerProfiles from "@components/core/CustomerProfiles"

const styles = {
  wrapper: "flex flex-col gap-y-8 px-8 pt-10",
  pageTitle: "font-h1-bold",
}

const OfflineAgentView: FunctionComponent = () => {
  const { t } = useTranslation()

  // TODO: reload page when customer profiles is broken
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.pageTitle} data-sentry-unmask>
        {t("callPanel.title")}
      </h1>
      <CustomerProfiles isHidden={false} />
    </div>
  )
}

export default OfflineAgentView
