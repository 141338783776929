export const getTaskAttributes = (
  agent: connect.Agent | null,
): connect.AttributeDictionary | undefined => {
  const task = agent?.getContacts(connect.ContactType.TASK)[0]
  const attributes = task?.getAttributes()

  return attributes
}

export const findCurrentContact = ({
  agent,
  currentContactId,
}: {
  agent: connect.Agent
  currentContactId: string
}) => {
  const contacts = agent.getContacts()

  const currentContact = contacts.find(
    (contact) => contact.contactId === currentContactId,
  )

  return currentContact
}
