import { useTranslation } from "react-i18next"

import { ColumnGroupDef, DashboardTable } from "@/components/DashboardTable"
import { cn } from "@/helpers/classNames"
import { formatFullName } from "@/helpers/names"

import { PhoneNumberValidationResult } from "../campaigns-types"
import { isValidationError, ValidationResult } from "./validation-api"

type Props = {
  isLoading?: boolean
  results: ValidationResult[]
}
export function PhoneNumbersTable({ isLoading, results }: Props) {
  const { t } = useTranslation()
  const columns: ColumnGroupDef<ValidationResult>[] = [
    {
      header: "#",
      cols: [
        {
          id: "index",
          className: (item) => cn("w-16", getCellClassName(item)),
          cell: (item) => <>{item.line}</>,
        },
      ],
    },
    {
      header: "Phone Number",
      cols: [
        {
          id: "phone_number",
          className: (item) => cn("w-64", getCellClassName(item)),
          cell: (result) => (
            <div className="w-64 truncate">{result.phone_number}</div>
          ),
        },
      ],
    },
    {
      header: "Student",
      cols: [
        {
          id: "student_name",
          className: getCellClassName,
          cell: (result) =>
            result.validation_status === "SUCCESS" ? (
              <>{formatFullName(result.first_name, result.last_name)}</>
            ) : (
              <i>
                {t(
                  `campaigns.filters.selected_phone_numbers.errors.${result.validation_status}`,
                )}
              </i>
            ),
        },
      ],
    },
  ]

  return (
    <DashboardTable
      isLoading={isLoading}
      columnGroups={columns}
      items={results}
      getItemId={(item) => item.line.toString()}
    />
  )
}

function getCellClassName(result: PhoneNumberValidationResult) {
  return isValidationError(result) ? "bg-danger-100" : undefined
}
