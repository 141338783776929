import ReactDatePicker from "react-datepicker"
import { FieldValues } from "react-hook-form"
import Label from "@components/core/Label"
import PopperContainer from "@components/core/MonthPicker/PopperContainer"
import PseudoInput from "@components/core/MonthPicker/PseudoInput"
import { cn } from "@helpers/classNames"
import { isDefined } from "@helpers/typeguards"
import { CommonInputProps, FormErrorProps, Orientation } from "@typedefs/props"

import CustomHeader from "./CustomHeader"
import { useDatePickerHook } from "./hook"

import "react-datepicker/dist/react-datepicker.css"

const styles = {
  root: "flex flex-col gap-y-1 w-full",
  container: (orientation: Orientation, isDisabled: boolean) => {
    return cn([
      "flex w-full",
      orientation === "horizontal"
        ? "flex-row items-baseline gap-x-3"
        : "flex-col gap-y-1",
      isDisabled && "pointer-events-none",
    ])
  },
  timeClassName: (_date: Date) => {
    return "text-neutral-600 font-button-small"
  },
  error: "text-danger-300 font-paragraph-small-regular",
}

interface Props<FieldsType extends FieldValues>
  extends FormErrorProps<FieldsType>,
    CommonInputProps<FieldsType> {
  clearField?: VoidFunction
  isClearable?: boolean
  isRequired?: boolean
  maxDate?: Date
  minDate?: Date
  onChange: (date: Date | null) => void
  value: Date | null
  withTime?: boolean
}

const DatePicker = <FieldsType extends FieldValues>({
  className,
  clearErrors,
  clearField,
  disabled: isDisabled = false,
  errorLabel,
  errors,
  isClearable = false,
  label,
  labelClassName,
  maxDate,
  minDate,
  name,
  onBlur,
  onChange,
  orientation = "horizontal",
  required: isRequired = false,
  value,
  withTime = false,
}: Props<FieldsType>) => {
  const {
    dateFormat,
    error,
    filterTime,
    locale,
    onFocus,
    placeholder,
    timeCaption,
    timeFormat,
  } = useDatePickerHook({
    name,
    clearErrors,
    withTime,
    errorLabel,
    label,
    errors,
    minDate,
    maxDate,
  })

  return (
    <div
      className={cn([styles.root, className])}
      id="datetimepicker"
      data-sentry-unmask
    >
      <div className={styles.container(orientation, isDisabled)}>
        {isDefined(label) && (
          <Label
            label={label}
            labelClassName={labelClassName}
            required={isRequired}
            isDisabled={isDisabled}
          />
        )}
        <ReactDatePicker
          showTimeSelect={withTime}
          placeholderText={placeholder}
          locale={locale}
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          onChange={onChange}
          onCalendarClose={() => {
            onBlur && onBlur()
          }}
          selected={value}
          minDate={minDate}
          maxDate={maxDate}
          filterTime={withTime ? filterTime : undefined}
          disabled={isDisabled}
          customInput={
            <PseudoInput
              value={value}
              isDisabled={isDisabled}
              hasError={isDefined(error)}
              onControllerBlur={onBlur}
              onControllerFocus={onFocus}
              isClearable={isClearable}
              clearField={clearField}
            />
          }
          calendarContainer={PopperContainer}
          renderCustomHeader={CustomHeader}
          timeCaption={timeCaption}
          timeClassName={styles.timeClassName}
        />
      </div>
      {isDefined(error) && (
        <div className={styles.error} data-sentry-unmask>
          {error}
        </div>
      )}
    </div>
  )
}

export default DatePicker
