import { formatStringValue as formatValue } from "@helpers/valueFormat"
import { useFormValidation } from "@hooks/formValidation"
import * as connectPanelValue from "@pages/CustomConnectPanel/value"

interface ContactProfileHookInput {
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  customerProfile: connectPanelValue.Decoder.CustomerProfile | null
  historyItem: connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null
}

interface ContactProfileHookType {
  contactName: string
  contactNameKana: string
  historyEntries: connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType[]
}

const useContactProfileHook = ({
  currentCase,
  customerProfile,
  historyItem,
}: ContactProfileHookInput): ContactProfileHookType => {
  const { isKanji } = useFormValidation()

  const getNames = (): {
    contactName: string
    contactNameKana: string
  } => {
    const isJapaneseName = isKanji(
      customerProfile?.LastName || customerProfile?.FirstName || "",
    )

    if (!isJapaneseName) {
      const contactName = `${customerProfile?.FirstName ?? ""}${
        customerProfile?.MiddleName
          ? " " + customerProfile?.MiddleName + " "
          : " "
      }${customerProfile?.LastName ?? ""}`

      const contactNameKana = `${
        customerProfile?.Attributes?.FirstNameKana ?? ""
      } ${customerProfile?.Attributes?.LastNameKana ?? ""}`

      return {
        contactName: formatValue(contactName),
        contactNameKana: formatValue(contactNameKana),
      }
    }

    const contactName = `${customerProfile?.LastName ?? ""}${
      customerProfile?.FirstName ?? ""
    }`

    const contactNameKana = `${
      customerProfile?.Attributes?.LastNameKana ?? ""
    } ${customerProfile?.Attributes?.FirstNameKana ?? ""}`

    return {
      contactName: formatValue(contactName),
      contactNameKana: formatValue(contactNameKana),
    }
  }

  const caseHistoryEntries = currentCase?.activity_feed ?? []
  const historyEntries = historyItem
    ? [historyItem, ...caseHistoryEntries]
    : caseHistoryEntries

  return {
    ...getNames(),
    historyEntries,
  }
}

export { useContactProfileHook }
