import { caseIdSchema, datetimeSchema, idSchema } from "@helpers/zodSchemas"
import { z } from "zod"

namespace Encoder {
  export const defaultValues = (rawCallReport: unknown) => {
    try {
      return Decoder.schema.parse(rawCallReport)
    } catch (error) {
      return { status: "", reason: "", scheduledCallDate: null, memo: "" }
    }
  }
}

namespace Decoder {
  export const schema = z.object({
    status: z.string(),
    reason: z.string(),
    scheduledCallDate: datetimeSchema.nullable(),
    memo: z.string(),
  })

  export type DecoderType = z.infer<typeof schema>

  export const saveReportSchema = z.object({
    data: z.object({
      case_id: caseIdSchema,
      is_campaign_no_call: z.boolean(),
      is_global_no_call: z.boolean().nullable(),
      new_contact_id: idSchema.nullable(),
    }),
    reqId: idSchema,
    success: z.string(),
  })

  export type SaveReportResult = z.infer<typeof saveReportSchema>
}

export { Decoder, Encoder }
