import { connectErrorHandler } from "@helpers/error"
import { isNull } from "@helpers/typeguards"
import { logMultipleTasks } from "@hooks/connectActions"

const isTask = (contact: connect.Contact): boolean => {
  const contactType = contact.getType()

  return contactType === connect.ContactType.TASK
}

const isInboundCall = (contact: connect.Contact): boolean => {
  const contactType = contact.getType()

  if (contactType !== connect.ContactType.VOICE) {
    return false
  }

  try {
    return contact.isInbound()
  } catch (err) {
    // If called in the onDestroyed event handler, the contact is merely a snapshot of the contact
    // so isInbound() method will not work
    // @ts-expect-error Contact can be a snapshot
    const isInbound = contact?.contactData?.initiationMethod === "inbound"

    return isInbound
  }
}

const autoAcceptTask = (
  agent: connect.Agent | null,
): Promise<connect.Contact> => {
  if (isNull(agent)) {
    throw new Error("Agent is not found")
  }

  const contacts = agent.getContacts(connect.ContactType.TASK)

  if (!contacts.length) {
    throw new Error("No incoming task found")
  }

  if (contacts.length > 1) {
    logMultipleTasks(agent, contacts)
  }

  const task = contacts[0]

  return new Promise((resolve, reject) => {
    task.accept({
      success: () => resolve(task),
      failure: (err) => {
        reject(connectErrorHandler(err, "Failure to accept task"))
      },
    })
  })
}

// When ending outbound calls, set the task as the contact.
// Ending inbound calls or tasks should set null
// For the edge case where we end the task while the call is connected return the voice contact
const getNextContactId = ({
  agent,
  destroyedContactId,
}: {
  agent: connect.Agent | null
  destroyedContactId: string
}): string | null => {
  const contacts = agent?.getContacts()

  const nextContact = contacts?.find(
    (contact) => contact.getContactId() !== destroyedContactId,
  )

  const nextContactId = nextContact?.getContactId() ?? null

  return nextContactId
}

export { autoAcceptTask, getNextContactId, isInboundCall, isTask }
