import { useState } from "react"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useLogger } from "@hooks/useLogger"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useUpdateFilters } from "../api/campaigns"
import { CampaignDetails, FilterSelections } from "../campaigns-types"

type EditableCampaignData = {
  filters: FilterSelections
  priorityFilters: FilterSelections | null
}

export function useCreateCampaignState(campaign_id: string | null) {
  return useFilters({
    campaign_id,
    initialFilters: {},
    initialPriorityFilters: null,
  })
}

export function useEditCampaignState(campaign: CampaignDetails) {
  const { campaign_filter_selection, campaign_id, priority_filter_selection } =
    campaign

  return useFilters({
    campaign_id,
    initialFilters: campaign_filter_selection,
    initialPriorityFilters: priority_filter_selection,
  })
}

function useFilters({
  campaign_id,
  initialFilters,
  initialPriorityFilters,
}: {
  campaign_id: string | null
  initialFilters?: FilterSelections
  initialPriorityFilters?: FilterSelections | null
}) {
  // the filters being modified by the user (the "draft" state, until the user saves the changes)
  const [filters, setFilters] = useState<FilterSelections>(initialFilters || {})
  const [priorityFilters, setPriorityFilters] = useState<FilterSelections>(
    initialPriorityFilters || {},
  )

  const { addFilter, isAddedFilter, removeFilter, resetAddedFilters } =
    useVisibleFilters()
  const {
    addFilter: addPriorityFilter,
    isAddedFilter: isAddedPriorityFilter,
    removeFilter: removePriorityFilter,
    resetAddedFilters: resetAddedPriorityFilters,
  } = useVisibleFilters()

  // Track the filters saved to the database to show the Save button only when the user changes something
  const [savedFilters, setSavedFilters] = useState<EditableCampaignData>({
    filters: initialFilters || {},
    priorityFilters: initialPriorityFilters || {},
  })

  const isChanged = !areSameFilters({ filters, priorityFilters }, savedFilters)

  const { mutation, save } = useSaveFilters(
    campaign_id,
    filters,
    priorityFilters,
  )

  async function handleSave() {
    if (await save()) {
      setSavedFilters({ filters, priorityFilters })
    }
  }

  function handleCancel() {
    resetAddedFilters()
    resetAddedPriorityFilters()
    setFilters(initialFilters || {})
    setPriorityFilters(initialPriorityFilters || {})
  }

  return {
    // data and setters
    filters,
    setFilters,
    priorityFilters,
    setPriorityFilters,
    // actions
    isChanged,
    handleSave,
    handleCancel,
    mutation,
    // visibility state
    addFilter,
    removeFilter,
    isAddedFilter,
    addPriorityFilter,
    removePriorityFilter,
    isAddedPriorityFilter,
  }
}

function useSaveFilters(
  campaign_id: string | null,
  filters: FilterSelections,
  priorityFilters: FilterSelections,
) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const log = useLogger()

  const updateFilters = useUpdateFilters()

  const mutation = useMutation({
    mutationFn: async () => {
      if (!campaign_id) throw new Error("No campaign ID") //TODO refactor
      await updateFilters(campaign_id, filters, priorityFilters)
    },
  })

  async function save() {
    try {
      await mutation.mutateAsync()
      toast.success(t("campaigns.filters.success"))
      queryClient.invalidateQueries({ queryKey: ["campaigns"] })
      navigate(`/campaigns`)
    } catch (error) {
      toast.error("Unable to save filters")
      log.error(error)

      return false
    }
  }

  return { save, mutation }
}

/**
 * Handle the visibility of filters added by the user, before any value is set.
 * Basically an array of filter keys that show up in the UI, even if no value is set.
 */
function useVisibleFilters() {
  const [addedFilters, setAddedFilters] = useState<
    Array<keyof FilterSelections>
  >([] as Array<keyof FilterSelections>)

  function addFilter(filterKey: keyof FilterSelections) {
    return setAddedFilters((current) => [...current, filterKey])
  }

  function removeFilter(filterKey: keyof FilterSelections) {
    return setAddedFilters((current) =>
      current.filter((key) => key !== filterKey),
    )
  }

  function isAddedFilter(filterKey: keyof FilterSelections) {
    return addedFilters.includes(filterKey)
  }

  function resetAddedFilters() {
    return setAddedFilters([])
  }

  return { addFilter, removeFilter, isAddedFilter, resetAddedFilters }
}

function areSameFilters(a: EditableCampaignData, b: EditableCampaignData) {
  return JSON.stringify(a) === JSON.stringify(b)
}
