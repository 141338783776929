import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"

const useToast = () => {
  const { t } = useTranslation()

  const error = (id: string) => {
    toast.remove()
    toast.error(t(id))
  }

  const success = (id: string) => {
    toast.remove()
    toast.success(t(id))
  }

  return {
    error,
    success,
  }
}

export { useToast }
