import { addHours, addMinutes, addSeconds } from "date-fns"

import { getTimestamp } from "@/helpers/dateFormat"
import { hasValue } from "@/helpers/typeguards"
import { CallDurationData, callDurationDataSchema } from "@/helpers/zodSchemas"
import { Logger } from "@/hooks/useLogger"

export type Timer = {
  hours: number
  minutes: number
  seconds: number
}

/**
 * Function to parse the call duration stored in local storage.
 * Will log some errors in case the call duration stored in local storage does not match the current contact by contact id.
 * @param callDurationData - call duration data stored in local storage
 * @param contactIdForCallDuration - contact id for the current call (either task id or voice call id for inbound calls)
 */
export function getInitialTimer({
  callDurationData,
  callDurationKey,
  contactIdForCallDuration,
}: {
  callDurationData: CallDurationData | null
  callDurationKey: string
  contactIdForCallDuration?: string
  log: Logger
}): Timer {
  const validCallDuration = callDurationDataSchema.safeParse(callDurationData)

  if (!validCallDuration.success) {
    return { hours: 0, minutes: 0, seconds: 0 }
  }

  const isMismatchedIds =
    hasValue(contactIdForCallDuration) &&
    validCallDuration.data.contact_id !== contactIdForCallDuration

  if (isMismatchedIds) {
    // TODO: restore this as Sentry warning when we have resolved: POC-5577,POC-5424 & POC-5675
    // eslint-disable-next-line no-console
    console.warn(
      "Contact ID in local storage does not match the current contact ID in agent object.",
      {
        contactIdForCallDuration,
        [callDurationKey]: callDurationData,
      },
    )

    return { hours: 0, minutes: 0, seconds: 0 }
  }

  const startTimestamp = new Date(validCallDuration.data.call_start_timestamp)
  const endTimestamp = validCallDuration.data.call_end_timestamp
    ? new Date(validCallDuration.data.call_end_timestamp)
    : new Date()

  return getTimestamp(startTimestamp, endTimestamp)
}

export function calculateEndTimestamp(
  initialCallDurationData: CallDurationData,
  timer: Timer,
): Date {
  if (hasValue(initialCallDurationData.call_end_timestamp)) {
    return new Date(initialCallDurationData.call_end_timestamp)
  }

  let newEndTimestamp = new Date(initialCallDurationData.call_start_timestamp)

  if (timer.hours) {
    newEndTimestamp = addHours(newEndTimestamp, timer.hours)
  }

  if (timer.minutes) {
    newEndTimestamp = addMinutes(newEndTimestamp, timer.minutes)
  }

  if (timer.seconds) {
    newEndTimestamp = addSeconds(newEndTimestamp, timer.seconds)
  }

  return newEndTimestamp
}
