import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import i18n from "i18next"
import { CheckIcon, LogOutIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { cn } from "@/helpers/classNames"
import { useAuthHook } from "@/hooks/auth"
import { useManualCallAtom } from "@/hooks/useManualCallAtom"

export function UserDropdownMenu({ children }: PropsWithChildren) {
  const { t } = useTranslation()
  const { logout } = useAuthHook()
  const { shouldClearAllStateInLocalStorage } = useManualCallAtom()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          className="hover:bg-transparent h-[50px] rounded-md py-0"
        >
          {children}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t("header.language")}</DropdownMenuLabel>
        <LanguageMenuItem language="en">English</LanguageMenuItem>
        <LanguageMenuItem language="ja">日本語</LanguageMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => logout(shouldClearAllStateInLocalStorage)}
        >
          <LogOutIcon className="mr-2 size-4" />
          {t("header.logout")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

function LanguageMenuItem({
  children,
  language,
}: React.PropsWithChildren<{ language: string }>) {
  const isCurrent = i18n.resolvedLanguage === language

  return (
    <DropdownMenuItem
      onClick={() => i18n.changeLanguage(language)}
      disabled={isCurrent}
    >
      <CheckIcon className={cn("mr-2 size-4", !isCurrent && "opacity-0")} />
      {children}
    </DropdownMenuItem>
  )
}
