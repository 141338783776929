import { MutableRefObject, useRef } from "react"
import { useLifecycles } from "react-use"
import { config } from "@config/index"
import {
  mount as mountCustomerProfilesIframe,
  unmount as unmountCustomerProfilesIframe,
} from "@helpers/ccp"

interface CustomerProfilesHookType {
  appContainer: string
  ref: MutableRefObject<HTMLDivElement | null>
}

const useCustomerProfilesHook = (): CustomerProfilesHookType => {
  const ref = useRef<HTMLDivElement | null>(null)

  const appName = "customerprofiles"
  const appContainer = `${appName}-container`
  const appUrl = `https://${config.connectInstanceAlias}.my.connect.aws/connect/customerprofiles-v2/`

  // UseEffect with cleanup function
  useLifecycles(
    () => mountCustomerProfilesIframe({ appName, appContainer, appUrl }),
    () => unmountCustomerProfilesIframe(ref),
  )

  return { appContainer, ref }
}

export { useCustomerProfilesHook }
