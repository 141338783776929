import { FunctionComponent } from "react"
import { CalendarContainer } from "react-datepicker"
import { ChildrenProps } from "@typedefs/props"

interface Props extends ChildrenProps {
  className?: string
}

/* Providing a custom container seems to disable the triangle on the calendar. Could also be done with this piece of styling
.react-datepicker__triangle {
  @apply hidden;
} 
*/

const PopperContainer: FunctionComponent<Props> = ({ children, className }) => {
  return <CalendarContainer className={className}>{children}</CalendarContainer>
}

export default PopperContainer
