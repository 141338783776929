import { useTranslation } from "react-i18next"

import { AlertLoadingError } from "@/components/ui/alert"
import { MultiCombobox } from "@/components/ui/multi-combobox"
import { Skeleton } from "@/components/ui/skeleton"
import { unique } from "@/helpers/other"

import { useFetchAllFilterOptions } from "../api/filter-options"
import { FilterSelections } from "../campaigns-types"

type Props = {
  filterKey: keyof FilterSelections
  onChange: (value: string[]) => void
  propertyName: "department_groups" | "genders" | "majors"
  values: string[]
}

export function GenericFilterPicker({
  filterKey,
  onChange,
  propertyName,
  values,
}: Props) {
  const { data, error, isLoading } = useFetchAllFilterOptions()

  if (isLoading) {
    return <LoadingFilter />
  }

  if (error) {
    return (
      <AlertLoadingError error={error} title="Unable to load filter options" />
    )
  }

  const items = (data?.data?.[propertyName] || []) as string[]
  const allItems = unique([...items, ...values])

  const options = allItems.map((item) => ({
    value: item,
    label: item,
  }))

  return (
    <MultiCombobox<string>
      options={options}
      values={values}
      onChange={onChange}
      size="campaign-filter"
      buttonProps={{ size: "sm" }}
      renderSelectedOptions={(selectedOptions) => {
        const labels = selectedOptions.map((option) => option.label)

        return <FilterControlLabel filterKey={filterKey} labels={labels} />
      }}
    />
  )
}

export function LoadingFilter() {
  return (
    <div className="flex h-[40px] w-campaign-filter items-center rounded-md border bg-neutral-100 px-3">
      <Skeleton className="h-4 w-full" />
    </div>
  )
}

export function FilterControlLabel({
  filterKey,
  labels,
}: {
  filterKey: keyof FilterSelections
  labels: string[]
}) {
  const { t } = useTranslation()
  const propertyLabel = t(`campaigns.filters.${filterKey}.label`)

  if (labels.length === 0) return <>{propertyLabel}</>
  if (labels.length === 1) return <div className="truncate">{labels[0]}</div>

  return (
    <>
      {t(`campaigns.filters.shortSummary`, {
        count: labels.length,
        label: propertyLabel,
      })}
    </>
  )
}
