import { useState } from "react"
import { useTranslation } from "react-i18next"
import { PlusIcon, X } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

import { FilterSelectionUniversity } from "../campaigns-types"
import { UniversityGroupPicker } from "./university-group-picker"
import { UniversityPicker } from "./university-picker"

type Props = {
  filters: FilterSelectionUniversity[]
  restrictionFilters?: FilterSelectionUniversity[]
  setFilters: (values: FilterSelectionUniversity[]) => void
}

const defaultFilter: FilterSelectionUniversity = {
  type: "university",
  operator: "include",
  values: [],
}

export function UniversityFilterEditor({
  filters,
  restrictionFilters,
  setFilters,
}: Props) {
  const { t } = useTranslation()
  const [showExtra, setShowExtra] = useState(false)
  const showExtraItem = showExtra || filters.length === 0

  const isFormValid = filters.every((filter) => filter.values.length > 0)
  const showAddRow = !showExtraItem && isFormValid

  function addFilter(filter: Partial<FilterSelectionUniversity>) {
    setFilters([...filters, { ...defaultFilter, ...filter }])
    setShowExtra(false)
  }

  function addNewRow() {
    setShowExtra(true)
  }

  const editFilter =
    (index: number) => (changes: Partial<FilterSelectionUniversity>) => {
      const updated = filters.slice()
      updated[index] = { ...updated[index], ...changes }
      setFilters(updated)
    }

  const removeFilter = (index: number) => () => {
    setFilters(filters.filter((_, i) => i !== index))
  }

  return (
    <div className="flex flex-col">
      {filters.map((filter, index) => {
        return (
          <FilterItem
            key={index}
            filter={filter}
            onChange={editFilter(index)}
            onRemove={removeFilter(index)}
            restrictionFilters={restrictionFilters}
          />
        )
      })}
      {showExtraItem && (
        <FilterItem
          filter={defaultFilter}
          onChange={addFilter}
          restrictionFilters={restrictionFilters}
        />
      )}
      <div className="pt-4">
        <Button variant="outline" onClick={addNewRow} disabled={!showAddRow}>
          <PlusIcon />
          {t("global.button.add")}
        </Button>
      </div>
    </div>
  )
}

function FilterItem({
  filter,
  onChange,
  onRemove,
  restrictionFilters,
}: {
  filter: FilterSelectionUniversity
  onChange: (changes: Partial<FilterSelectionUniversity>) => void
  onRemove?: () => void
  restrictionFilters?: FilterSelectionUniversity[]
}) {
  return (
    <div className="flex gap-4 border-b py-4">
      <FilterTypePicker
        value={filter.type}
        onChange={(type) => onChange({ type })}
      />
      <FilterOperatorPicker
        value={filter.operator}
        onChange={(operator) => onChange({ operator })}
      />
      <div className="grow">
        {filter.type === "group" ? (
          <UniversityGroupPicker
            filters={restrictionFilters}
            values={filter.values}
            onChange={(values) => onChange({ values })}
          />
        ) : (
          <UniversityPicker
            filters={restrictionFilters}
            values={filter.values}
            onChange={(values) => onChange({ values })}
          />
        )}
      </div>
      {onRemove && (
        <div>
          <Button
            variant="outline"
            onClick={onRemove}
            className="rounded-full"
            size="icon"
          >
            <X className="size-5" />
          </Button>
        </div>
      )}
    </div>
  )
}

function FilterTypePicker({
  onChange,
  value,
}: {
  onChange: (value: FilterSelectionUniversity["type"]) => void
  value: FilterSelectionUniversity["type"]
}) {
  const { t } = useTranslation()

  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger className="w-[200px]">
        <SelectValue placeholder="Select" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="university">
          {t("campaigns.filters.university.list")}
        </SelectItem>
        <SelectItem value="group">
          {t("campaigns.filters.university.group")}
        </SelectItem>
      </SelectContent>
    </Select>
  )
}

function FilterOperatorPicker({
  onChange,
  value,
}: {
  onChange: (value: FilterSelectionUniversity["operator"]) => void
  value: FilterSelectionUniversity["operator"]
}) {
  const { t } = useTranslation()

  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger className="w-[120px]">
        <SelectValue placeholder="Select" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="include">
          {t("campaigns.filters.university.include")}
        </SelectItem>
        <SelectItem value="exclude">
          {t("campaigns.filters.university.exclude")}
        </SelectItem>
      </SelectContent>
    </Select>
  )
}
