import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { AlertLoadingError } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"

import { useFetchCampaign } from "../api/campaigns"
import { CampaignDetails } from "../campaigns-types"
import { CreateEditPageLayout } from "../shared/create-edit-page-layout"
import { useEditCampaignState } from "./edit-filters-hooks"
import { FiltersPanel, LoadingFiltersPanel } from "./filters-panel"
import { LoadingViewStudentsPreview, PreviewPanel } from "./preview-panel"
import { PriorityFiltersPanel } from "./priority-filters-panel"

export function EditCampaignPage() {
  const { campaign_id } = useParams()
  if (!campaign_id) throw new Error("Missing campaign_id")
  const campaignQueryState = useFetchCampaign(campaign_id)
  const { data, error, isError, isLoading } = campaignQueryState

  if (isLoading) {
    return (
      <CreateEditPageLayout
        campaign_id={campaign_id}
        actionBar={<Button className="invisible" />}
        filtersArea={<LoadingFiltersPanel />}
        header={<>{campaign_id}</>}
        previewArea={<LoadingViewStudentsPreview />}
      />
    )
  }

  if (isError) {
    return (
      <CreateEditPageLayout
        campaign_id={campaign_id}
        header={<>{campaign_id}</>}
        previewArea={
          <AlertLoadingError
            error={error}
            title="Unable to load the campaign filters"
          />
        }
      />
    )
  }

  const campaign = data?.data
  if (!campaign) return null

  return <ViewCampaignFilters campaign={campaign} />
}

function ActionBar({
  isSaving,
  onCancel,
  onSave,
}: {
  isSaving?: boolean
  onCancel?: () => void
  onSave?: () => void
}) {
  const { t } = useTranslation()

  return (
    <div className="flex gap-4">
      <Button
        variant="outline"
        isDisabled={!onCancel || isSaving}
        onClick={onCancel}
      >
        {t("global.button.cancel")}
      </Button>
      <Button
        variant="default"
        isDisabled={!onSave || isSaving}
        onClick={onSave}
        isLoading={isSaving}
      >
        {t("global.button.save")}
      </Button>
    </div>
  )
}

function ViewCampaignFilters({ campaign }: { campaign: CampaignDetails }) {
  const {
    addFilter,
    addPriorityFilter,
    filters,
    handleCancel,
    handleSave,
    isAddedFilter,
    isAddedPriorityFilter,
    isChanged,
    mutation,
    priorityFilters,
    removeFilter,
    removePriorityFilter,
    setFilters,
    setPriorityFilters,
  } = useEditCampaignState(campaign)

  return (
    <CreateEditPageLayout
      campaign_id={campaign.campaign_id}
      actionBar={
        <ActionBar
          isSaving={mutation.isPending}
          onSave={isChanged ? handleSave : undefined}
          onCancel={isChanged ? handleCancel : undefined}
        />
      }
      header={<>{campaign.campaign_id}</>}
      filtersArea={
        <FiltersPanel
          campaign_id={campaign.campaign_id}
          filters={filters}
          setFilters={setFilters}
          addFilter={addFilter}
          removeFilter={removeFilter}
          isAddedFilter={isAddedFilter}
        />
      }
      priorityFiltersArea={
        <PriorityFiltersPanel
          campaign_id={campaign.campaign_id}
          filters={filters}
          priorityFilters={priorityFilters}
          setPriorityFilters={setPriorityFilters}
          addFilter={addPriorityFilter}
          removeFilter={removePriorityFilter}
          isAddedFilter={isAddedPriorityFilter}
        />
      }
      previewArea={
        <PreviewPanel filters={filters} priorityFilters={priorityFilters} />
      }
    />
  )
}
