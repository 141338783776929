import { FormEventHandler, useState } from "react"
import { SubmitHandler, useForm, UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useAuthHook } from "@hooks/auth"
import { useLogger } from "@hooks/useLogger"

import { agentStorageService } from "@/services/localStorageService"

import * as value from "./value"

type InitMode = {
  current: "init"
  state?: { prevErrorMessage: string }
}
type LoadingMode = { current: "loading" }
type SuccessMode = { current: "success" }
type ErrorMode = {
  current: "error"
  state: {
    errorMessage: string
    errorType: "recoverable" | "unrecoverable"
  }
}
type Mode = ErrorMode | InitMode | LoadingMode | SuccessMode

interface UseResetPasswordHookType {
  enableSubmit: () => Promise<void>
  form: UseFormReturn<value.Encoder.EncoderType>
  isDisabled: boolean
  isLoading: boolean
  mode: Mode
  onSubmit: FormEventHandler<HTMLFormElement>
}

const knownErrors = ["LimitExceededException", "UserNotFoundException"]

const useResetPasswordHook = (): UseResetPasswordHookType => {
  const [mode, setMode] = useState<Mode>({ current: "init" })
  const { t } = useTranslation()
  const { requestPasswordReset } = useAuthHook()
  const log = useLogger()

  const form = useForm<value.Encoder.EncoderType>({
    defaultValues: value.Encoder.defaultValues,
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldFocusError: false,
  })

  const { handleSubmit } = form

  const isEnabled =
    mode.current === "init" ||
    (mode.current === "error" && mode.state.errorType === "recoverable")

  const onSubmit: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
    try {
      setMode(() => ({ current: "loading" }))
      const { email: validEmail } = await value.Decoder.schema.parseAsync(data)

      await requestPasswordReset(validEmail)

      // Pass the email to the reset password confirmation page
      agentStorageService.setResetPasswordEmail(validEmail)

      setMode(() => ({ current: "success" }))
    } catch (err) {
      if (err instanceof Error) {
        const isKnownError = knownErrors.includes(err.name)
        const errorType =
          err.name === "LimitExceededException"
            ? "unrecoverable"
            : "recoverable"

        const errorMessage = isKnownError
          ? t(`errors.resetPassword.${err.name}`)
          : t("errors.resetPassword.unknownError")

        setMode(() => ({
          current: "error",
          state: { errorMessage, errorType },
        }))

        if (!isKnownError) {
          log.error(err)
        }
      } else {
        setMode(() => ({
          current: "error",
          state: {
            errorMessage: t("errors.resetPassword.unknownError"),
            errorType: "recoverable",
          },
        }))

        log.error(err)
      }

      agentStorageService.removeResetPasswordEmail()
    }
  }

  const enableSubmit = async () => {
    setMode(() => ({ current: "init" }))
  }

  return {
    onSubmit: handleSubmit(onSubmit),
    form,
    mode,
    enableSubmit,
    isDisabled: !isEnabled,
    isLoading: mode.current === "loading",
  }
}

export { type Mode, useResetPasswordHook }
