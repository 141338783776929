import { useState } from "react"
import { useTranslation } from "react-i18next"
import { PlusIcon, X } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader } from "@/components/ui/card"

import { FilterSelectionChannel } from "../campaigns-types"
import { ChannelPicker } from "./channel-picker"
import { ChannelsEditorQA } from "./channels-editor-qa"
import { useChannelsState } from "./channels-state"

type Props = {
  availableChannelIds?: number[]
  channelsState: ReturnType<typeof useChannelsState>
}

export function ChannelsEditor({ availableChannelIds, channelsState }: Props) {
  const { t } = useTranslation()
  const [showExtra, setShowExtra] = useState(false)

  const showExtraChannel = showExtra || channelsState.channels.length === 0

  function addEmptyChannel() {
    setShowExtra(true)
  }

  function addChannel(channel_id: number) {
    channelsState.addChannel(channel_id)
    setShowExtra(false)
  }

  return (
    <div className="flex w-full items-center gap-4">
      <div className="flex w-full flex-col gap-6">
        {channelsState.channels.map(
          (channel: FilterSelectionChannel, index) => (
            <Card key={channel.channel_id}>
              <CardHeader className="border-b bg-neutral-200 p-2">
                <div className="flex justify-between">
                  <div>
                    <ChannelPicker
                      availableChannelIds={availableChannelIds}
                      value={channel.channel_id}
                      onChange={channelsState.setChannelId(index)}
                    />
                  </div>
                  <div>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={channelsState.removeChannel(index)}
                      className=""
                    >
                      {t("campaigns.filters.channels.remove")}
                      <X />
                    </Button>
                  </div>
                </div>
              </CardHeader>
              {channel.channel_id !== null && (
                <CardContent className="p-2">
                  {channel.channel_id !== null && (
                    <div>
                      <ChannelsEditorQA
                        channel_id={channel.channel_id}
                        questionAnswers={channel.questionsAnswers}
                        onChangeQuestionAnswers={channelsState.onChangeQuestionAnswers(
                          index,
                        )}
                      />
                    </div>
                  )}
                </CardContent>
              )}
            </Card>
          ),
        )}
        {showExtraChannel ? (
          <Card>
            <CardHeader className="border-b bg-neutral-200 p-2">
              <ChannelPicker
                availableChannelIds={availableChannelIds}
                value={null}
                onChange={addChannel}
              />
            </CardHeader>
          </Card>
        ) : (
          <div className="">
            <Button
              variant="outline"
              size="default"
              onClick={addEmptyChannel}
              className=""
              type="button"
            >
              <PlusIcon />
              {t("campaigns.filters.channels.add")}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
