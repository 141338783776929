import { FunctionComponent } from "react"
import SlowConnectionLoader from "@components/SlowConnectionLoader"
import Spinner from "@components/Spinner"

const styles = {
  wrapper: "w-full h-full",
}

const Loader: FunctionComponent<{ isSlow?: boolean }> = ({ isSlow }) => {
  return (
    <div className={styles.wrapper} data-sentry-unmask>
      {isSlow ? <SlowConnectionLoader /> : <Spinner />}
    </div>
  )
}

export default Loader
