import { useTranslation } from "react-i18next"

import { AlertLoadingError } from "@/components/ui/alert"
import { MultiCombobox } from "@/components/ui/multi-combobox"
import { unique } from "@/helpers/other"

import { useFetchAllFilterOptions } from "../api/filter-options"
import { FilterSelectionUniversity } from "../campaigns-types"
import { LoadingFilter } from "../pickers/generic-filter-picker"

type Props = {
  allowReset?: boolean
  filters?: FilterSelectionUniversity[]
  onChange: (value: string[]) => void
  placeholder?: string
  values: string[]
}

export function UniversityPicker({
  allowReset,
  filters,
  onChange,
  placeholder,
  values,
}: Props) {
  const { t } = useTranslation()
  const { data, error, isLoading } = useFetchAllFilterOptions()

  if (isLoading) {
    return <LoadingFilter />
  }

  if (error) {
    return (
      <AlertLoadingError error={error} title="Unable to load filter options" />
    )
  }

  const groups = data?.data?.grouped_universities || []

  const allUniversities = unique(
    groups.flatMap(({ group_name, universities }) =>
      universities.filter((university) =>
        isUniversityIncludedPredicate(university, group_name, filters),
      ),
    ),
  )

  const options = allUniversities.map((university) => ({
    value: university,
    label: university,
  }))

  return (
    <MultiCombobox<string>
      allowReset={allowReset}
      options={options}
      values={values}
      onChange={onChange}
      size="lg"
      placeholder={placeholder || t("campaigns.filters.noFilterSelected")}
    />
  )
}

/**
 * Used to filter the list of university from the API given an existing filter,
 * to take into account the query filter in the context of the priority filter
 */
function isUniversityIncludedPredicate(
  university: string,
  group_name: string,
  filters?: FilterSelectionUniversity[],
) {
  if (!filters || filters.length === 0) {
    return true
  }
  const isGroupIncluded = filters
    .filter(({ operator, type }) => type === "group" && operator === "include")
    .some(({ values }) => values.includes(group_name))

  const isUniversityIncluded = filters
    .filter(
      ({ operator, type }) => type === "university" && operator === "include",
    )
    .some(({ values }) => values.includes(university))

  const isUniversityExcluded = filters
    .filter(
      ({ operator, type }) => type === "university" && operator === "exclude",
    )
    .some(({ values }) => values.includes(university))

  if (isGroupIncluded) {
    return !isUniversityExcluded
  }

  return isUniversityIncluded
}
