import CallView from "@components/CallView"
import EmptyQueueView from "@components/EmptyQueueView"
import Loader from "@components/layout/Loader"
import OfflineAgentView from "@components/OfflineAgentView"
import PreparingCallView from "@components/PreparingCallView"
import ResetCallView from "@components/ResetCallView"

import { CallDurationProvider } from "@/hooks/callDuration"

import { useCustomPanelHook } from "./hook"

const styles = {
  container: "w-full h-full",
  actions: "flex flex-row flex-wrap gap-x-4 gap-y-4 p-4",
  appsContainer: "flex flex-row space-x-4 p-4",
}

const CustomConnectPanel = () => {
  const { displayMode: agentDisplayMode, errorMessage } = useCustomPanelHook()

  if (errorMessage) {
    throw new Error(errorMessage)
  }

  switch (agentDisplayMode.current) {
    case "offline":
      return (
        <div className={styles.container}>
          <OfflineAgentView />
        </div>
      )
    case "initializing":
      return <Loader />
    case "available":
      return (
        <div className={styles.container}>
          <EmptyQueueView />
        </div>
      )

    case "busy":
      return (
        <div className={styles.container}>
          {agentDisplayMode.state.isLoading ? (
            <PreparingCallView />
          ) : (
            <CallDurationProvider>
              <CallView
                customerProfile={agentDisplayMode.state.customerProfile}
                currentCase={agentDisplayMode.state.currentCase}
                caseStatuses={agentDisplayMode.state.caseStatuses}
              />
            </CallDurationProvider>
          )}
        </div>
      )

    case "reset":
      return <ResetCallView currentCase={agentDisplayMode.state.currentCase} />

    default:
      return null
  }
}

export default CustomConnectPanel
