import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { LoaderCircle, PlusIcon, RefreshCwIcon } from "lucide-react"

import { AlertLoadingError } from "@/components/ui/alert"
import { Button, buttonVariants } from "@/components/ui/button"
import { usePermissions } from "@/hooks/agent-profile"
import { useQueryAPI } from "@/hooks/query-api"

import { CampaignListResponseSchema } from "../campaigns-types"
import { CampaignsTable, LoadingCampaignsTable } from "./campaigns-table"

export function CampaignsListPage() {
  const { canEditCampaigns } = usePermissions()

  const campaignListQuery = useFetchCampaigns()
  const { error, isError, isLoading } = campaignListQuery

  if (isError) {
    return (
      <PageLayout
        content={
          <AlertLoadingError
            error={error}
            title="Unable to load campaign list"
          />
        }
      />
    )
  }

  if (isLoading) {
    return (
      <PageLayout
        content={<LoadingCampaignsTable hasWriteAccess={canEditCampaigns} />}
      />
    )
  }

  return (
    <ViewCampaigns
      campaignListQuery={campaignListQuery}
      canEditCampaigns={canEditCampaigns}
    />
  )
}

function PageLayout({
  actions,
  content,
}: {
  actions?: React.ReactNode
  content: React.ReactNode
}) {
  const { t } = useTranslation()

  return (
    <main className="flex h-full flex-col gap-y-4 px-8 pt-10">
      <div className="container flex flex-col gap-4">
        <div className="flex items-center justify-between" data-sentry-unmask>
          <h1 className="font-h2-bold text-neutral-700">
            {t("campaigns.list.title")}
          </h1>
          <div className="flex items-center gap-4">{actions}</div>
        </div>
        {content}
      </div>
    </main>
  )
}

function ViewCampaigns({
  campaignListQuery,
  canEditCampaigns,
}: {
  campaignListQuery: ReturnType<typeof useFetchCampaigns>
  canEditCampaigns: boolean
}) {
  const { t } = useTranslation()
  const { data, isFetching, refetch } = campaignListQuery

  const campaigns = data?.data.campaigns || []
  const metricsMeta = data?.data.metrics_metadata

  const handleRefresh = () => {
    refetch()
  }

  return (
    <PageLayout
      actions={
        <>
          <Button onClick={handleRefresh} variant="outline" size="icon">
            {isFetching ? (
              <LoaderCircle className="animate-spin" />
            ) : (
              <RefreshCwIcon className="size-5" />
            )}
          </Button>
          {canEditCampaigns && (
            <Link to="/campaigns/create" className={buttonVariants()}>
              <PlusIcon />
              {t("campaigns.list.create")}
            </Link>
          )}
        </>
      }
      content={
        <CampaignsTable
          campaigns={campaigns}
          metricsMeta={metricsMeta}
          hasWriteAccess={canEditCampaigns}
          onEdit={handleRefresh}
        />
      }
    />
  )
}

function useFetchCampaigns() {
  return useQueryAPI({
    apiEndPoint: "/campaign-filters/get-campaigns",
    queryKey: ["campaigns"],
    params: { search_string: "" },
    schema: CampaignListResponseSchema,
  })
}
