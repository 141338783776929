import { useTranslation } from "react-i18next"

import EmptyContainer from "@/components/EmptyContainer"
import Icon from "@/components/Icon"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { cn } from "@/helpers/classNames"
import { hasValue, isNullish, isString } from "@/helpers/typeguards"
import { formatStringValue } from "@/helpers/valueFormat"
import { ActivityFeedEntry, Decoder } from "@/pages/CustomConnectPanel/value"

import CollapsibleQuestion from "./CollapsibleQuestion"
import HistoryItemCard from "./HistoryItemCard"

type Props = {
  historyEntries: Decoder.CaseAndActivityFeeds["activity_feed"]
  studentCase: Decoder.CaseAndActivityFeeds["case"]
}

export function CallViewHistoryTabs({ historyEntries, studentCase }: Props) {
  const { t } = useTranslation()

  return (
    <Tabs defaultValue="history" className="w-full">
      <TabsList className="bg-neutral-100">
        <TabsTrigger value="history">
          <HistoryTabTitle count={historyEntries.length} />
        </TabsTrigger>
        <TabsTrigger value="detailedInformation">
          {t("caseHistory.tabs.detailedInformation")}
        </TabsTrigger>
      </TabsList>
      <TabsContent value="history" className="mt-0">
        <HistoryEntries historyEntries={historyEntries} />
      </TabsContent>
      <TabsContent value="detailedInformation" className="mt-0 bg-neutral-100">
        <StudentDetailsTabContent studentCase={studentCase} />
      </TabsContent>
    </Tabs>
  )
}

const HistoryTabTitle = ({ count }: { count: number }) => {
  const { t } = useTranslation()

  return (
    <div className={cn("flex items-center gap-x-1")}>
      <div>{t("caseHistory.tabs.history")}</div>
      <Icon name="dot" size="xxsm" className={"text-neutral-500"} />
      <div>{t("caseHistory.tabs.numberOfItems", { count })}</div>
    </div>
  )
}

function HistoryEntries({
  historyEntries,
}: {
  historyEntries: ActivityFeedEntry.ActivityFeedEntryType[]
}) {
  return (
    <div className={cn("w-full bg-neutral-200 pt-1")}>
      <div
        className={cn(
          "flex h-[450px] flex-col gap-y-4 overflow-y-scroll px-6 py-4",
          "w-full ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
        )}
        tabIndex={0}
      >
        {historyEntries.map((event, i) => (
          <HistoryItemCard event={event} key={i} />
        ))}
      </div>
    </div>
  )
}

/**
 * Content of the second tab of the history section: show only the last questions
 */
export function StudentDetailsTabContent({
  studentCase,
}: {
  studentCase: Decoder.Case
}) {
  const questions = studentCase?.processed_data?.selected_questions ?? []
  const textualQuestions =
    studentCase.processed_data?.selected_textual_questions ?? []

  if (questions.length === 0 && textualQuestions.length === 0) {
    return <EmptyContainer />
  }

  const selectedQuestion = questions.at(-1)
  const selectedTextualQuestion = textualQuestions.at(-1)

  return (
    <div className={cn("w-full bg-neutral-100 pt-1")}>
      <div
        className={cn(
          "flex h-[450px] flex-col gap-y-4 overflow-y-scroll px-6 py-4",
          "w-full ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
        )}
        tabIndex={0}
      >
        {hasValue(selectedTextualQuestion) &&
          isString(selectedTextualQuestion.question) && (
            <CollapsibleQuestion
              question={selectedTextualQuestion.question}
              answer={formatStringValue(selectedTextualQuestion.answer)}
            />
          )}

        {hasValue(selectedQuestion) && isString(selectedQuestion.question) && (
          <CollapsibleQuestion
            question={selectedQuestion.question}
            answer={
              isNullish(selectedQuestion.answers)
                ? ""
                : selectedQuestion.answers
            }
          />
        )}
      </div>
    </div>
  )
}
