import { SentryLogger } from "@helpers/sentryLogger"
import { isNull } from "@helpers/typeguards"

const sentryLogger = new SentryLogger()

/**
 * A generic service to handle CRUD operations in local storage.
 */
class LocalStorageService {
  private readonly APP_PREFIX = "call_panel_"

  setItem<T>(key: string, value: T): void {
    try {
      const jsonValue = JSON.stringify(value)
      localStorage.setItem(`${this.APP_PREFIX}${key}`, jsonValue)
    } catch (error) {
      if (error instanceof Error) {
        error.message = `Error setting key ${key} in local storage: ${error.message}`
      }

      sentryLogger.error(error)

      throw error
    }
  }

  getItem(key: string): unknown {
    try {
      const storedValue = localStorage.getItem(`${this.APP_PREFIX}${key}`)

      if (isNull(storedValue)) {
        return null
      }

      return JSON.parse(storedValue)
    } catch (error) {
      if (error instanceof Error) {
        error.message = `Error retrieving key ${key} from local storage: ${error.message}`
      }

      sentryLogger.error(error)

      return null
    }
  }

  removeItem(key: string): void {
    try {
      localStorage.removeItem(`${this.APP_PREFIX}${key}`)
    } catch (error) {
      if (error instanceof Error) {
        error.message = `Error removing key ${key} from local storage: ${error.message}`
      }

      sentryLogger.error(error)

      throw error
    }
  }
}

export default LocalStorageService
