import { cn } from "@helpers/classNames"

interface TableCellProps extends React.HTMLAttributes<HTMLTableCellElement> {
  colSpan?: number // `colSpan` seems to be missing on React.HTMLAttributes<HTMLTableCellElement>
}

export function Table({
  className,
  ...props
}: React.HTMLAttributes<HTMLTableElement>) {
  return (
    <table
      {...props}
      className={cn([
        "w-full border-separate border-spacing-0 whitespace-nowrap",
        className,
      ])}
    />
  )
}

export function TableHeader({
  className,
  ...props
}: React.HTMLAttributes<HTMLTableSectionElement>) {
  return <thead {...props} className={cn(className)} />
}

export function TableHead({ className, ...props }: TableCellProps) {
  return (
    <th
      {...props}
      className={cn([
        "border-b border-neutral-300 bg-neutral-100 px-4 py-2 text-left text-sm text-neutral-600",
        className,
      ])}
      data-sentry-unmask
    />
  )
}

export function TableSubHead({ className, ...props }: TableCellProps) {
  return (
    <th
      {...props}
      className={cn([
        "border-b border-neutral-300 bg-neutral-200 px-4 py-2 text-left text-xs text-neutral-600",
        className,
      ])}
      data-sentry-unmask
    />
  )
}

export function TableCell({ className, ...props }: TableCellProps) {
  return (
    <td
      {...props}
      className={cn([
        "border-b border-neutral-300 bg-neutral-100 px-4 py-2 text-sm text-neutral-700 ",
        className,
      ])}
    />
  )
}

export function TableBody({
  className,
  ...props
}: React.HTMLAttributes<HTMLTableSectionElement>) {
  return <tbody {...props} className={cn(className)} />
}
