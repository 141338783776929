import { useTranslation } from "react-i18next"
import * as callViewValue from "@components/CallView/value"
import { useAxiosClientContext } from "@components/core/AxiosInstanceProvider/useAxiosClient"
import { config } from "@config/index"
import { modalModeAtom } from "@helpers/atoms"
import { isNull } from "@helpers/typeguards"
import { useProcessingTimeHook } from "@hooks/callProcessingTime"
import { useConnectActionsHook } from "@hooks/connectActions"
import { useContactDataHook } from "@hooks/contactData"
import { useReactQueryOptions } from "@hooks/reactQueryOptions"
import { useToast } from "@hooks/toastHook"
import { useCallPanelLogger } from "@hooks/useLogger"
import * as connectPanelValue from "@pages/CustomConnectPanel/value"
import { useMutation } from "@tanstack/react-query"
import { useSetAtom } from "jotai"

interface GoOfflineHookType {
  closeModal: VoidFunction
  goOffline: () => Promise<void>
  isLoading: boolean
}

const useGoOfflineHook = (
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null,
): GoOfflineHookType => {
  const setModalMode = useSetAtom(modalModeAtom)

  const log = useCallPanelLogger()
  const { error: toastError } = useToast()
  const { t } = useTranslation()
  const axiosClient = useAxiosClientContext()
  const { retry } = useReactQueryOptions()

  const { closeContact, endTask, toggleAgentStatus } = useConnectActionsHook()
  const { getProcessingTime } = useProcessingTimeHook()

  const {
    callType,
    campaignId,
    isTask,
    isVoiceCallWithError,
    isVoiceContact,
    studentId,
    voiceContactId,
  } = useContactDataHook(currentCase)

  const closeModal = () => setModalMode(null)

  const { isPending, mutateAsync: sendSkipCallReport } = useMutation({
    mutationFn: async () => {
      try {
        if (isNull(studentId)) {
          throw new Error("[go-offline] studentId is not found")
        }

        if (isNull(campaignId)) {
          throw new Error("[go-offline] campaignId is not found")
        }

        const createdAt = new Date()
        const processingTime = getProcessingTime()

        // You cannot go offline when in a call or in ACW state after the call has taken place.
        // There is no call duration to be posted in this case.
        const callContact = voiceContactId
          ? {
              call_duration_seconds: null,
              contact_id: voiceContactId,
              created_at: createdAt,
              type: callType,
            }
          : undefined

        const response = await axiosClient.post(
          `https://${config.apiEndpoint}/call-reports`,
          {
            campaign_id: campaignId,
            student_id: studentId,
            status_event: {
              event_name: "スキップ",
              created_at: createdAt,
              processing_duration_seconds: processingTime,
              call_attempted: false,
            },
            call_contact: callContact,
            stop_most_recent_task: false,
          },
        )

        const validResponse =
          await callViewValue.Decoder.saveReportSchema.parseAsync(response.data)

        if (!validResponse.success.length) {
          throw new Error("[go-offline] Empty response from call report API")
        }

        return validResponse
      } catch (err) {
        throw new Error(
          `[go-offline] Failed to set agent offline while having an active contact: ${
            (err as Error).message
          }`,
          { cause: err },
        )
      }
    },
    retry,
  })

  const handleSendSkipCallReport = async (): Promise<void> => {
    try {
      await sendSkipCallReport()
    } catch (error) {
      log.error(error)
    }
  }

  const stopCurrentTask = async () => {
    try {
      await endTask()
    } catch (error) {
      log.error(error)
    }
  }

  const sendReportAndEndTask = async (): Promise<void> => {
    await handleSendSkipCallReport()
    await stopCurrentTask()
  }

  const goOffline = async (): Promise<void> => {
    try {
      if (isVoiceCallWithError) {
        await closeContact()
        await toggleAgentStatus({
          newStateType: connect.AgentStateType.OFFLINE,
        })
      } else if (isVoiceContact) {
        return toastError(t("global.modals.goOffline.callError"))
      } else if (isTask) {
        await toggleAgentStatus({
          newStateType: connect.AgentStateType.OFFLINE,
          enqueueNextState: true,
        })
        await sendReportAndEndTask()
      }
    } catch (error) {
      log.error(error)
    }

    setModalMode(null)
  }

  return { closeModal, goOffline, isLoading: isPending }
}

export { useGoOfflineHook }
