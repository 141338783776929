import { useTranslation } from "react-i18next"
import debounce from "lodash/debounce"

import Input from "@/components/core/Input"
import Icon from "@/components/Icon"

import { StudentSearchOptions } from "../api/students"
import { UniversityPicker } from "../university/university-picker"
import { StatusPicker } from "./status-picker"
import { useStudentsSearch } from "./students-search"

type Props = {
  onChange: ReturnType<typeof useStudentsSearch>["onChange"]
  searchOptions: StudentSearchOptions
}

export function StudentsSearchBar({ onChange, searchOptions }: Props) {
  const { t } = useTranslation()

  return (
    <div className="flex gap-4">
      <UniversityPicker
        values={searchOptions.universities}
        onChange={onChange("universities")}
        allowReset
        placeholder={t("campaigns.details.search.universities")}
      />
      <StatusPicker
        allowReset
        values={searchOptions.statuses}
        onChange={onChange("statuses")}
      />
      <SearchBox
        defaultValue={searchOptions.search_query || ""}
        onChange={onChange("search_query")}
      />
    </div>
  )
}

/**
 * Uncontrolled input to search by mane or phone, we don't need state here
 */
function SearchBox({
  defaultValue,
  onChange,
}: {
  defaultValue: string
  onChange: (text: string) => void
}) {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value)
  }

  const debouncedChange = debounce(handleChange, 300)

  return (
    <Input
      name="search"
      defaultValue={defaultValue}
      onChange={debouncedChange}
      target={<Icon name="search" />}
      inputClassName="h-10"
      placeholder="dashboard.selectOptions.searchPlaceholder"
    />
  )
}
