import { useTranslation } from "react-i18next"

import Icon from "@/components/Icon"
import { cn } from "@/helpers/classNames"

/** Show a notification card on incoming Inbound calls */
export function IncomingCallNotificationCard({
  isConnecting,
}: {
  isConnecting: boolean
}) {
  const { t } = useTranslation()

  return (
    <div
      className={cn([
        "overflow-hidden",
        !isConnecting && "transition-all duration-700 ease-linear",
      ])}
      style={{
        height: isConnecting ? "60px" : 0,
        marginBottom: isConnecting ? "0.5rem" : 0,
      }}
      data-sentry-unmask
    >
      <div className="font-body-large-bold flex items-center gap-x-2 rounded border border-success-200 bg-success-100 px-4 py-3 text-neutral-600">
        <Icon name="voice-call" />
        <p>{t("callPanel.incomingCall")}</p>
      </div>
    </div>
  )
}
