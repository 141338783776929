import { FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"
import Icon from "@components/Icon"
import { Button } from "@components/ui/button"
import { useAuthHook } from "@hooks/auth"

interface Props {
  errorDescription: string
  errorMessage: string
}

const styles = {
  pageContainer:
    "w-full h-full px-8 py-36 flex flex-col gap-y-6 text-neutral-700 items-center",
  icon: "text-danger-200",
  errorMsgContainer: "flex flex-col justify-center",
  status: "font-body-large-regular",
  message: "font-h1-bold",
  description: "font-body-large-regular",
}

const CCPAuthError: FunctionComponent<Props> = ({
  errorDescription,
  errorMessage,
}) => {
  const { logout } = useAuthHook()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <div className={styles.pageContainer} data-sentry-unmask>
      <Icon size="lg" name="alert" className={styles.icon} />
      <div className={styles.errorMsgContainer}>
        {errorMessage && <h1 className={styles.message}>{errorMessage}</h1>}
        {errorDescription && (
          <p className={styles.description}>{errorDescription}</p>
        )}
      </div>
      <Button
        type="button"
        onClick={async () => {
          setIsLoading(true)
          await logout()
          setIsLoading(false)
        }}
        isDisabled={isLoading}
        isLoading={isLoading}
      >
        {t("global.button.logout")}
      </Button>
    </div>
  )
}

export default CCPAuthError
