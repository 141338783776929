import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

import { FilterSelections } from "../campaigns-types"

const basicFilters = [
  "graduation_year",
  "department_group",
  "major",
  "gender",
] as (keyof FilterSelections)[]

type Props = PropsWithChildren<{
  availableFilters: (keyof FilterSelections)[]
  onChange: (value: keyof FilterSelections) => void
  showBasicFilters?: boolean
}>

export function FilterPicker({
  availableFilters,
  children,
  onChange,
  showBasicFilters = true,
}: Props) {
  const { t } = useTranslation()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={availableFilters.length === 0} asChild>
        {children}
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-campaign-filter" align="start">
        <DropdownMenuLabel>
          {t("campaigns.filters.add.smartFilters")}
        </DropdownMenuLabel>
        <DropdownMenuItem
          onClick={() => onChange("channels")}
          disabled={!availableFilters.includes("channels")}
          inset
        >
          {t(`campaigns.filters.channels.label`)}
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => onChange("university")}
          disabled={!availableFilters.includes("university")}
          inset
        >
          {t(`campaigns.filters.university.label`)}
        </DropdownMenuItem>
        {showBasicFilters && (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuLabel>
              {t("campaigns.filters.add.basicFilters")}
            </DropdownMenuLabel>
            {basicFilters.map((filter) => (
              <DropdownMenuItem
                key={filter}
                onClick={() => onChange(filter)}
                disabled={!availableFilters.includes(filter)}
                inset
              >
                {t(`campaigns.filters.${filter}.label`)}
              </DropdownMenuItem>
            ))}

            <DropdownMenuSeparator />
            <DropdownMenuLabel>
              {t("campaigns.filters.add.manualAdditions")}
            </DropdownMenuLabel>
            <DropdownMenuItem
              key="selected_phone_numbers"
              onClick={() => onChange("selected_phone_numbers")}
              disabled={!availableFilters.includes("selected_phone_numbers")}
              inset
            >
              {t(`campaigns.filters.selected_phone_numbers.label`)}
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
