import { Ref } from "react"
import { useLifecycles } from "react-use"
import { hasProperty, isNull } from "@helpers/typeguards"

const useClickOutside = (
  refs: Ref<HTMLElement>[],
  onClickOutside: VoidFunction,
) => {
  const onClick = (event: MouseEvent) => {
    const clickedElement = event.target
    if (clickedElement instanceof Element) {
      if (
        refs.some(
          (ref) =>
            !hasProperty("current")(ref) ||
            isNull(ref.current) ||
            !ref.current.contains(clickedElement),
        )
      ) {
        onClickOutside()
      }
    }
  }

  // UseEffect with cleanup function
  useLifecycles(
    () => window.addEventListener("mousedown", onClick),
    () => window.removeEventListener("mousedown", onClick),
  )
}

export { useClickOutside }
