import { Dispatch, SetStateAction } from "react"

import { useModal } from "@/components/core/Root/modal-root"
import { isArray } from "@/helpers/typeguards"

import { DialogAddStudents } from "../add-student/dialog-add-students"
import {
  FilterSelectionChannel,
  FilterSelections,
  FilterSelectionUniversity,
  PhoneNumberValidationResult,
} from "../campaigns-types"
import { DialogEditChannels } from "../channels/dialog-edit-channels"
import { DialogUniversityFilter } from "../university/universities-filter-control"

type Props = {
  addFilter: (filterKey: keyof FilterSelections) => void
  campaign_id: string | null
  filters: FilterSelections
  isAddedFilter: (filterKey: keyof FilterSelections) => boolean
  isPriorityFilter?: boolean
  removeFilter: (filterKey: keyof FilterSelections) => void
  restrictionFilters?: FilterSelections
  setFilters: Dispatch<SetStateAction<FilterSelections>>
}

export function useFiltersState({
  addFilter,
  campaign_id,
  filters,
  isAddedFilter,
  isPriorityFilter,
  removeFilter,
  restrictionFilters,
  setFilters,
}: Props) {
  const { showModal } = useModal()

  const allFilters: Array<keyof FilterSelections> = isPriorityFilter
    ? ["channels", "university"]
    : [
        "channels",
        "university",
        "graduation_year",
        "department_group",
        "major",
        "gender",
        "selected_phone_numbers",
      ]

  const availableFiltersToAdd = allFilters.filter(
    (key) => !isFilterVisible(key),
  )

  async function handleEditChannels() {
    const editedChannels = await showModal<FilterSelectionChannel[]>(
      (close) => (
        <DialogEditChannels
          close={close}
          campaign_id={campaign_id}
          channels={filters.channels || []}
          restrictionChannels={restrictionFilters?.channels}
        />
      ),
    )
    if (!editedChannels) return // cancel button
    setFilters((current) => ({
      ...current,
      channels: editedChannels,
    }))
  }

  async function handleEditUniversities() {
    const universityFilters = await showModal<FilterSelectionUniversity[]>(
      (close) => (
        <DialogUniversityFilter
          campaign_id={campaign_id}
          close={close}
          filters={filters.university || []}
          restrictionFilters={restrictionFilters?.university}
        />
      ),
    )
    if (!universityFilters) return // cancel button
    setFilters((current) => ({ ...current, university: universityFilters }))
  }

  async function handleEditPhoneNumbers() {
    const results = await showModal<PhoneNumberValidationResult[]>((close) => {
      return (
        <DialogAddStudents
          close={close}
          items={filters.selected_phone_numbers || []}
        />
      )
    })
    if (!results) return
    setFilters((current) => ({ ...current, selected_phone_numbers: results }))
  }

  /**
   * Channels, university and phone number have a specific behavior:
   * as we open a modal to edit them directly,
   * instead of just showing the filter button in the filter sidebar
   */
  function handleAddFilter(filterKey: keyof FilterSelections) {
    switch (filterKey) {
      case "channels":
        return handleEditChannels()
      case "selected_phone_numbers":
        return handleEditPhoneNumbers()
      case "university":
        return handleEditUniversities()
      default:
        addFilter(filterKey)
    }
  }

  const onChangeFilter =
    (propertyName: keyof FilterSelections) => (values: unknown[]) => {
      setFilters((current) => ({
        ...current,
        [propertyName]: { values },
      }))
    }

  const handleResetFilter = (filterKey: keyof FilterSelections) => () => {
    if (isFilterEmpty(filterKey, filters[filterKey])) {
      removeFilter(filterKey)
    } else {
      setFilters((current) => {
        const { [filterKey]: _, ...rest } = current

        return rest
      })
    }
  }

  const areAllFiltersHidden = allFilters.every(
    (key) => !isFilterVisible(key as keyof FilterSelections),
  )

  function isFilterVisible(filterKey: keyof FilterSelections) {
    return (
      !isFilterEmpty(filterKey, filters[filterKey]) || isAddedFilter(filterKey)
    )
  }

  return {
    isFilterVisible,
    availableFiltersToAdd,
    handleAddFilter,
    handleEditChannels,
    handleEditPhoneNumbers,
    handleEditUniversities,
    onChangeFilter,
    handleResetFilter,
    areAllFiltersHidden,
  }
}

export function isFilterSelectionsEmpty(filters: FilterSelections) {
  return Object.entries(filters).every(([key, value]) =>
    isFilterEmpty(key as keyof FilterSelections, value),
  )
}

function isFilterEmpty(
  filterKey: keyof FilterSelections,
  filterValue: FilterSelections[keyof FilterSelections],
) {
  switch (filterKey) {
    case "channels":
    case "selected_phone_numbers":
    case "university":
      return !filterValue || (isArray(filterValue) && filterValue?.length === 0)
    default:
      return !filterValue?.values || filterValue?.values.length === 0
  }
}
