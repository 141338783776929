import { getLocale } from "@helpers/i18n"
import { isNullish, isNumber } from "@helpers/typeguards"
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  formatDuration,
  intervalToDuration,
  intlFormatDistance,
  parse,
} from "date-fns"

// See here https://github.com/date-fns/date-fns/issues/229#issuecomment-646124041
// Returns a properly formatted string for durations of at least 1000 ms.
export const formatReadableTime = (duration: number) => {
  const locale = getLocale()

  const durations = intervalToDuration({ start: 0, end: duration })

  return formatDuration(durations, { locale })
}

export const fromString = (simpleDate: string) =>
  parse(simpleDate.replace(/\//g, "-"), "yyyy-MM-dd", new Date())

// https://date-fns.org/v2.25.0/docs/formatDistanceToNow
export const formatDistanceToDate = (date: Date | null | undefined): string => {
  if (isNullish(date)) {
    return "-"
  }

  if (Number.isNaN(date.getDate())) {
    return "-"
  }

  const now = new Date()
  const locale = getLocale()

  return intlFormatDistance(date, now, { locale: locale.code })
}

export const getTimestamp = (
  initialTimestamp: Date,
  date: Date,
): {
  hours: number
  minutes: number
  seconds: number
} => {
  const seconds = differenceInSeconds(date, initialTimestamp) % 60
  const minutes = differenceInMinutes(date, initialTimestamp) % 60
  const hours = differenceInHours(date, initialTimestamp)

  return { minutes, seconds, hours }
}

export const formatDateDigits = (value: number): string =>
  value < 10 ? `0${value}` : value.toString()

export const formatSecondsToTime = (seconds: unknown): string => {
  if (!isNumber(seconds)) {
    return "-"
  }
  // seconds => HH:mm
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)

  return `${formatDateDigits(hours)}:${formatDateDigits(minutes)}`
}
