import LocalStorageService from "./localStorageService"

/**
 * Service to handle CRUD operations in local storage for agent related data.
 */
class AgentStorageService extends LocalStorageService {
  private readonly RESET_PASSWORD_EMAIL = "reset_password_email"

  private static instance: AgentStorageService

  static getInstance(): AgentStorageService {
    if (!AgentStorageService.instance) {
      AgentStorageService.instance = new AgentStorageService()
    }

    return AgentStorageService.instance
  }

  setResetPasswordEmail(email: string): void {
    super.setItem(this.RESET_PASSWORD_EMAIL, email)
  }

  getResetPasswordEmail(): unknown {
    return super.getItem(this.RESET_PASSWORD_EMAIL)
  }

  removeResetPasswordEmail(): void {
    super.removeItem(this.RESET_PASSWORD_EMAIL)
  }

  clear(): void {
    const keys = [this.RESET_PASSWORD_EMAIL]

    keys.forEach((key) => super.removeItem(key))
  }
}

const agentStorageService = AgentStorageService.getInstance()

export default agentStorageService
