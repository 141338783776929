import { useTranslation } from "react-i18next"

import { Button } from "@/components/ui/button"
import {
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"

import { Campaign } from "../campaigns-types"
import { useMutationChangeCampaignStatus } from "./campaign-queries"
import { DialogMutation } from "./dialog-mutation"

type DialogProps = {
  campaign: Campaign
  close: (value: boolean) => void
}

export function DialogPauseCampaign({ campaign, close }: DialogProps) {
  const { t } = useTranslation()

  const mutation = useMutationChangeCampaignStatus(campaign.campaign_id)

  async function handlePause(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    await mutation.mutateAsync(false)
  }

  return (
    <DialogContent isPending={mutation.isPending}>
      <form onSubmit={handlePause}>
        <DialogHeader>
          <DialogTitle>{t("campaigns.dialogs.pause.title")}</DialogTitle>
        </DialogHeader>

        <DialogMutation
          status={mutation.status}
          action={
            <Button
              type="submit"
              variant="destructive"
              isLoading={mutation.isPending}
            >
              {t("campaigns.actions.pause")}
            </Button>
          }
          successMessage={t("campaigns.dialogs.pause.success")}
          errorMessage={t("campaigns.dialogs.error")}
          close={close}
        >
          <div className="flex flex-col gap-4">
            <p>{t("campaigns.dialogs.pause.description")}</p>
            <p>
              <code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                {campaign.campaign_id}
              </code>
            </p>
            <p>{t("campaigns.dialogs.pause.confirm")}</p>
          </div>
        </DialogMutation>
      </form>
    </DialogContent>
  )
}
