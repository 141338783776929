import { LoaderCircle } from "lucide-react"

import { ColumnGroupDef, DashboardTable } from "@/components/DashboardTable"
import { AlertLoadingError } from "@/components/ui/alert"

export function StatefulTable<T>({
  columnGroups,
  error,
  getItemId,
  isLoading,
  items,
}: {
  columnGroups: ColumnGroupDef<T>[]
  error: Error | null
  getItemId: (item: T) => string
  isLoading: boolean
  items: T[]
}) {
  const showTable = !isLoading && !error

  return (
    <>
      {isLoading && (
        <div className="flex w-full justify-center text-primary-300">
          <LoaderCircle className="animate-spin" size={30} />
        </div>
      )}
      {error && <AlertLoadingError error={error} title="Unable to Load data" />}
      {showTable && (
        <DashboardTable
          columnGroups={columnGroups}
          items={items}
          getItemId={getItemId}
        />
      )}
    </>
  )
}
