import { useTranslation } from "react-i18next"
import { useMutation } from "@tanstack/react-query"
import { LoaderCircle } from "lucide-react"

import { useAxiosClientContext } from "@/components/core/AxiosInstanceProvider/useAxiosClient"
import { Button } from "@/components/ui/button"
import { useToast } from "@/hooks/toastHook"

export default function SyncAgents() {
  const { t } = useTranslation()
  const { error: toastError, success: toastSuccess } = useToast()
  const axiosClient = useAxiosClientContext()

  const syncMutation = useMutation({
    mutationFn: () => axiosClient.post("/agents/sync-users"),
    onError: () => {
      toastError(t("syncAgents.error"))
    },
    onSuccess: () => {
      toastSuccess(t("syncAgents.success"))
    },
  })

  const handleSync = async () => {
    try {
      await syncMutation.mutateAsync()
    } catch (error) {
      toastError(t("syncAgents.error"))
    }
  }

  return (
    <main className="flex h-full flex-col gap-y-4 px-8 pt-10">
      <div className="flex items-center justify-between">
        <h1 className="font-h2-bold text-neutral-700">
          {t("sidebarMenu.syncAgents")}
        </h1>
      </div>
      <div className="flex w-[600px] flex-col gap-8 rounded-sm border border-solid border-neutral-300 bg-neutral-100 p-4">
        <div className="font-bold text-neutral-700">
          {t("syncAgents.description")}
        </div>
        <div>
          <Button onClick={handleSync} disabled={syncMutation.isPending}>
            {syncMutation.isPending ? (
              <>
                <LoaderCircle className="animate-spin" />
                {t("syncAgents.syncing")}
              </>
            ) : (
              <>{t("syncAgents.buttonText")}</>
            )}
          </Button>
        </div>
      </div>
    </main>
  )
}
