import { useState } from "react"
import { PlusIcon, X } from "lucide-react"

import { Button } from "@/components/ui/button"

import { QuestionAnswerItem } from "../campaigns-types"
import { QuestionAnswersOperatorPicker } from "./qa-operator-picker"

type Props = {
  channel_id: number
  onChangeQuestionAnswers: (questionAnswers: QuestionAnswerItem[]) => void
  questionAnswers: QuestionAnswerItem[]
}
export function ChannelsEditorQA({
  channel_id,
  onChangeQuestionAnswers,
  questionAnswers,
}: Props) {
  const [showExtra, setShowExtra] = useState(false)

  const addQuestion = (question: string) => {
    onChangeQuestionAnswers([
      ...questionAnswers,
      { question, operator: "and", answers: [] },
    ])
    setShowExtra(false)
  }

  const addEmptyQuestion = () => {
    setShowExtra(true)
  }

  const updateAt = (index: number, changes: Partial<QuestionAnswerItem>) => {
    const updated = questionAnswers.slice()
    updated[index] = { ...updated[index], ...changes }
    onChangeQuestionAnswers(updated)
  }

  const setQuestion = (index: number) => (question: string) => {
    updateAt(index, { question })
  }
  const setAnswers = (index: number) => (answers: string[]) => {
    updateAt(index, { answers })
  }
  const setOperator =
    (index: number) => (operator: QuestionAnswerItem["operator"]) => {
      updateAt(index, { operator })
    }

  const removeQuestion = (index: number) => () => {
    onChangeQuestionAnswers(questionAnswers.filter((_, i) => i !== index))
  }

  const showExtraQuestion = showExtra || questionAnswers.length === 0

  return (
    <div className="flex flex-col gap-4">
      {questionAnswers.length > 0 && (
        <div className="flex flex-col gap-4">
          {questionAnswers.map((qa, index) => {
            const isLastQuestion = index === questionAnswers.length - 1

            return (
              <div className="flex justify-between" key={index}>
                <QuestionAnswersOperatorPicker
                  key={index}
                  channelId={channel_id}
                  question={qa.question || null}
                  setQuestion={setQuestion(index)}
                  answers={qa.answers}
                  setAnswers={setAnswers(index)}
                  operator={qa.operator}
                  setOperator={setOperator(index)}
                />
                <div className="flex min-w-[90px] items-center gap-2">
                  <Button
                    variant="outline"
                    className="rounded-full"
                    size="icon"
                    onClick={removeQuestion(index)}
                    type="button"
                  >
                    <X />
                  </Button>
                  {isLastQuestion && (
                    <Button
                      variant="outline"
                      className="rounded-full"
                      size="icon"
                      onClick={addEmptyQuestion}
                      type="button"
                    >
                      <PlusIcon />
                    </Button>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      )}
      {showExtraQuestion && (
        <div>
          <QuestionAnswersOperatorPicker
            key={"extra"}
            channelId={channel_id}
            question={null}
            setQuestion={addQuestion}
          />
        </div>
      )}
    </div>
  )
}
