import { useTranslation } from "react-i18next"
import { z } from "zod"

import { AlertLoadingError } from "@/components/ui/alert"
import { Combobox } from "@/components/ui/combobox"
import { MultiCombobox } from "@/components/ui/multi-combobox"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Skeleton } from "@/components/ui/skeleton"
import { useQueryAPI } from "@/hooks/query-api"

import { QuestionAnswerItem } from "../campaigns-types"

type Props = {
  answers?: string[]
  channelId: number
  operator?: QuestionAnswerItem["operator"]
  question: string | null
  setAnswers?: (answers: string[]) => void
  setOperator?: (operator: QuestionAnswerItem["operator"]) => void
  setQuestion: (question: string) => void
}

export function QuestionAnswersOperatorPicker({
  answers,
  channelId,
  operator,
  question,
  setAnswers,
  setOperator,
  setQuestion,
}: Props) {
  const { data, error, isLoading } = useFetchQuestionByChannelId(channelId)

  if (isLoading) {
    return (
      <div className="w-[280px] rounded px-4 py-2">
        <Skeleton className="h-6 w-full" />
      </div>
    )
  }

  if (error) {
    return <AlertLoadingError error={error} title="Unable to load QA" />
  }

  const channelQuestions = Object.keys(data?.data || [])

  return (
    <div className="flex gap-4">
      <QuestionPicker
        questions={channelQuestions}
        value={question}
        onChange={setQuestion}
      />
      {question && answers && setAnswers && operator && setOperator && (
        <>
          <OperatorPicker value={operator} onChange={setOperator} />
          <AnswersPicker
            answers={data?.data[question] || []}
            value={answers}
            onChange={setAnswers}
          />
        </>
      )}
    </div>
  )
}

const responseSchema = z.object({
  data: z.object({}).catchall(z.array(z.string())),
})

function useFetchQuestionByChannelId(channelId: number) {
  return useQueryAPI({
    apiEndPoint: "/campaign-filters/options/qa",
    params: {
      channel_id: channelId,
    },
    schema: responseSchema,
  })
}

function QuestionPicker({
  onChange,
  questions,
  value,
}: {
  onChange: (value: string) => void
  questions: string[]
  value: string | null
}) {
  const { t } = useTranslation()
  const options = questions.map((question) => ({
    label: question,
    value: question,
  }))

  return (
    <Combobox<string>
      options={options}
      value={value}
      onChange={onChange}
      placeholder={t("campaigns.filters.question.placeholder")}
      size="lg"
    />
  )
}

function AnswersPicker({
  answers,
  onChange,
  value,
}: {
  answers: string[]
  onChange: (values: string[]) => void
  value: string[]
}) {
  const { t } = useTranslation()
  const options = answers.map((answer) => ({
    label: answer,
    value: answer,
  }))

  return (
    <MultiCombobox<string>
      options={options}
      values={value}
      onChange={onChange}
      placeholder={t("campaigns.filters.answer.placeholder")}
      size="md"
    />
  )
}

function OperatorPicker({
  onChange,
  value,
}: {
  onChange: (value: QuestionAnswerItem["operator"]) => void
  value: QuestionAnswerItem["operator"]
}) {
  const { t } = useTranslation()

  const operators = ["and", "or", "except-for"]

  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger className="w-[100px]">
        <SelectValue placeholder="Select operator" />
      </SelectTrigger>
      <SelectContent>
        {operators.map((operator) => (
          <SelectItem key={operator} value={operator}>
            {t(`campaigns.filters.channels.operators.${operator}`)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
