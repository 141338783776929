import { useState } from "react"
import * as callViewValue from "@components/CallView/value"
import { useAxiosClientContext } from "@components/core/AxiosInstanceProvider/useAxiosClient"
import { config } from "@config/index"
import { hasValue, isNull } from "@helpers/typeguards"
import { useCallDurationData } from "@hooks/callDuration"
import { useProcessingTimeHook } from "@hooks/callProcessingTime"
import { useConnectActionsHook } from "@hooks/connectActions"
import { useContactDataHook } from "@hooks/contactData"
import { useReactQueryOptions } from "@hooks/reactQueryOptions"
import { useToast } from "@hooks/toastHook"
import { useCallPanelLogger } from "@hooks/useLogger"
import * as connectPanelValue from "@pages/CustomConnectPanel/value"
import { useMutation } from "@tanstack/react-query"

import { contactStorageService } from "@/services/localStorageService"

interface ResetCallViewType {
  isLoading: boolean
  onResetCallView: () => Promise<void>
}

const useResetCallViewHook = ({
  currentCase,
}: {
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
}): ResetCallViewType => {
  const [isLoading, setIsLoading] = useState(false)

  const log = useCallPanelLogger()
  const { error: toastError } = useToast()
  const axiosClient = useAxiosClientContext()
  const { retry } = useReactQueryOptions()

  const {
    closeContact,
    closeTask,
    endTask,
    hangUpPhoneCall,
    rejectCall,
    rejectTask,
  } = useConnectActionsHook()
  const { clearProcessingTimeData, getProcessingTime } = useProcessingTimeHook()
  const { clearCallDurationData } = useCallDurationData()

  const { callType, campaignId, isInboundCall, studentId, voiceContactId } =
    useContactDataHook(currentCase)

  const agentHasCalled = contactStorageService.getAgentHasCalled()

  const { mutateAsync: sendSkipCallReport } = useMutation({
    mutationFn: async () => {
      if (isNull(studentId)) {
        throw new Error("[reset-call-view] studentId is not found")
      }
      if (isNull(campaignId)) {
        throw new Error("[reset-call-view] campaignId is not found")
      }

      const createdAt = new Date()
      const processingTime = getProcessingTime()

      // It's assumed that it is an error while calling so will discard any available call duration
      // because we're skipping the task
      const callContact = voiceContactId
        ? {
            call_duration_seconds: null,
            contact_id: voiceContactId,
            created_at: createdAt,
            type: callType,
          }
        : undefined

      const response = await axiosClient.post(
        `https://${config.apiEndpoint}/call-reports`,
        {
          campaign_id: campaignId,
          student_id: studentId,
          status_event: {
            event_name: "スキップ",
            created_at: createdAt,
            processing_duration_seconds: processingTime,
            call_attempted: agentHasCalled,
          },
          call_contact: callContact,
          stop_most_recent_task: false,
        },
      )

      const validResponse =
        await callViewValue.Decoder.saveReportSchema.parseAsync(response.data)

      if (!validResponse.success.length) {
        throw new Error("[reset-call-view] Failed to save call report")
      }

      return validResponse
    },
    retry,
  })

  const resetCall = async () => {
    try {
      await closeContact()
    } catch {
      try {
        await hangUpPhoneCall()
        await closeContact()
      } catch {
        await rejectCall()
      }
    }
  }

  const resetTask = async () => {
    try {
      await closeTask()
    } catch {
      try {
        await endTask()
      } catch {
        await rejectTask()
        await closeTask()
      }
    }
  }

  const resetContact = async () => {
    try {
      // Reset the call processing time for inbound calls
      // there is no task to follow the call after it ends
      if (isInboundCall) {
        clearProcessingTimeData()
      }
      clearCallDurationData()
      await resetCall()
    } catch {
      clearProcessingTimeData()
      clearCallDurationData()
      await resetTask()
    }
  }

  // Only send a call report if we have the necessary information
  const skipCallAndEndTask = async () => {
    if (hasValue(studentId) && hasValue(campaignId)) {
      await sendSkipCallReport()
    }

    clearProcessingTimeData()
    clearCallDurationData()

    await endTask()
  }

  /**
   * Attempts to reset the call panel when stuck in an infinite loadind loop.
   * It first attempts to skip the task back-end side by sending a call report.
   * If that fails, it attempts to end the call and close the contact.
   * If that fails, it attempts to end the task client side.
   *
   */
  const onResetCallView = async () => {
    setIsLoading(true)

    try {
      await skipCallAndEndTask()
      setIsLoading(false)
    } catch (err) {
      try {
        await resetContact()
        setIsLoading(false)
      } catch (err) {
        log.error(
          new Error(
            `[reset-call-view] Failed to reset call view state: ${
              (err as Error).message
            }`,
            { cause: err },
          ),
        )

        toastError("errors.callPanel.nextContactError")
        setIsLoading(false)
      }
    }
  }

  return { onResetCallView, isLoading }
}

export { useResetCallViewHook }
