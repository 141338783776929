import { isNullish } from "@helpers/typeguards"

const uuidRegexp = /\/(?<connect_user_id>[\w-]*)$/i

const getAgentId = (agentARN: string | undefined): string | undefined => {
  if (isNullish(agentARN)) {
    return
  }

  const matchResult = uuidRegexp.exec(agentARN)

  // https://github.com/microsoft/TypeScript/issues/29465
  if (
    isNullish(matchResult) ||
    isNullish(matchResult.groups) ||
    isNullish(matchResult.groups.connect_user_id)
  ) {
    throw new Error("Agent ID not found")
  }

  return matchResult.groups.connect_user_id
}

const getContactIdFromAgent = (
  agent: connect.Agent | null,
): string | undefined => {
  const taskId = agent?.getContacts(connect.ContactType.TASK)[0]?.getContactId()
  const voiceId = agent
    ?.getContacts(connect.ContactType.VOICE)[0]
    ?.getContactId()

  return taskId || voiceId
}

export { getAgentId, getContactIdFromAgent }
