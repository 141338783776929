import { toError } from "@helpers/error"
import {
  captureException,
  captureMessage,
  flush,
  SeverityLevel,
} from "@sentry/react"

/**
 * A logging service to be used in the hooks and in the aws connect iframe (where hooks are not an option)
 */
export class SentryLogger {
  private consoleLog(data: unknown, level: SeverityLevel) {
    let method: Exclude<SeverityLevel, "fatal" | "warning"> | "warn"

    switch (level) {
      case "fatal":
        method = "error"
        break
      case "warning":
        method = "warn"
        break
      default:
        method = level
    }

    console[method](`[${level.toLocaleUpperCase()}]: `, data) // eslint-disable-line no-console
  }

  /**
   * Log the provided info message to STDOUT and SENTRY.
   *
   * @param message - string
   * @param level - type `SeverityLevel` but excluding the error types such as error and fatal
   * @param context - Optional JSON serializable context object to provide to Sentry, of type `Contexts` (extends `Record<string, unknown>`)
   */
  private logMessage(
    message: string,
    level: Exclude<SeverityLevel, "error" | "fatal">,
    context?: Record<string, unknown>,
  ) {
    const captureContext = {
      level,
      contexts: {
        messageContext: { local_context: context },
      },
    }

    this.consoleLog(message, level)
    captureMessage(message, captureContext)
  }

  /**
   * Log the provided error, object or string to STDOUT and SENTRY.
   *
   * @param error - The error or object to log. If object, at minimum it should have attributes `.message` and `.name`
   * @param level - type `SeverityLevel` - actually a subset of it: error, warning, fatal
   * @param context - Optional JSON serializable context object to provide to Sentry, of type `Contexts` (extends `Record<string, unknown>`).
   * Top level key is what will appear as left hand heading on sentry and within that you can have nested key-value pairs or a single value. https://github.com/getsentry/sentry-javascript/issues/2905#issuecomment-692744516
   */
  private logError(
    error: unknown,
    level: Extract<SeverityLevel, "error" | "fatal" | "warning">,
    context?: Record<string, unknown>,
  ) {
    const parsedError = toError(error)

    const captureContext = {
      level,
      contexts: {
        // local_context key added to prevent Sentry from filtering out variables == null | undefined
        // in the extraErrorDetails object
        extraErrorDetails: { local_context: context },
      },
    }

    this.consoleLog(parsedError, level)
    captureException(parsedError, captureContext)

    flush(5000).then() // https://github.com/getsentry/sentry-javascript/issues/3031
  }

  info(message: string, context?: Record<string, unknown>) {
    return this.logMessage(message, "info", context)
  }

  error(error: unknown, context?: Record<string, unknown>) {
    return this.logError(error, "error", context)
  }

  warn(error: unknown, context?: Record<string, unknown>) {
    return this.logError(error, "warning", context)
  }
}
