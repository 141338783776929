import { enGB as enLocale, ja as jaLocale } from "date-fns/locale"
import i18n from "i18next"

import { config, langSchema, languages } from "@/config/index"

import { initI18n } from "./init"

export * from "./use-translation"

export function resolveLanguage() {
  const lang = i18n.resolvedLanguage?.slice(0, 2)
  const { data, success } = langSchema.safeParse(lang)

  return success ? data : config.defaultLang
}

export function getLocale(): Locale {
  const lang = resolveLanguage()

  switch (lang) {
    case languages.english:
      return enLocale
    case languages.japanese:
      return jaLocale
  }
}

async function main() {
  await initI18n({ fallbackLng: config.defaultLang })
}

main()

export default i18n
