import { FunctionComponent } from "react"

const styles = {
  container: "flex w-full items-center justify-center",
  progressBar:
    "relative w-full h-5 bg-neutral-100 border border-neutral-300 rounded-full overflow-hidden",
  accent:
    "absolute top-0 left-0 h-full w-32 bg-primary-200 rounded-full animate-progress",
}

const ProgressBar: FunctionComponent = () => {
  return (
    <div className={styles.container} id="loading-zone" aria-busy="true">
      <div
        role="progressbar"
        tabIndex={-1}
        aria-describedby="loading-zone"
        className={styles.progressBar}
      >
        <div className={styles.accent}></div>
      </div>
    </div>
  )
}

export default ProgressBar
