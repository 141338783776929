import { MouseEvent, useState } from "react"

import { Button, type ButtonProps } from "@/components/ui/button"

/** React button component that disables a button after the user clicks it.
 * Prevents unintended double clicks on actions such as answer call, hangup call, etc.
 * which will publish false positives in sentry.
 *
 * @param delay: delay in milliseconds before the button is disabled
 * @param onClick: function to be called when the button is clicked
 * @param isDisabled: if the button is disabled
 * @param children: button content
 * @param restProps: rest of the props to be passed to the button component
 * @returns
 */
function SingleClickButton({
  children,
  isDisabled,
  onClick,
  ...restProps
}: ButtonProps) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    setIsButtonDisabled(true)
    if (onClick) {
      // we don't await the onClick fn (in case of async fn)
      onClick(e)
    }
  }

  return (
    <Button
      {...restProps}
      onClick={handleClick}
      isDisabled={isButtonDisabled || isDisabled}
    >
      {children}
    </Button>
  )
}

export { SingleClickButton }
