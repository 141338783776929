import * as React from "react"

import { BasicTextarea, BasicTextareaProps } from "./textarea"

type Props = Omit<BasicTextareaProps, "defaultValue" | "onChange" | "value"> & {
  onChange: (values: string[]) => void
  values: string[]
}

/**
 * A textarea that handles an array of string as values,
 * rendering each value on a new line
 * Purpose: let users copy/paste columns of data from a spreadsheet (E.g. phone numbers to upload)
 */
export const MultiTextarea = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ onChange, values, ...other }, ref) => {
    return (
      <BasicTextarea
        ref={ref}
        value={stringify(values)}
        onChange={(e) => onChange(parse(e.target.value))}
        rows={10}
        {...other}
      />
    )
  },
)

MultiTextarea.displayName = "MultiTextarea"

function stringify(values: string[]) {
  return values.join("\n")
}

function parse(value: string) {
  return value ? value.split("\n") : []
}
