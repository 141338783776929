/* eslint-disable no-console */
import { useRef } from "react"
import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios"

import { useAuthHook } from "@/hooks/auth"
import { createSinglePromiseStore } from "@/lib/single-promise-store"

declare module "axios" {
  export interface AxiosRequestConfig {
    isRetry?: boolean
  }
}

export function useRefetchTokenAndRetry() {
  const refreshToken = useRefreshToken()

  function attachRetryInterceptor(instance: AxiosInstance) {
    instance.interceptors.response.use(onSuccess, async (error: AxiosError) =>
      onError(instance, error),
    )
  }

  // 2xx range status codes
  function onSuccess(result: AxiosResponse) {
    return result
  }

  async function onError(instance: AxiosInstance, error: AxiosError) {
    const config = getConfig(error)

    if (shouldRefreshToken(error)) {
      console.info("[auth] Auth error, a fresh token is needed...")
      const accessToken = await refreshToken()
      if (!accessToken) {
        // do nothing as the user should have been redirected to the login page, from `resetAuthentication`
        // no need to throw an error that would bubble up to the React Query level
        console.info("[auth] Failed to refresh, refresh token must be invalid")

        return
      }

      const headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      }

      console.info(`[auth] Retrying ${config.url} ***${accessToken.slice(-5)}`)

      return instance.request({ ...config, headers, isRetry: true })
    } else {
      console.info("[auth] No refresh token needed for this error")

      throw error
    }
  }

  return { attachRetryInterceptor }
}

/** Provide a refreshToken function that "dedupes" requests, to ensure we make only one request at a time */
export function useRefreshToken() {
  const { refreshTokenAfterFailure } = useAuthHook()
  const storeRef = useRef(createSinglePromiseStore(refreshTokenAfterFailure))
  const { fetch } = storeRef.current

  return fetch
}

function getConfig(error: AxiosError) {
  return error.config as AxiosRequestConfig
}

/**
 * Check if we should refresh the token
 * if an authentication error happens and it it's not already a retry
 */
function shouldRefreshToken(error: AxiosError) {
  const isRetry = (error.config as AxiosRequestConfig).isRetry
  const isAuthError = error.response?.status === 401

  return isAuthError && !isRetry
}
