import { useState } from "react"
import { ChevronRightIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { MultiTextarea } from "@/components/ui/multi-textarea"
import { useI18n } from "@/helpers/i18n"

import { PhoneNumberValidationResult } from "../campaigns-types"
import { DialogStep2 } from "./dialog-add-students-step-2"

type Props = {
  close: (value: PhoneNumberValidationResult[] | null) => void
  items: PhoneNumberValidationResult[]
}

export function DialogAddStudents({ close, items }: Props) {
  const initialPhoneNumbers = items.map((item) => item.phone_number)

  const [phoneNumbersToValidate, setPhoneNumbersToValidate] =
    useState<string[]>(initialPhoneNumbers)

  const [step, setStep] = useState<"confirm" | "edit">("edit")

  const handleValidate = async (phoneNumbers: string[]) => {
    setPhoneNumbersToValidate(phoneNumbers)
    setStep("confirm")
  }

  const handleCancel = () => close(null)

  const handleGoBack = () => setStep("edit")

  const handleConfirm = (results: PhoneNumberValidationResult[]) => {
    close(results)
  }

  return (
    <DialogContent
      className="max-w-4xl"
      onInteractOutside={(e) => e.preventDefault()}
    >
      {step === "edit" && (
        <AddStudentsStep1
          defaultValues={phoneNumbersToValidate}
          onCancel={handleCancel}
          onSubmit={handleValidate}
        />
      )}
      {step === "confirm" && (
        <DialogStep2
          phoneNumbers={phoneNumbersToValidate}
          onGoBack={handleGoBack}
          onConfirm={handleConfirm}
        />
      )}
    </DialogContent>
  )
}

function AddStudentsStep1({
  defaultValues,
  onCancel,
  onSubmit,
}: {
  defaultValues: string[]
  onCancel: () => void
  onSubmit: (phoneNumbers: string[]) => void
}) {
  const { t, tMap } = useI18n()

  const [phoneNumbers, setPhoneNumbers] = useState<string[]>(defaultValues)

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    onSubmit(phoneNumbers.filter(Boolean))
  }

  const canSubmit = phoneNumbers.length > 0

  return (
    <>
      <DialogHeader>
        <DialogTitle>
          {t("campaigns.filters.selected_phone_numbers.header")}
        </DialogTitle>
      </DialogHeader>

      <form onSubmit={handleSubmit}>
        <DialogBody>
          <div className="flex flex-col gap-4">
            <p>{t("campaigns.filters.selected_phone_numbers.explanation")}</p>
            <ul className="ml-4 list-disc">
              {tMap(
                "campaigns.filters.selected_phone_numbers.details",
                (text, i) => (
                  <li key={i} className="">
                    {text}
                  </li>
                ),
              )}
            </ul>
            <MultiTextarea
              values={phoneNumbers}
              onChange={setPhoneNumbers}
              placeholder="Enter phone numbers"
            />
          </div>
        </DialogBody>
        <DialogFooter>
          <Button type="button" variant="outline" onClick={onCancel}>
            {t("global.button.cancel")}
          </Button>
          <Button type="submit" disabled={!canSubmit}>
            {t("campaigns.filters.selected_phone_numbers.submit")}
            <ChevronRightIcon className="size-5" />
          </Button>
        </DialogFooter>
      </form>
    </>
  )
}
