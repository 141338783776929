import { createContext, useContext } from "react"
import { isNull } from "@helpers/typeguards"

export type OptionsType = {
  [key: string]: unknown
} & {
  DomainName: string
  KeyName: string
  Values: string[] | undefined
}

export type CustomerProfileClientContextType = {
  checkIsClientReady: () => Promise<boolean>
  initializeClient: () => Promise<void>
  searchProfiles: (options: OptionsType) => Promise<unknown>
  unmountClient: VoidFunction
}

const CustomerProfileClientContext =
  createContext<CustomerProfileClientContextType | null>(null)

const useCustomerProfileClientContext =
  (): CustomerProfileClientContextType => {
    const context = useContext(CustomerProfileClientContext)

    if (isNull(context)) {
      throw new Error(
        "CustomerProfileClientContext must be instantiated in the Private Route component",
      )
    }

    return context
  }

export { CustomerProfileClientContext, useCustomerProfileClientContext }
