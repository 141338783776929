import { Dispatch, FunctionComponent, SetStateAction } from "react"
import { UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"
import * as callViewValue from "@components/CallView/value"
import * as connectPanelValue from "@pages/CustomConnectPanel/value"

import { isNull } from "@/helpers/typeguards"

import { CallReportModeType } from "../CallView"
import { CallReportStatelessForm } from "./call-report-stateless-form"
import { useACWFormHook } from "./hook"

interface Props {
  callReportMode: CallReportModeType
  caseStatuses: connectPanelValue.Decoder.CaseStatuses | null
  className?: string
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  form: UseFormReturn<callViewValue.Decoder.DecoderType>
  historyItem: connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null
  setHistoryItem: Dispatch<
    SetStateAction<connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null>
  >
}

const CallReportForm: FunctionComponent<Props> = ({
  callReportMode,
  caseStatuses,
  className,
  currentCase,
  form,
  historyItem,
  setHistoryItem,
}) => {
  const { isLoadingAgent, onSave, status } = useACWFormHook({
    setHistoryItem,
    currentCase,
  })
  const { t } = useTranslation()
  const title = t(
    callReportMode === "skip-call" ? "callResult.pcrTitle" : "callResult.title",
  )

  return (
    <CallReportStatelessForm
      caseStatuses={caseStatuses}
      className={className}
      currentCase={currentCase}
      form={form}
      isNewItem={isNull(historyItem)}
      isLoadingAgent={isLoadingAgent}
      onSave={onSave}
      status={status}
      callReportMode={callReportMode}
      title={title}
    />
  )
}

export default CallReportForm
