import { FunctionComponent } from "react"
import NavigateWithContactModal from "@components/modals/NavigateWithContactModal"
import { hasProperty, isValidModalMode } from "@helpers/typeguards"
import { useRedirectModalHook } from "@hooks/redirectModal"
import { ChildrenProps } from "@typedefs/props"

// Show a modal when the agent is online and has an active contact
// Agent can only navigate to the call panel route to turn itself offline
const ForbidOnlineAgentRoute: FunctionComponent<ChildrenProps> = ({
  children,
}) => {
  const { modalMode } = useRedirectModalHook()

  return (
    <>
      {hasProperty("mode", isValidModalMode)(modalMode) &&
        modalMode.mode === "navigateWithContact" && (
          <NavigateWithContactModal />
        )}
      {children}
    </>
  )
}

export default ForbidOnlineAgentRoute
