import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"

import { useAgentProfile } from "@/hooks/agent-profile"

export function AdminRoute({ children }: PropsWithChildren) {
  const { t } = useTranslation()
  const { isManager } = useAgentProfile()

  if (!isManager) {
    return <main className="px-8 pt-10">{t("global.errors.unauthorized")}</main>
  }

  return <>{children}</>
}
