import { connectSessionStorageService } from "@services/localStorageService"

const setTimestampInLocalStorage = () =>
  connectSessionStorageService.setTimestampInLocalStorage()

const checkResetSession = () => connectSessionStorageService.checkResetSession()

const clearConnectTimestamp = () =>
  connectSessionStorageService.clearConnectTimestamp()

export { checkResetSession, clearConnectTimestamp, setTimestampInLocalStorage }
