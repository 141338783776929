import { FunctionComponent } from "react"

import { AgentStatusSwitcher } from "./agent-status-switcher"
import { useAgentStatusHook } from "./hook"

const AgentStatusSelect: FunctionComponent = () => {
  const { disabledInManualCalling, onChange, selectedValue } =
    useAgentStatusHook()

  return (
    <AgentStatusSwitcher
      disabledInManualCalling={disabledInManualCalling}
      status={selectedValue}
      onChange={onChange}
      data-sentry-unmask
    />
  )
}

export default AgentStatusSelect
