/* eslint-disable no-console */
import { useCognitoTimeoutContext } from "@components/core/CognitoTimeoutProvider/useCognitoTimeoutContext"
import { clearConnectTimestamp } from "@components/core/ConnectCCP/connect_session"
import {
  callDurationDataAtom,
  cognitoModeAtom,
  pseudoACWAtom,
} from "@helpers/atoms"
import { hasValue } from "@helpers/typeguards"
import { idSchema } from "@helpers/zodSchemas"
import { useLogger } from "@hooks/useLogger"
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js"
import axios from "axios"
import { useSetAtom } from "jotai"
import { RESET } from "jotai/utils"
import { z } from "zod"

import { useManualCallAtom } from "@/hooks/useManualCallAtom"
import { contactStorageService } from "@/services/localStorageService"

import { config } from "../config"

type ValidInputCredentials = {
  password: string
  username: string
}

type UseAuthHookType = {
  authenticateCognitoUser: (
    credentials: ValidInputCredentials,
  ) => Promise<CognitoUserSession>
  changePassword: ({
    email,
    newPassword,
    verificationCode,
  }: {
    email: string
    newPassword: string
    verificationCode: string
  }) => Promise<string>
  checkAuth: () => Promise<void>
  checkIsValidCognitoSession: () => Promise<boolean>
  connectLoginUrl: string
  connectLogoutUrl: string
  getCognitoSession: () => Promise<CognitoUserSession>
  getConnectCredentials: (accessToken: string) => Promise<ConnectCredentials>
  logout: (shouldClearAllState?: boolean) => Promise<void>
  refreshTokenAfterFailure: () => Promise<string | undefined>
  requestPasswordReset: (email: string) => Promise<string>
}

const credentialsSchema = z.object({
  AccessToken: z.string(),
  AccessTokenExpiration: z.number(),
  RefreshToken: z.string(),
  RefreshTokenExpiration: z.number(),
})

export type ConnectCredentials = z.infer<typeof credentialsSchema>

// Connect Credentials response includes the agent's user ID. However, will not store in state
// because it can be fetched from the agent's ARN
const connectCredentialsResponseSchema = z.object({
  success: z.string(),
  data: z.object({
    connect_user_id: idSchema,
    login_credentials: credentialsSchema,
  }),
  reqId: idSchema,
})

const userPool = new CognitoUserPool({
  UserPoolId: config.cognitoUserPoolId,
  ClientId: config.cognitoClientId,
})

const authUrl = `https://${config.apiEndpoint}/ccp-login`
const connectLoginUrl = `https://${config.connectInstanceAlias}.my.connect.aws/auth/sign-in`
export const connectLogoutUrl = `https://${config.connectInstanceAlias}.my.connect.aws/connect/logout`
// const connectRefreshUrl = `https://${config.connectInstanceAlias}.my.connect.aws/auth/refresh`

const useAuthHook = (): UseAuthHookType => {
  const setCognitoMode = useSetAtom(cognitoModeAtom)
  const timerIdRef = useCognitoTimeoutContext()
  const log = useLogger()
  const setPseudoACW = useSetAtom(pseudoACWAtom)
  const setCallDurationData = useSetAtom(callDurationDataAtom)
  const { resetInManualCall } = useManualCallAtom()

  // Retrieves the jwt credentials from Cognito (idToken, accessToken, refreshToken) and stores them in local storage
  // Also stores creds in state to validate if user is logged in
  const authenticateCognitoUser = async ({
    password,
    username,
  }: ValidInputCredentials): Promise<CognitoUserSession> => {
    setCognitoMode({ current: "AUTHENTICATING" })

    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    })

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    })

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (credentials) => {
          // We do not se the cognitoMode to AUTHENTICATED here. It is done in the Protected route
          // which calls the checkAuth function
          resolve(credentials)
        },
        onFailure: (err) => {
          setCognitoMode({ current: "NOT_AUTHENTICATED" })
          reject(err)
        },
      })
    })
  }

  const getCurrentUser = (): CognitoUser | null => {
    return userPool.getCurrentUser()
  }

  const getCognitoSession = async (): Promise<CognitoUserSession> => {
    const cognitoUser = getCurrentUser()

    if (!cognitoUser) {
      throw new Error("No cognito user found")
    }

    return new Promise((resolve, reject) => {
      cognitoUser.getSession(
        (err: Error | null, credentials: CognitoUserSession | null) => {
          if (hasValue(credentials)) {
            resolve(credentials)
          } else {
            reject(err ?? new Error("No cognito session found"))
          }
        },
      )
    })
  }

  // Returns new credentials and also stores them in local storage
  const refreshCognitoSession = async (): Promise<CognitoUserSession> => {
    const cognitoUser = getCurrentUser()
    const session = await getCognitoSession()

    if (!cognitoUser) {
      throw new Error("No cognito user found")
    }

    return new Promise((resolve, reject) => {
      cognitoUser.refreshSession(
        session.getRefreshToken(),
        (err: Error | null, newCredentials: CognitoUserSession | null) => {
          if (newCredentials) {
            resolve(newCredentials)
          } else {
            reject(err ?? new Error("Failed to refresh the cognito session"))
          }
        },
      )
    })
  }

  const cognitoSignOut = (): void => {
    const cognitoUser = getCurrentUser()

    if (hasValue(cognitoUser)) {
      cognitoUser.signOut()
    }

    setCognitoMode({ current: "NOT_AUTHENTICATED" })
  }

  const fallbackLogout = (): void => {
    try {
      cognitoSignOut()
      clearConnectTimestamp()
      contactStorageService.clear()
      resetInManualCall()
      setPseudoACW(RESET)
      setCallDurationData(RESET)
    } catch (err) {
      setCognitoMode({ current: "NOT_AUTHENTICATED" })
      clearConnectTimestamp()
      contactStorageService.clear()
      resetInManualCall()
      setPseudoACW(RESET)
      setCallDurationData(RESET)
    }
  }

  const logout = async (shouldClearAllState = false) => {
    try {
      // First sign out of Amazon Connect
      await fetch(connectLogoutUrl, {
        credentials: "include",
        mode: "no-cors",
      })

      // https://github.com/amazon-connect/amazon-connect-streams/issues/365
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const eventBus = connect?.core?.getEventBus()
      if (hasValue(eventBus)) {
        eventBus.trigger(connect.EventType.TERMINATE)
      }

      // We do not clear the cognito timer (see timerIdRef) here because it is done in the Protected route
      // It calls a cleanup hook when the component is unmounted
      // We also navigate to the login page in the Protected route
      cognitoSignOut()
      clearConnectTimestamp()

      // clear call related data from local storage on logout
      contactStorageService.clear(shouldClearAllState)
      if (shouldClearAllState) {
        setCallDurationData(RESET)
        setPseudoACW(RESET)
      }

      resetInManualCall()
    } catch (err) {
      log.error(err)
      // clears the full state in local storage on logout
      fallbackLogout()
    }
  }

  const getConnectCredentials = async (
    accessToken: string,
  ): Promise<ConnectCredentials> => {
    const credentialsResponse = await axios.post(
      authUrl,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )

    const validResponse = await connectCredentialsResponseSchema.parseAsync(
      credentialsResponse.data,
    )

    const {
      data: { login_credentials: loginCredentials },
    } = validResponse

    return loginCredentials
  }

  const getRefreshTokenInterval = (session: CognitoUserSession): number => {
    const accessToken = session.getAccessToken()
    const expireAt = accessToken.getExpiration()
    const buffer = config.accessTokenTimeoutBuffer

    return expireAt * 1000 - Date.now() - buffer
  }

  const resetAuthentication = async (err: unknown) => {
    const isAgentLoggedOut =
      err instanceof Error && err.message === "No cognito user found"

    clearTimeout(timerIdRef.current)
    // Required in order to redirect to /login page
    setCognitoMode({ current: "NOT_AUTHENTICATED" })

    if (!isAgentLoggedOut) {
      log.error(err)
      // Logout the agent from both cognito and connect whenever we fail to validate a session
      // or to refresh it
      await logout()
    }
  }

  const validateSession = async () => {
    let session = await getCognitoSession()

    if (!session.isValid() || getRefreshTokenInterval(session) <= 0) {
      session = await refreshCognitoSession()
    }

    updateAuthState(session)

    return session
  }

  const refreshToken = async () => {
    const session = await refreshCognitoSession()
    updateAuthState(session)

    const accessToken = session.getAccessToken().getJwtToken()

    return accessToken
  }

  const updateAuthState = (session: CognitoUserSession) => {
    const accessToken = session.getAccessToken().getJwtToken()
    console.info(
      "[auth] Authenticated,",
      showTokenExpiration(session.getAccessToken()),
    )
    setCognitoMode({
      current: "AUTHENTICATED",
      state: { accessToken },
    })
  }

  const scheduleRefresh = async (session: CognitoUserSession) => {
    try {
      const computedInterval = getRefreshTokenInterval(session)
      const minimumInterval = 60 * 1000 // don't make requests more often than every minute to avoid rate limiting
      const actualInterval = Math.max(computedInterval, minimumInterval)
      console.log(
        `[auth] Refresh token scheduled in ${Math.round(
          actualInterval / 1000 / 60,
        )} minutes`,
      )

      // Delete existing timers to account for useEffect running twice in dev
      // and for react query instances running in parallel
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current)
      }

      timerIdRef.current = setTimeout(async () => {
        try {
          console.info(`[auth] Scheduled refresh token request...`)
          const newSession = await validateSession()
          await scheduleRefresh(newSession)
        } catch (err) {
          await resetAuthentication(err)
        }
      }, actualInterval)
    } catch (err) {
      await resetAuthentication(err)
    }
  }

  const checkAuth = async () => {
    try {
      const session = await validateSession()
      await scheduleRefresh(session)
    } catch (err) {
      await resetAuthentication(err)
    }
  }

  /**
   * Fetch a new token after a 401 error, when the token has expired (see Axios interceptor)
   * The session does not have to be validated in this scenario
   * */
  const refreshTokenAfterFailure = async () => {
    try {
      const accessToken = await refreshToken()

      return accessToken
    } catch (err) {
      await resetAuthentication(err)
    }
  }

  const checkIsValidCognitoSession = async () => {
    let cognitoSession: CognitoUserSession | null

    try {
      cognitoSession = await getCognitoSession()
    } catch {
      cognitoSession = null
    }

    return cognitoSession?.isValid() ?? false
  }

  // Send a password reset message (email only for now)
  const requestPasswordReset = async (email: string): Promise<string> => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    })

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: () => resolve("Success"),
        onFailure: (err) => reject(err),
      })
    })
  }

  const changePassword = ({
    email,
    newPassword,
    verificationCode,
  }: {
    email: string
    newPassword: string
    verificationCode: string
  }): Promise<string> => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    })

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => resolve("Success"),
        onFailure: (err) => reject(err),
      })
    })
  }

  return {
    connectLoginUrl,
    connectLogoutUrl,
    authenticateCognitoUser,
    getCognitoSession,
    logout,
    getConnectCredentials,
    checkAuth,
    refreshTokenAfterFailure,
    checkIsValidCognitoSession,
    requestPasswordReset,
    changePassword,
  }
}

export { useAuthHook }

function showTokenExpiration(token: CognitoAccessToken) {
  const expiresAt = new Date(token.getExpiration() * 1000)
  const now = new Date().getTime()
  const expiresIn = Math.round((expiresAt.getTime() - now) / 1000 / 60)

  return `Token expires in ${expiresIn} minutes (${expiresAt.toLocaleTimeString()})`
}
