import { useAxiosClientContext } from "@/components/core/AxiosInstanceProvider/useAxiosClient"
import { useQueryAPI } from "@/hooks/query-api"

import {
  Campaign,
  CampaignDetails,
  CampaignDetailsResponseSchema,
  CampaignStatus,
  FilterSelections,
} from "../campaigns-types"

export function useFetchCampaign(campaign_id: string) {
  return useQueryAPI({
    apiEndPoint: `/campaign-filters/${campaign_id}`,
    queryKey: ["campaigns", campaign_id], // used to invalidate the cache after updating the campaign
    schema: CampaignDetailsResponseSchema,
  })
}

export function useUpdateFilters() {
  const axiosClient = useAxiosClientContext()

  async function updateFilters(
    campaign_id: string,
    filters: FilterSelections,
    priorityFilters: FilterSelections | null,
  ) {
    return await axiosClient.post("/campaign-filters", {
      campaign_id,
      campaign_filter_selection: filters,
      priority_filter_selection: priorityFilters,
    })
  }

  return updateFilters
}

export function getCampaignStatus(campaign: Campaign): CampaignStatus {
  if (campaign.lock_status) {
    return campaign.lock_status
  }

  return campaign.enabled ? "active" : "paused"
}

export function getTotalStudents(campaign: CampaignDetails) {
  return (
    campaign.campaign_management_stats.metrics?.TotalStudentsInCampaign || 0
  )
}

export function getNumberOfStudentsToBeCalledToday(campaign: CampaignDetails) {
  return (
    campaign.campaign_management_stats.metrics?.TotalStudentsCanBeCalledToday ||
    0
  )
}
