import { MultiCombobox } from "@/components/ui/multi-combobox"

import { FilterControlLabel } from "./generic-filter-picker"

type Props = {
  onChange: (value: number[]) => void
  values: number[]
}

export function YearPicker({ onChange, values }: Props) {
  const years = getYears(2020, 2030)

  const options = years.map((item) => ({
    value: item,
    label: item.toString(),
  }))

  return (
    <MultiCombobox<number>
      options={options}
      values={values}
      onChange={onChange}
      size="campaign-filter"
      buttonProps={{ size: "sm" }}
      renderSelectedOptions={(selectedOptions) => {
        const labels = selectedOptions.map((option) => option.label)

        return (
          <FilterControlLabel filterKey={"graduation_year"} labels={labels} />
        )
      }}
    />
  )
}

function getYears(start: number, end: number) {
  const years = []
  for (let i = start; i <= end; i++) {
    years.push(i)
  }

  return years
}
