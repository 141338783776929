export const formatStringValue = (value: string | null | undefined): string => {
  if (!value || !value.trim().length) {
    return "-"
  }

  return value.trim()
}

export const formatValue = (
  value: number | string | null | undefined,
): number | string => {
  if (typeof value === "string") {
    return formatStringValue(value)
  }

  return value ?? "-"
}
