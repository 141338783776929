import { z } from "zod"

namespace Encoder {
  export interface EncoderType {
    password: string
    username: string
  }

  export const defaultValues: EncoderType = {
    username: "",
    password: "",
  }
}

namespace Decoder {
  export const schema = z.object({
    username: z.string(),
    password: z.string(),
  })
}

export { Decoder, Encoder }
