import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"

import { Button } from "@/components/ui/button"
import {
  DialogBody,
  DialogBodyError,
  DialogBodySuccess,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { useTableSorting } from "@/hooks/table-sorting"

import { useExportCampaignStudentsToCSV } from "../api/students"
import { useStudentsSearch } from "./students-search"

type Props = {
  campaign_id: string
  close: (result: boolean | null) => void
}

export function DialogExportCSV({ campaign_id, close }: Props) {
  const { t } = useTranslation()
  const { searchOptions } = useStudentsSearch()
  const { sortOptions } = useTableSorting({
    id: "latest_contacted_at",
    direction: "desc",
  })
  const { data, error, isLoading } = useExportCampaignStudentsToCSV({
    campaign_id,
    searchOptions,
    sortOptions,
  })

  if (error) {
    return (
      <DialogWrapper close={close}>
        <DialogBodyError>Unable to generate CSV file.</DialogBodyError>
      </DialogWrapper>
    )
  }

  if (isLoading) {
    return (
      <DialogWrapper isProcessing close={close}>
        <DialogBody>
          <div className="min-h-48">
            {t("campaigns.details.exportCSV.loading")}
          </div>
        </DialogBody>
      </DialogWrapper>
    )
  }

  const url = data?.data.csv_download_url

  return (
    <DialogWrapper close={close}>
      <DialogBodySuccess>
        <p className="mb-4">{t("campaigns.details.exportCSV.success")}</p>
        <a className="underline hover:no-underline" href={url}>
          {t("campaigns.details.exportCSV.download")}
        </a>
      </DialogBodySuccess>
    </DialogWrapper>
  )
}

function DialogWrapper({
  children,
  close,
  isProcessing,
}: PropsWithChildren<{ close: Props["close"]; isProcessing?: boolean }>) {
  const { t } = useTranslation()

  return (
    <DialogContent isPending={isProcessing}>
      <DialogHeader>
        <DialogTitle>{t("campaigns.details.exportCSV.action")}</DialogTitle>
      </DialogHeader>
      {children}
      <DialogFooter>
        <Button
          isLoading={isProcessing}
          onClick={() => close(false)}
          variant="outline"
        >
          {t("global.button.close")}
        </Button>
      </DialogFooter>
    </DialogContent>
  )
}
