/**
 * This file reflects the objects and types created in API `models/cases.ts` file
 * https://github.com/scoville/cci-call-panel-api/blob/develop/call_panel_api/src/models/cases.ts
 * TODO: share this with the backend when we move to a monorepo :)
 * */
import { z } from "zod"

export const statusMap = {
  TentativeEntry: "エントリー見込",
  OutboundMissedCall: "不通",
  CallAgainUnsuitableDates: "日程追加後に再架電",
  SendEmailCallLater: "メール送付後に再架電",
  CallLaterAtGivenTime: "日時を指定して再架電",
  InboundMissedCall: "再架電(折り返し)",
  Skip: "スキップ",
  RejectInstantly: "アウト(フロント)",
  RejectAfterPitch: "アウト(商談)",
  RejectUnsuitableDates: "アウト(有効商談)",
  ConsecutiveMissedCalls: "アウト(不通)",
  Cancelled: "キャンセル",
  AlreadyRegistered: "ノーカウント",
  ConfirmedEntry: "エントリー確定",
  GlobalNoCall: "リスト削除",
  CallerTemporarilyUnavailable: "通信不可",
  ConfirmedEntryByAgent: "代理エントリー確定",
  RejectBeforeCall: "アウト(架電前)",
  Scheduled: "scheduled",
  LeaveAsIs: "そのまま",
} as const

type StatusMap = typeof statusMap

export type Status = StatusMap[keyof StatusMap]

export const statusUsageMap = {
  automaticGoingOffline: "automatic call view (going-offline)",
  automaticInbound: "automatic call view (inbound)",
  automaticOutbound: "automatic call view (outbound)",
  automaticPreCall: "automatic call view (pre-call)", // In UI we also allow a special 'repeater' status beyond those specified by the API here, see https://scvl.slite.com/app/docs/fLATUu61IstA5b for details
  automaticPreConnectedCall: "automatic call view (pre-connected-call)",
  campaignDetailsCsvUpload: "campaign details view (csv-upload)",
  campaignDetailsDropdown: "campaign details view (filter-dropdown)",
  manualLeavingView: "manual call view (leaving-view)",
  manualOutbound: "manual call view (outbound)",
  manualPreConnectedCall: "manual call view (pre-connected-call)",
  studentDetails: "student details view",
} as const

export const statusSchema = z.object({
  id: z.nativeEnum(statusMap),
  en: z.string(),
  ja: z.string(),
  group: z.enum(["root", "callAgain", "out", "skip"]),
  usageContext: z.array(z.nativeEnum(statusUsageMap)),
})

export type CallStatusDef = z.infer<typeof statusSchema>
