import { XIcon } from "lucide-react"

import { Button } from "@/components/ui/button"

/** Shared by both "Filters" and "Priority Filters" panels */
export function FilterPanelLayout({
  addButton,
  children,
  emptyState,
  header,
  isEmpty,
}: React.PropsWithChildren<{
  addButton?: React.ReactNode
  emptyState?: React.ReactNode
  header: React.ReactNode
  isEmpty?: boolean
}>) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div className="flex gap-1 text-neutral-600">{header}</div>
        {!isEmpty && <div>{addButton}</div>}
      </div>
      <div>
        {isEmpty && emptyState}
        <div className="flex flex-col gap-4">{children}</div>
      </div>
    </div>
  )
}

type Props = {
  children: React.ReactNode
  label?: string
  onReset?: () => void
}

export function FilterControl({ children, label, onReset }: Props) {
  return (
    <section className="flex items-center justify-between gap-2" title={label}>
      <div>{children}</div>
      <div>
        {onReset && (
          <Button
            variant="ghost"
            size="icon"
            onClick={onReset}
            className="size-7 rounded-full opacity-40 hover:bg-danger-100 hover:opacity-100"
          >
            <XIcon className="size-4" />
          </Button>
        )}
      </div>
    </section>
  )
}
