interface UseOSHookType {
  isLinux: boolean
  isMac: boolean
  isWin: boolean
  os: "linux" | "mac" | "other" | "win"
}

const useOS = (): UseOSHookType => {
  // TODO: Consider other OS in the future
  const baseConfig: UseOSHookType = {
    isMac: false,
    isWin: false,
    isLinux: false,
    os: "other",
  }

  const isWin = navigator.userAgent.indexOf("Win") !== -1
  const isMac = navigator.userAgent.indexOf("Mac") !== -1
  const isLinux = navigator.userAgent.indexOf("Linux") != -1

  if (isWin) {
    return { ...baseConfig, isWin, os: "win" }
  } else if (isMac) {
    return { ...baseConfig, isMac, os: "mac" }
  } else if (isLinux) {
    return { ...baseConfig, isLinux, os: "linux" }
  } else {
    return baseConfig
  }
}

export { useOS }
