import { useLocation, useSearchParams } from "react-router-dom"
import { z } from "zod"

const DEFAULT_PAGE_SIZE = 20

/**
 * Read `page` and `limit` (the number of items per page) query parameters from the URL
 */
export function usePaginationParams(pageSize: number = DEFAULT_PAGE_SIZE) {
  const [searchParams] = useSearchParams()

  const paginationSchema = z.object({
    page: z.coerce.number().default(1),
    limit: z.coerce.number().default(pageSize),
  })

  const { limit, page } = paginationSchema.parse({
    limit: searchParams.get("limit") || undefined,
    page: searchParams.get("page") || undefined,
  })

  return { limit, page }
}

type PaginationParams = {
  pageSize?: number
  total: number
}

export function usePagination({
  pageSize = DEFAULT_PAGE_SIZE,
  total,
}: PaginationParams) {
  const location = useLocation()

  const [searchParams] = useSearchParams()
  const { limit, page } = usePaginationParams(pageSize)

  const hasPreviousPage = page > 1
  const hasNextPage = total > page * limit

  function getNextPagePath() {
    return setPage(page + 1)
  }
  function getPreviousPagePath() {
    return setPage(page - 1)
  }

  function setPage(pageNumber: number) {
    searchParams.set("page", pageNumber.toString())

    return getURL()
  }

  function getURL() {
    return location.pathname + "?" + searchParams.toString()
  }

  return {
    pageSize: limit,
    page,
    hasPreviousPage,
    hasNextPage,
    getNextPagePath,
    getPreviousPagePath,
    total,
  }
}
