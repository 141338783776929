import { MoreVerticalIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

import { getCampaignStatus } from "../api/campaigns"
import { Campaign } from "../campaigns-types"
import { PauseResumeCampaignMenuItems } from "../shared/campaign-shared-actions"

type Props = {
  campaign: Campaign
  onEdit: (campaign_id: string) => void
}

export function CampaignRowDropdownMenu({ campaign, onEdit }: Props) {
  const status = getCampaignStatus(campaign)
  const isPending = ["starting", "stopping"].includes(status)
  const isError = ["starting-failure", "stopping-failure"].includes(status)

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="size-8 p-0"
          disabled={isPending || isError}
        >
          <MoreVerticalIcon className="size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <PauseResumeCampaignMenuItems campaign={campaign} onEdit={onEdit} />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
