import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useAxiosClientContext } from "@/components/core/AxiosInstanceProvider/useAxiosClient"
import { useLogger } from "@/hooks/useLogger"

export function useMutationChangeCampaignStatus(campaign_id: string) {
  const axiosClient = useAxiosClientContext()
  const queryClient = useQueryClient()
  const log = useLogger()

  return useMutation({
    mutationFn: async (isEnabled: boolean) => {
      const action = isEnabled ? "start" : "stop"

      return await axiosClient.post(`/campaign-filters/${action}/v2`, {
        campaign_id,
      })
    },
    onSuccess: async () => {
      // Wait until the case list is updated to show the right status when closing the dialog
      return await queryClient.invalidateQueries({ queryKey: ["campaigns"] })
    },
    onError: (error) => {
      log.error(error)
    },
  })
}
