import { Dispatch, SetStateAction } from "react"
import { SubmitHandler } from "react-hook-form"
import * as callViewValue from "@components/CallView/value"
import {
  agentAtom,
  agentProfileAtom,
  currentContactStatusTypeAtom,
  currentContactTypeAtom,
  pseudoACWAtom,
} from "@helpers/atoms"
import { isNull } from "@helpers/typeguards"
import * as connectPanelValue from "@pages/CustomConnectPanel/value"
import { useAtomValue } from "jotai"

export type ACWFormStatus =
  | "disabled" // initial state, we are not in "ACW state"
  | "normal_ACW" // voice contact ended
  | "pseudo_ACW" // after a failed connection

interface useACWFormHookInput {
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  setHistoryItem: Dispatch<
    SetStateAction<connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null>
  >
}

const useACWFormHook = ({
  currentCase,
  setHistoryItem,
}: useACWFormHookInput) => {
  const agent = useAtomValue(agentAtom)
  const agentProfile = useAtomValue(agentProfileAtom)
  const currentContactType = useAtomValue(currentContactTypeAtom)
  const currentContactStatusType = useAtomValue(currentContactStatusTypeAtom)
  const isPseudoACW = useAtomValue(pseudoACWAtom)

  const isACWState =
    (currentContactStatusType === connect.ContactStateType.ENDED &&
      currentContactType === connect.ContactType.VOICE) ||
    isPseudoACW

  const onSave: SubmitHandler<callViewValue.Decoder.DecoderType> = async (
    data,
  ) => {
    const validData = await callViewValue.Decoder.schema.parseAsync(data)

    const firstName = agentProfile?.identity_info.first_name ?? ""
    const lastName = agentProfile?.identity_info.last_name ?? ""
    const email = agentProfile?.email ?? ""

    const isInboundCall =
      agent?.getContacts(connect.ContactType.VOICE)[0]?.isInbound() ?? false

    const formattedActivityEntry = connectPanelValue.ActivityFeedEntry.encode(
      validData,
      { firstName, lastName, email },
      currentCase?.case.campaign_id ?? "",
      isInboundCall ? "inbound" : "outbound",
    )

    setHistoryItem(() => formattedActivityEntry)
  }

  function getStatus(): ACWFormStatus {
    if (isPseudoACW) {
      return "pseudo_ACW"
    }

    if (isACWState) {
      return "normal_ACW"
    }

    return "disabled"
  }

  return {
    onSave,
    isPseudoACW,
    isLoadingAgent: isNull(agentProfile),
    status: getStatus(),
  }
}

export { useACWFormHook }
