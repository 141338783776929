import { hasValue } from "@helpers/typeguards"
import {
  caseIdRegexp,
  GetCaseParams,
  getCaseParamsSchema,
} from "@helpers/zodSchemas"

const getCaseQueryParams = ({
  campaignName,
  caseId,
  phoneNumber,
}: {
  campaignName: string | null
  caseId: string | null
  phoneNumber: string | null
}): GetCaseParams => {
  let queryStudentId: string | undefined = undefined
  let queryCampaignId: string | undefined = undefined
  let queryPhoneNumber: string | undefined = undefined

  // Incoming Task -> will query case by student_id and campaign_id
  if (hasValue(caseId)) {
    const params = caseIdRegexp.exec(caseId)

    if (params?.groups?.studentId) {
      queryStudentId = params?.groups?.studentId
    }

    if (params?.groups?.campaign) {
      queryCampaignId = params?.groups?.campaign
    }
  }

  // Fallbacks for inbound call and edge cases -> will query case by phone_number and campaign_id
  if (!queryCampaignId && hasValue(campaignName)) {
    queryCampaignId = campaignName
  }

  if (!queryPhoneNumber && hasValue(phoneNumber)) {
    queryPhoneNumber = phoneNumber
  }

  const validParams = getCaseParamsSchema.safeParse({
    student_id: queryStudentId,
    phone_number: queryPhoneNumber,
    campaign_id: queryCampaignId,
  })

  if (!validParams.success) {
    throw new Error(
      `Failed to fetch parameters for case query.${{
        caseId,
        campaignName,
        phoneNumber,
      }}`,
    )
  }

  return validParams.data
}

export { getCaseQueryParams }
