import { cn } from "@/helpers/classNames"
import { useCallStatusLabel } from "@/hooks/call-statuses-context"
import { Status, statusMap } from "@/models/cases"

interface Props {
  className?: string
  isSentryUnmask?: boolean
  value: Status
}

export function StatusPill({
  className,
  isSentryUnmask = false,
  value,
}: Props) {
  const translatedValue = useCallStatusLabel(value)

  return (
    <span
      className={cn(
        "font-label-medium block max-w-fit rounded-full px-4 py-1 font-bold text-neutral-600",
        getPillBgColor(value),
        className,
      )}
      {...(isSentryUnmask && { "data-sentry-unmask": true })}
    >
      {translatedValue}
    </span>
  )
}

function getPillBgColor(status: Status) {
  switch (status) {
    case statusMap.TentativeEntry:
    case statusMap.ConfirmedEntry:
    case statusMap.ConfirmedEntryByAgent:
      return "bg-success-100"
    case statusMap.InboundMissedCall:
    case statusMap.CallLaterAtGivenTime:
    case statusMap.CallAgainUnsuitableDates:
    case statusMap.CallerTemporarilyUnavailable:
    case statusMap.SendEmailCallLater:
      return "bg-warning-200"
    case statusMap.GlobalNoCall:
    case statusMap.RejectInstantly:
    case statusMap.RejectAfterPitch:
    case statusMap.RejectUnsuitableDates:
    case statusMap.ConsecutiveMissedCalls:
    case statusMap.Cancelled:
    case statusMap.AlreadyRegistered:
      return "bg-danger-100"
    default:
      return "bg-primary-100"
  }
}
