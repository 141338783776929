import { PropsWithChildren, useState } from "react"
import { useTranslation } from "react-i18next"
import { ChevronLeftIcon } from "lucide-react"

import { AlertLoadingError } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
  DialogBody,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"

import { PhoneNumberValidationResult } from "../campaigns-types"
import { PhoneNumbersTable } from "./phone-number-table"
import {
  addLineNumbers,
  isValidationError,
  useValidatePhoneNumbers,
  ValidationResult,
} from "./validation-api"

type Props = {
  onConfirm: (results: PhoneNumberValidationResult[]) => void
  onGoBack: () => void
  phoneNumbers: string[]
}

export function DialogStep2({ onConfirm, onGoBack, phoneNumbers }: Props) {
  const { t } = useTranslation()
  const { data, error, isLoading } = useValidatePhoneNumbers(phoneNumbers)

  if (isLoading) {
    return (
      <DialogStep2Layout
        footer={<Button type="button" variant="outline" isLoading />}
      >
        <PhoneNumbersTable isLoading results={[]} />
      </DialogStep2Layout>
    )
  }

  if (error) {
    return (
      <DialogStep2Layout
        footer={
          <Button type="button" variant="outline" onClick={onGoBack}>
            <ChevronLeftIcon className="size-5" />
            {t("global.button.goBack")}
          </Button>
        }
      >
        <AlertLoadingError
          error={error}
          title="Unable to validate the phone numbers"
        />
      </DialogStep2Layout>
    )
  }

  const results = addLineNumbers(data?.data || [])
  const hasSuccess = results.some((result) => !isValidationError(result))

  const handleConfirm = () => {
    onConfirm(results)
  }

  return (
    <DialogStep2Layout
      footer={
        <>
          <Button type="button" variant="outline" onClick={onGoBack}>
            <ChevronLeftIcon className="size-5" />
            {t("global.button.goBack")}
          </Button>
          <Button type="button" onClick={handleConfirm} disabled={!hasSuccess}>
            {t("campaigns.filters.channels.apply")}
          </Button>
        </>
      }
    >
      <ViewResults results={results} />
    </DialogStep2Layout>
  )
}

function ViewResults({ results }: { results: ValidationResult[] }) {
  const [showOnlyErrors, setShowOnlyErrors] = useState(false)

  const filteredResults = showOnlyErrors
    ? results.filter(isValidationError)
    : results

  return (
    <div className="flex flex-col gap-4">
      <DescribeResults
        results={results}
        showOnlyErrors={showOnlyErrors}
        setShowOnlyErrors={setShowOnlyErrors}
      />
      <div className="h-96 overflow-y-auto">
        <PhoneNumbersTable results={filteredResults} />
      </div>
    </div>
  )
}

function DescribeResults({
  results,
  setShowOnlyErrors,
  showOnlyErrors,
}: {
  results: ValidationResult[]
  setShowOnlyErrors: (value: boolean) => void
  showOnlyErrors: boolean
}) {
  const { t } = useTranslation()
  const total = results.length
  const errors = results.filter(isValidationError).length
  const foundStudents = total - errors

  if (!foundStudents)
    return (
      <div className="text-danger-300">
        {t("campaigns.filters.selected_phone_numbers.preview.noStudentsFound")}
      </div>
    )

  return (
    <div className="flex-col gap-4">
      <p>
        <span className="mr-1 text-2xl">{foundStudents}</span>
        {t("campaigns.filters.selected_phone_numbers.preview.found")}
      </p>
      {errors > 0 && (
        <div className="flex justify-between">
          <p className="text-danger-300">
            <span className="mr-1 text-xl">{errors}</span>
            {t("campaigns.filters.selected_phone_numbers.preview.errors")}
          </p>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="onlyErrors"
              checked={showOnlyErrors}
              onCheckedChange={setShowOnlyErrors}
            />
            <label htmlFor="onlyErrors">
              {t("campaigns.filters.selected_phone_numbers.preview.onlyErrors")}
            </label>
          </div>
        </div>
      )}
    </div>
  )
}

function DialogStep2Layout({
  children,
  footer,
}: PropsWithChildren<{
  footer: React.ReactNode
}>) {
  const { t } = useTranslation()

  return (
    <>
      <DialogHeader>
        <DialogTitle>
          {t("campaigns.filters.selected_phone_numbers.preview.header")}
        </DialogTitle>
      </DialogHeader>
      <DialogBody>{children}</DialogBody>
      <DialogFooter>{footer}</DialogFooter>
    </>
  )
}
