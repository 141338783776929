import { ForwardedRef, forwardRef } from "react"
import { FieldValues } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Icon from "@components/Icon"
import { cn } from "@helpers/classNames"
import { FormErrorProps } from "@typedefs/props"

interface Props<FieldsType extends FieldValues>
  extends FormErrorProps<FieldsType> {
  clearField?: VoidFunction
  hasError?: boolean
  isClearable: boolean
  isDisabled: boolean
  onBlur?: VoidFunction
  onClick?: VoidFunction
  onControllerBlur?: VoidFunction
  onControllerFocus?: VoidFunction
  onFocus?: VoidFunction
  value: Date | null
}

const styles = {
  wrapper: "relative min-w-[220px] w-full",
  input: (isDisabled: boolean, hasError: boolean) =>
    cn([
      "font-button-small w-full cursor-pointer select-none rounded border py-2 pl-4 pr-8 font-semibold",
      isDisabled && "pointer-events-none",
      isDisabled
        ? "bg-neutral-200 text-neutral-400"
        : "bg-neutral-100 text-neutral-700",
      hasError ? "border-danger-300" : "border-neutral-300",
    ]),
  icon: ({
    isActive,
    isDisabled = false,
  }: {
    isActive?: boolean
    isDisabled?: boolean
  }) =>
    cn([
      isDisabled && "pointer-events-none",
      "absolute right-3 top-1/2 size-5 -translate-y-1/2",
      isDisabled
        ? "text-neutral-400"
        : isActive
        ? "text-neutral-700"
        : "text-neutral-600",
    ]),
  value: (isDefinedValue: boolean, isDisabled: boolean) => {
    if (isDisabled) return "text-neutral-400"

    return isDefinedValue ? "text-neutral-700" : "font-normal text-neutral-600"
  },
}

const PseudoInput = <FieldsType extends FieldValues>(
  {
    clearField,
    hasError = false,
    isClearable,
    isDisabled,
    onBlur: reactDatePickerBlur,
    onClick,
    onControllerBlur,
    onControllerFocus,
    onFocus: reactDatePickerFocus,
    value,
  }: Props<FieldsType>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.input(isDisabled, hasError)}
        onClick={onClick}
        onFocus={() => {
          reactDatePickerFocus && reactDatePickerFocus()
          onControllerFocus && onControllerFocus()
        }}
        ref={ref}
        tabIndex={0}
        onBlur={() => {
          reactDatePickerBlur && reactDatePickerBlur()
          onControllerBlur && onControllerBlur()
        }}
      >
        <span className={styles.value(!!value, isDisabled)}>
          {value instanceof Date
            ? value.toString()
            : value
            ? value
            : t("global.datepicker.placeholder")}
        </span>
      </div>
      {isClearable && value ? (
        <Icon
          name="clear"
          className={cn([
            styles.icon({ isActive: Boolean(value), isDisabled }),
            "cursor-pointer",
          ])}
          onClick={(e) => {
            e.stopPropagation()
            clearField && clearField()
          }}
        />
      ) : (
        <Icon
          name="calendar"
          className={styles.icon({ isActive: Boolean(value), isDisabled })}
        />
      )}
    </div>
  )
}

const ForwardedPseudoInput = forwardRef(PseudoInput)

export default ForwardedPseudoInput
