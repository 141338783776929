import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { format } from "date-fns"
import { ChevronDownIcon } from "lucide-react"

import { useModal } from "@/components/core/Root/modal-root"
import { usePaginationParams } from "@/components/pagination/pagination-state"
import { AlertLoadingError } from "@/components/ui/alert"
import { PageBreadcrumb } from "@/components/ui/breadcrumb"
import { Button, buttonVariants } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Skeleton } from "@/components/ui/skeleton"
import { usePermissions } from "@/hooks/agent-profile"
import { useTableSorting } from "@/hooks/table-sorting"

import {
  getCampaignStatus,
  getNumberOfStudentsToBeCalledToday,
  getTotalStudents,
  useFetchCampaign,
} from "../api/campaigns"
import { useFetchStudentsByCampaign } from "../api/students"
import { CampaignDetails } from "../campaigns-types"
import { CampaignBadge } from "../list/campaign-badges"
import { PauseResumeCampaignMenuItems } from "../shared/campaign-shared-actions"
import { DialogExportCSV } from "./dialog-export-csv"
import { DialogImportCSV } from "./dialog-import-csv"
import { StatusStatsDrawer } from "./status-stats-drawer"
import { useStudentsSearch } from "./students-search"
import { StudentsSearchBar } from "./students-search-bar"
import { StudentsTable } from "./students-table"

export function ViewCampaignPage() {
  const { campaign_id } = useParams()
  const { t } = useTranslation()

  if (!campaign_id) throw new Error("Missing campaign_id")

  const { limit, page } = usePaginationParams()
  const searchState = useStudentsSearch()
  const sortState = useTableSorting({
    id: "latest_contacted_at",
    direction: "desc",
  })
  const studentsQuery = useFetchStudentsByCampaign({
    campaign_id,
    page,
    limit,
    searchOptions: searchState.searchOptions,
    sortOptions: sortState.sortOptions,
  })

  return (
    <main className="pt-10">
      <div className="container flex flex-col gap-y-8">
        <div className="flex flex-col gap-y-4">
          <PageBreadcrumb
            items={[
              { label: t("campaigns.details.viewList"), path: "/campaigns" },
              { label: campaign_id },
            ]}
          />
          <Header campaign_id={campaign_id} studentsQuery={studentsQuery} />
        </div>
        <div className="flex flex-col gap-4">
          <h2 className="text-xl">{t("entries.title")}</h2>
          <ViewCampaignStudents
            campaign_id={campaign_id}
            sortState={sortState}
            studentsQuery={studentsQuery}
            searchState={searchState}
          />
        </div>
      </div>
    </main>
  )
}

function Header({
  campaign_id,
  studentsQuery,
}: {
  campaign_id: string
  studentsQuery: ReturnType<typeof useFetchStudentsByCampaign>
}) {
  const { t } = useTranslation()
  const { data, error, isFetching } = useFetchCampaign(campaign_id)

  if (isFetching) {
    return (
      <HeaderLayout
        campaign_id={campaign_id}
        dropdownMenu={<Button isLoading variant="outline" />}
        summary={<div className="italic text-muted-foreground">—</div>}
        statsArea={<StudentCountsCard isLoading />}
      />
    )
  }

  if (error) {
    return (
      <AlertLoadingError
        error={error}
        title="Unable to load campaign details"
      />
    )
  }

  const campaign = data?.data
  if (!campaign) throw new Error("Missing campaign")

  return (
    <HeaderLayout
      campaign_id={campaign_id}
      dropdownMenu={
        <CampaignDropdownMenu
          campaign={campaign}
          studentsQuery={studentsQuery}
        />
      }
      summary={
        <div className="flex items-center gap-2">
          <div>
            {t("campaigns.details.lastUpdate")}:{" "}
            {format(new Date(campaign.updated_at), "yyyy/MM/dd")}
          </div>
          <div>•</div>
          <div>
            <CampaignBadge status={getCampaignStatus(campaign)} />
          </div>
        </div>
      }
      statsArea={<StudentCountsCard campaign={campaign} />}
    />
  )
}

function HeaderLayout({
  campaign_id,
  dropdownMenu,
  statsArea,
  summary,
}: {
  campaign_id: string
  dropdownMenu?: React.ReactNode
  statsArea?: React.ReactNode
  summary: React.ReactNode
}) {
  const { t } = useTranslation()
  const { canEditCampaigns } = usePermissions()

  return (
    <div className="flex w-full justify-between">
      <div className="flex flex-col gap-3">
        {summary}
        <h1 className="font-h2-bold mb-2 text-neutral-700">{campaign_id}</h1>
        {canEditCampaigns && (
          <div className="flex gap-4">
            <Link
              to={`/campaigns/${campaign_id}/edit`}
              className={buttonVariants()}
            >
              {t("global.button.edit")}
            </Link>
            {dropdownMenu}
          </div>
        )}
      </div>
      {statsArea}
    </div>
  )
}

function CampaignDropdownMenu({
  campaign,
  studentsQuery,
}: {
  campaign: CampaignDetails
  studentsQuery: ReturnType<typeof useFetchStudentsByCampaign>
}) {
  const { t } = useTranslation()
  const { showModal } = useModal()
  const queryClient = useQueryClient()

  const onStartOrPause = () => {
    queryClient.invalidateQueries({
      queryKey: ["campaigns", campaign.campaign_id],
    })
  }

  async function handleExportCSV() {
    const result = await showModal<boolean>((close) => (
      <DialogExportCSV campaign_id={campaign.campaign_id} close={close} />
    ))
    if (!result) return
  }

  async function handleImportCSV() {
    await showModal<boolean>((close) => (
      <DialogImportCSV campaign_id={campaign.campaign_id} close={close} />
    ))
    studentsQuery.refetch() // always refresh the student list after import
  }

  const students = studentsQuery.data?.data.students || [] // Export CSV available only if there are students in the page

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          {t("global.button.moreActions")}
          <ChevronDownIcon />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuItem
          onClick={handleExportCSV}
          disabled={students.length === 0}
        >
          {t("campaigns.details.exportCSV.action")}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleImportCSV}>
          {t("campaigns.details.importCSV.action")}
        </DropdownMenuItem>
        <PauseResumeCampaignMenuItems
          campaign={campaign}
          onEdit={onStartOrPause}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

function StudentCountsCard({
  campaign,
  isLoading,
}: {
  campaign?: CampaignDetails
  isLoading?: boolean
}) {
  const { t } = useTranslation()

  const total = campaign ? getTotalStudents(campaign) : 0
  const todayCount = campaign ? getNumberOfStudentsToBeCalledToday(campaign) : 0

  return (
    <Card className="min-w-[300px]">
      <div className="p-4">
        <div className="grid grid-cols-2 gap-x-8 gap-y-4">
          <div className="flex items-center">
            {t("campaigns.details.currentNumbers")}
          </div>

          <div>
            {campaign && (
              <StatusStatsDrawer
                campaign_id={campaign.campaign_id}
                total={total}
              />
            )}
          </div>

          <PeopleCount
            label={t("campaigns.list.studentCount")}
            count={total}
            isLoading={isLoading}
          />

          <PeopleCount
            label={t("campaigns.list.studentsToBeCalled")}
            count={todayCount}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Card>
  )
}

export function PeopleCount({
  count,
  isLoading,
  label,
}: {
  count?: number | null
  isLoading?: boolean
  label: string
}) {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <PeopleCountWrapper label={label}>
        <Skeleton className="mt-4 h-6 w-16" />
      </PeopleCountWrapper>
    )
  }

  return (
    <PeopleCountWrapper label={label}>
      {count ? (
        t("global.peopleCount", { count })
      ) : (
        <span className="text-neutral-500">—</span>
      )}
    </PeopleCountWrapper>
  )
}

function PeopleCountWrapper({
  children,
  label,
}: PropsWithChildren<{ label: string }>) {
  return (
    <div>
      <div className="text-sm text-muted-foreground">{label}</div>
      <div className="text-2xl">{children}</div>
    </div>
  )
}

function ViewCampaignStudents({
  campaign_id,
  searchState,
  sortState,
  studentsQuery,
}: {
  campaign_id: string
  searchState: ReturnType<typeof useStudentsSearch>
  sortState: ReturnType<typeof useTableSorting>
  studentsQuery: ReturnType<typeof useFetchStudentsByCampaign>
}) {
  const { page } = usePaginationParams()
  const { onChange, searchOptions } = searchState
  const { sortOptions, toggleSort } = sortState
  const { data, error, isFetching } = studentsQuery

  if (error) {
    return <AlertLoadingError error={error} title="Unable to load students" />
  }

  const { students, total } = data?.data || {}

  return (
    <div className="flex flex-col gap-4">
      <StudentsSearchBar searchOptions={searchOptions} onChange={onChange} />
      <StudentsTable
        campaign_id={campaign_id}
        students={students}
        total={total}
        page={page}
        isLoading={isFetching}
        sortOptions={sortOptions}
        toggleSort={toggleSort}
      />
    </div>
  )
}
