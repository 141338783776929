import { useTranslation } from "react-i18next"

import { AlertLoadingError } from "@/components/ui/alert"
import { Combobox } from "@/components/ui/combobox"

import { useFetchAllFilterOptions } from "../api/filter-options"
import { LoadingFilter } from "../pickers/generic-filter-picker"

type Props = {
  availableChannelIds?: number[]
  onChange: (value: number) => void
  value: number | null
}

export function ChannelPicker({ availableChannelIds, onChange, value }: Props) {
  const { t } = useTranslation()
  const { data, error, isLoading } = useFetchAllFilterOptions()

  if (isLoading) {
    return <LoadingFilter />
  }

  if (error) {
    return <AlertLoadingError error={error} title="Unable to load channels" />
  }

  const channels = data?.data?.channels_questions_answers || []
  const availableChannels =
    availableChannelIds && availableChannelIds.length > 0
      ? channels.filter((channel) =>
          availableChannelIds.includes(channel.channel_id),
        )
      : channels

  const options = availableChannels.map((channel) => ({
    value: channel.channel_id,
    label: channel.channel_title,
  }))

  return (
    <Combobox
      options={options}
      value={value}
      onChange={onChange}
      placeholder={t("campaigns.filters.channels.placeholder")}
      size="lg"
    />
  )
}
