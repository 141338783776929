import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Check, ChevronsUpDown } from "lucide-react"

import { AlertLoadingError } from "@/components/ui/alert"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { cn } from "@/lib/utils"

import { UniversityGroup } from "../api/filter-options"
import { FilterSelectionUniversity } from "../campaigns-types"
import { LoadingFilter } from "../pickers/generic-filter-picker"
import { useFetchUniversityGroups } from "./universities-hooks"

type Props = {
  filters?: FilterSelectionUniversity[]
  onChange: (value: string[]) => void
  values: string[]
}

export function UniversityGroupPicker({ filters, onChange, values }: Props) {
  const { t } = useTranslation()
  const { error, groups, isLoading } = useFetchUniversityGroups()

  if (isLoading) {
    return <LoadingFilter />
  }

  if (error) {
    return (
      <AlertLoadingError error={error} title="Unable to load filter options" />
    )
  }

  const filteredGroups = groups.filter(isGroupIncludedPredicate(filters))

  return (
    <UniversityGroupCombobox
      groups={filteredGroups}
      value={values[0]}
      onChange={(value) => onChange([value])}
      placeholder={t("campaigns.filters.noFilterSelected")}
    />
  )
}

const isGroupIncludedPredicate =
  (filters?: FilterSelectionUniversity[]) => (group: UniversityGroup) => {
    if (group.group_name === "No Group") {
      return false
    }

    if (!filters || filters.length === 0) {
      return true
    }

    return filters
      .filter(
        ({ operator, type }) => type === "group" && operator === "include",
      )
      .some((filter) => filter.values.includes(group.group_name))
  }

type ComboboxProps = {
  groups: UniversityGroup[]
  onChange: (value: string) => void
  placeholder?: string
  value: string | null
}

function UniversityGroupCombobox({
  groups,
  onChange,
  placeholder = "Select option...",
  value,
}: ComboboxProps) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [localValue, setValue] = useState(value || groups[0].group_name)

  function handleSelect(selectedValue: string) {
    if (value !== selectedValue) {
      onChange(selectedValue)
      setOpen(false)
    }
  }

  const currentOption =
    value !== null && groups.find((group) => group.group_name === value)

  const previewGroup = groups.find((group) => group.group_name === localValue)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("w-96 justify-between")}
        >
          {currentOption ? currentOption.group_name : placeholder}
          <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={cn("w-[42rem] overflow-hidden p-0")}
        align="start"
      >
        <Command value={localValue} onValueChange={(v) => setValue(v)}>
          <CommandInput placeholder={t("global.search.action")} />
          <div className="grid max-h-[300px] grid-cols-2 divide-x">
            <div className="overflow-y-auto">
              <CommandList className="overflow-y-auto">
                <CommandEmpty>{t("global.search.noItemsFound")}</CommandEmpty>
                <CommandGroup className="">
                  {groups.map((group) => {
                    const isSelected = value === group.group_name
                    const isPreviewed = localValue === group.group_name

                    return (
                      <CommandItem
                        key={group.group_name}
                        value={group.group_name}
                        keywords={group.universities}
                        onSelect={handleSelect}
                      >
                        <Check
                          className={cn(
                            "mr-2 size-4",
                            isSelected ? "opacity-100" : "opacity-0",
                          )}
                        />
                        {group.group_name}
                        <Badge
                          variant={isPreviewed ? "default" : "outline"}
                          className="ml-2"
                        >
                          {group.universities.length}
                        </Badge>
                      </CommandItem>
                    )
                  })}
                </CommandGroup>
              </CommandList>
            </div>
            <div className="flex max-h-[300px] flex-col">
              {previewGroup && <PreviewUniversityGroup group={previewGroup} />}
            </div>
          </div>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

function PreviewUniversityGroup({ group }: { group: UniversityGroup }) {
  return (
    <>
      <div className="bg-neutral-200 px-4 py-2">{group.group_name}</div>
      <ul className="grow list-disc overflow-y-scroll p-4 pl-10">
        {group.universities.map((university) => (
          <li className="text-muted-foreground" key={university}>
            {university}
          </li>
        ))}
      </ul>
    </>
  )
}
