import * as React from "react"
import { useTranslation } from "react-i18next"
import { cva, VariantProps } from "class-variance-authority"
import { Check, ChevronsUpDown, X } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { cn } from "@/lib/utils"

// eslint-disable-next-line react-refresh/only-export-components
export const comboboxVariants = cva([], {
  variants: {
    size: {
      sm: "w-48", // 192px
      md: "w-52", // 208px
      "campaign-filter": "w-campaign-filter",
      lg: "w-96",
      university: "w-university-picker",
    },
  },
  defaultVariants: {
    size: "md",
  },
})

export type ComboboxOptions<T extends number | string> = {
  label: string
  value: T
}

type Props<
  T extends number | string,
  AllowReset extends boolean = false,
> = VariantProps<typeof comboboxVariants> & {
  allowReset?: AllowReset
  onChange: AllowReset extends true
    ? (value: T | null) => void
    : (value: T) => void
  options: ComboboxOptions<T>[]
  placeholder?: string
  value: T | null
}

/**
 * A component to select a single value from a list of options displayed in a popover,
 * with a search input to filter the options
 */
export function Combobox<
  T extends number | string,
  AllowReset extends boolean = false,
>({
  allowReset = false as AllowReset,
  onChange,
  options,
  placeholder = "Select option...",
  size,
  value,
}: Props<T, AllowReset>) {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const isNumber = typeof options[0]?.value === "number"

  // <Command> `onSelect` callback only handles string values
  function handleSelect(selectedValue: string) {
    const parsedValue = (isNumber ? Number(selectedValue) : selectedValue) as T
    if (value === parsedValue) {
      return // the selected value cannot be removed
    } else {
      onChange(parsedValue)
      setOpen(false)
    }
  }

  function handleReset(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation()
    if (allowReset) {
      ;(onChange as (value: T | null) => void)(null)
      setOpen(false)
    }
  }

  const currentOption =
    value !== null && options.find((option) => option.value === value)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("justify-between", comboboxVariants({ size }))}
        >
          <div className="truncate">
            {currentOption ? currentOption.label : placeholder}
          </div>
          {allowReset && value !== null ? (
            <div role="button" tabIndex={0} onClick={handleReset}>
              <X className="ml-2 size-4 shrink-0 opacity-50" />
            </div>
          ) : (
            <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn(comboboxVariants({ size }), "p-0")}>
        <Command>
          <CommandInput placeholder={t("global.search.action")} />
          <CommandList>
            <CommandEmpty>{t("global.search.noItemsFound")}</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option.value}
                  value={option.value.toString()}
                  keywords={[option.label]}
                  onSelect={handleSelect}
                >
                  <Check
                    className={cn(
                      "mr-2 size-4",
                      value === option.value ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
