import { ColumnGroupDef } from "@/components/DashboardTable"
import { TooltipWrapper } from "@/components/ui/tooltip"
import { formatSecondsToTime } from "@/helpers/dateFormat"
import i18n, { resolveLanguage } from "@/helpers/i18n"
import { formatFullName } from "@/helpers/names"
import { isNull, isNullish } from "@/helpers/typeguards"

import { Decoder } from "./value"

export function formatValue(
  value: unknown,
  decimalPlaces: number,
  isPercentage?: boolean,
) {
  if (typeof value !== "number") {
    return "-"
  }
  if (value === 0) return 0
  if (isPercentage) {
    return (value * 100).toFixed(decimalPlaces) + "%"
  }

  return value.toFixed(decimalPlaces)
}

const cellRenderers = {
  ratio:
    <T extends Decoder.BaseMetricsItem>(key: keyof T["metricData"]) =>
    (data: T) =>
      <>{formatValue(data.metricData[key], 2, true)}</>,
  average:
    <T extends Decoder.BaseMetricsItem>(key: keyof T["metricData"]) =>
    (data: T) =>
      <>{formatValue(data.metricData[key], 2)}</>,
  total:
    <T extends Decoder.BaseMetricsItem>(key: string & keyof T["metricData"]) =>
    (data: T) => {
      if (key === "TotalProcessingTime" && !isNull(data.metricData[key])) {
        return <>{formatSecondsToTime(data.metricData[key])}</>
      }

      return <>{data.metricData[key] ?? "-"}</>
    },
}

function cellRender<T extends Decoder.BaseMetricsItem>(
  metricType: Decoder.CellRendererKey,
  key: string & keyof T["metricData"],
): (data: T) => JSX.Element {
  if (!cellRenderers[metricType]) {
    return cellRenderers.total<T>(key)
  }

  return cellRenderers[metricType]<T>(key)
}

function formatColumnsFromMetadata(
  parentMetadata: Decoder.ParentMetaData,
  metadata: Decoder.MetaData,
) {
  const lang = resolveLanguage()

  return Object.keys(parentMetadata).map((key) => {
    const metaItems = Object.values(metadata).filter(
      (item) => item.parentId === key,
    )
    if (!metaItems.length) {
      throw new Error("No subcolumns found for parent metadata key")
    }

    const cols = getGroupSubColumns(metaItems)
    const hasSubHeaders = cols.some((col) => col.subHeader)

    return {
      header: parentMetadata[key][lang], // Explicitly type parentMetadata[key] to avoid implicit 'any' type error
      headerTooltip: hasSubHeaders ? undefined : cols[0].subHeaderTooltip, // Only show tooltip if there are no sub-headers
      cols,
    }
  })
}

function getGroupSubColumns(metaItems: Decoder.MetaDataItem[]) {
  const lang = resolveLanguage()

  return metaItems.map((item) => {
    return {
      id: item.metricId,
      subHeader: item.name[lang],
      subHeaderTooltip: item.additionalInfo[lang],
      cell: cellRender(
        item.metricType["en"] as Decoder.CellRendererKey,
        item.metricId,
      ),
    }
  })
}

function formatAgentColumnsFromMetadata<T extends Decoder.BaseMetricsItem>(
  metadata: Decoder.MetaData,
  parentMetadata: Decoder.ParentMetaData,
  firstColumn: ColumnGroupDef<T>,
) {
  const lang = resolveLanguage()
  const columnGroups: ColumnGroupDef<T>[] = [firstColumn]

  Object.keys(metadata).map((key) => {
    const header =
      parentMetadata[metadata[key].parentId][lang] +
      " " +
      metadata[key].name[lang]
    const column = {
      header: header,
      headerTooltip: metadata[key].additionalInfo[lang],
      cols: [
        {
          id: metadata[key].metricId,
          cell: cellRender(
            metadata[key].metricType["en"] as Decoder.CellRendererKey,
            metadata[key].metricId,
          ),
        },
      ],
    }
    columnGroups.push(column)
  })

  return columnGroups
}

function matchAgentName(
  agentMetrics: Decoder.AgentMetricsItem[] = [],
  agentMetadata: Decoder.AgentInfoMetadata,
) {
  return agentMetrics.map((metric) => {
    const agent = agentMetadata[metric.agentId]

    return {
      ...metric,
      fullName: isNullish(agent) ? (
        <TooltipWrapper tooltip={metric.agentId}>
          {i18n.t("performance.table.deletedUser")}
        </TooltipWrapper>
      ) : (
        formatFullName(
          agent ? agent.FirstName : null,
          agent ? agent.LastName : null,
        )
      ),
    }
  })
}

export {
  formatAgentColumnsFromMetadata,
  formatColumnsFromMetadata,
  matchAgentName,
}
