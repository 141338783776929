import { EllipsisIcon } from "lucide-react"

import { Button } from "@/components/ui/button"

import { PhoneNumberValidationResult } from "../campaigns-types"
import { FilterControlLabel } from "../pickers/generic-filter-picker"

type Props = {
  onEdit: () => void
  values: PhoneNumberValidationResult[]
}

export function AddStudentsControl({ onEdit, values }: Props) {
  const labels = values?.map((result) => result.phone_number)

  return (
    <Button
      onClick={onEdit}
      variant="outline"
      className="w-campaign-filter justify-between"
      size="sm"
    >
      <FilterControlLabel filterKey="selected_phone_numbers" labels={labels} />
      <EllipsisIcon className="size-4 opacity-50" />
    </Button>
  )
}
