import React from "react"

import { Card } from "@/components/ui/card"

import { StudentProfileSection } from "../shared/student-profile-section"
import { TwoColsLayout } from "../shared/two-cols-layout"
import { CaseWithActivityFeed, StudentProfile } from "../student-details-api"
import { StudentHistorySection } from "./history"

/**
 * Student page default layout (idle state), made of two rows
 * the first row being split into 2 columns
 */
export function StudentDefaultView({
  callPanel,
  caseData,
  studentProfile,
}: {
  callPanel: React.ReactNode
  caseData: CaseWithActivityFeed
  studentProfile: StudentProfile
}) {
  return (
    <>
      <TwoColsLayout
        main={
          <Card className="w-full">
            <StudentProfileSection
              studentProfile={studentProfile}
              caseData={caseData}
            />
          </Card>
        }
        aside={callPanel}
      />
      <StudentHistorySection caseData={caseData} />
    </>
  )
}
