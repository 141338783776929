import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { Wifi } from "lucide-react"

const Spinner: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <div className="flex size-full justify-center pb-10 pt-40">
      <div className="font-body-large-bold flex max-w-md flex-col items-center gap-y-6 text-neutral-700">
        <Wifi className="animate-pulse text-primary-300" size={48} />
        <div className="text-center">
          <p>{t("global.slowConnectionTitle")}</p>
          <p>{t("global.slowConnectionSubtitle")}</p>
        </div>
      </div>
    </div>
  )
}

export default Spinner
