import { useTranslation } from "react-i18next"

import { ColumnGroupDef, DashboardTable } from "@/components/DashboardTable"
import Spinner from "@/components/Spinner"
import { AlertLoadingError } from "@/components/ui/alert"
import { isUndefined } from "@/helpers/typeguards"
import { useAgentProfile } from "@/hooks/agent-profile"

import { formatColumnsFromMetadata } from "../column-utils"
import { useFetchCampaignPerformance, useFilterParams } from "../hooks"
import OperationRow from "../operation-row"
import SearchLink from "../search-link"
import { Decoder } from "../value"
import ExportButton from "./export-button"

export function CampaignMetricsContent() {
  const { isManager } = useAgentProfile()

  const [searchParams, updateQueryParams] = useFilterParams()

  const { data, error, isError, isLoading } = useFetchCampaignPerformance(
    searchParams.dateRange,
  )

  const displayMetrics = isUndefined(searchParams.campaign_id)
    ? data?.data.metrics || []
    : data?.data.metrics.filter(
        (item) => item.campaignId === searchParams.campaign_id,
      ) || []

  const campaignOptions = (data?.data.metrics || []).map((campaign) => ({
    value: campaign.campaignId,
    label: campaign.campaignId,
  }))

  return (
    <div>
      <OperationRow
        onDateRangeChange={(val) => updateQueryParams({ dateRange: val })}
        options={campaignOptions}
        dateRange={searchParams.dateRange}
        filterId={searchParams.campaign_id || null}
        onFilter={(val) => updateQueryParams({ campaign_id: val ?? undefined })}
        operationButton={
          isManager && <ExportButton initDateRange={searchParams.dateRange} />
        }
      />
      {isLoading && <Spinner />}
      {isError && (
        <AlertLoadingError error={error} title="Unable to load the metrics" />
      )}
      {data && (
        <CampaignPerformanceTable
          metadata={data?.data.metadata}
          parentMeta={data?.data.parentMetricsMetadata}
          metrics={displayMetrics}
        />
      )}
    </div>
  )
}

function CampaignPerformanceTable({
  metadata,
  metrics,
  parentMeta,
}: {
  metadata: Decoder.MetaData
  metrics: Decoder.CampaignMetricItem[]
  parentMeta: Decoder.ParentMetaData
}) {
  const { t } = useTranslation()

  const firstColumn: ColumnGroupDef<Decoder.CampaignMetricItem> = {
    header: t("performance.table.campaignName"),
    cols: [
      {
        id: "campaign",
        subHeader: "",
        cell: (data) => (
          <SearchLink pathname={data.campaignId}>{data.campaignId}</SearchLink>
        ),
      },
    ],
  }
  const columnGroups = [
    firstColumn,
    ...formatColumnsFromMetadata(parentMeta, metadata),
  ]

  return (
    <DashboardTable
      getItemId={(item) => item.campaignId}
      columnGroups={columnGroups}
      items={metrics}
    />
  )
}
