import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useAtom, useAtomValue } from "jotai"
import { RESET } from "jotai/utils"

import { routesConfig } from "@/config/routes"
import {
  currentContactStatusTypeAtom,
  currentContactTypeAtom,
  inManualCallAtom,
  pseudoACWAtom,
} from "@/helpers/atoms"

type UseManualCallHookType = {
  resetInManualCall: () => void
  resetManualCallWhenOnline: (newState: connect.AgentStateType) => void
  shouldClearAllStateInLocalStorage: boolean
}

const useManualCallAtom = (): UseManualCallHookType => {
  const [inManualCall, setInManualCall] = useAtom(inManualCallAtom)
  const currentContactType = useAtomValue(currentContactTypeAtom)
  const currentContactStatusType = useAtomValue(currentContactStatusTypeAtom)
  const pseudoACW = useAtomValue(pseudoACWAtom)

  const location = useLocation()

  const isACW =
    (currentContactType === connect.ContactType.VOICE &&
      currentContactStatusType === connect.ContactStateType.ENDED) ||
    pseudoACW
  /* Keep the processing time when logging out if we have a voice contact in progress (ACW state)
   in the automatic call panel */
  const withContactInProgress =
    isACW && location.pathname === routesConfig.AUTOMATIC_CALL_PANEL
  const shouldClearAllState = !withContactInProgress

  const shouldEraseInManualCall =
    location.pathname === routesConfig.AUTOMATIC_CALL_PANEL && inManualCall

  const resetInManualCall = () => {
    setInManualCall(RESET)
  }

  /* Erase isManualCall value when switching to the automatic calling panel
     to prevent bugs in the `contact.onDestroy()` event handler */
  const resetManualCallWhenOnline = (newState: connect.AgentStateType) => {
    if (newState === connect.AgentStateType.ROUTABLE) {
      resetInManualCall()
    }
  }

  // Erase inManualCall in the automatic call panel; `inManualCall` value is only useful in the manual call panel
  useEffect(() => {
    if (shouldEraseInManualCall) {
      resetInManualCall()
    }
  }, [shouldEraseInManualCall]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    resetManualCallWhenOnline,
    shouldClearAllStateInLocalStorage: shouldClearAllState,
    resetInManualCall,
  }
}

export { useManualCallAtom }
