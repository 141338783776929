/**
 * Create a store to ensure only a single Promise runs at a given time
 * Useful for Refresh Token requests that can be called in the same time from multiple places
 **/
export function createSinglePromiseStore<T>(taskFn: () => Promise<T>) {
  let isPending = false
  let currentPromise: Promise<T> | null = null

  return {
    async fetch() {
      if (isPending) {
        return currentPromise
      }
      try {
        isPending = true
        currentPromise = taskFn()
        const result = await currentPromise

        return result
      } finally {
        isPending = false
      }
    },
  }
}
