import { useRef } from "react"
import { config } from "@config/index"
import { sleep, throwErrorOnDelay } from "@helpers/other"
import { hasValue, isNullish } from "@helpers/typeguards"

import {
  CustomerProfileClientContextType,
  OptionsType,
} from "./useCustomerProfileContext"

// Couldn't find typings for amazon-connect-customer-profiles
type CustomerProfileClient = unknown

type ClientRefType = {
  client: CustomerProfileClient | null
  isClientReady: boolean
}

type CustomerProfileClientHookType = CustomerProfileClientContextType

const useCustomerProfileClientHook = (): CustomerProfileClientHookType => {
  const customerProfileInstance = `https://${config.connectInstanceAlias}.my.connect.aws`

  // Using a ref to access value in the AWS Connect handers (outside or React)
  const clientRef = useRef<ClientRefType>({
    isClientReady: false,
    client: null,
  })

  const initializeClient = async (): Promise<void> => {
    if (clientRef.current.isClientReady) {
      throw new Error("client-already-initialized")
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const customerProfileClient = new connect.CustomerProfilesClient(
      customerProfileInstance,
    )
    clientRef.current.client = customerProfileClient

    // Make queries until client is ready
    // give up after 5 attempts
    let i = 0
    const maxI = 10

    while (i < maxI) {
      if (!(await checkIsClientReady())) {
        i++

        if (i === maxI) {
          throw new Error("failure-to-initialize-client")
        }

        await sleep(i * 1000)
        continue
      }

      clientRef.current.isClientReady = true
      break
    }
  }

  // Remove the customer client from the DOM; the library https://github.com/amazon-connect/amazon-connect-customer-profiles
  // is a wrapper to an iframe; it adds an iframe in the <body> element, outside of the react root element
  const unmountClient = () => {
    const bodyElement = document.getElementById("customerprofiles-container")

    if (hasValue(bodyElement)) {
      bodyElement.remove()
    }

    clientRef.current = {
      isClientReady: false,
      client: null,
    }
  }

  const checkIsClientReady = async (): Promise<boolean> => {
    if (isNullish(clientRef.current.client)) {
      return false
    }

    if (clientRef.current.isClientReady) {
      return true
    }

    try {
      const queryOptions = {
        DomainName: config.customerProfilesDomain,
        KeyName: "_phone",
        Values: ["1111"],
      }

      const response = await Promise.race([
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        clientRef.current.client.searchProfiles(queryOptions),
        throwErrorOnDelay(5 * 1000),
      ])

      if (response?.status === 200) {
        clientRef.current.isClientReady = true

        return true
      }

      return false
    } catch {
      return false
    }
  }

  const searchProfiles = async (options: OptionsType): Promise<unknown> => {
    if (
      !clientRef.current.isClientReady ||
      isNullish(clientRef.current.client)
    ) {
      throw new Error("client-not-ready")
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const profiles = await clientRef.current.client.searchProfiles(options)

    const {
      data: { Items },
    } = profiles

    return Items
  }

  return {
    initializeClient,
    checkIsClientReady,
    searchProfiles,
    unmountClient,
  }
}

export { useCustomerProfileClientHook }
