import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "@components/ui/button"
import { cn } from "@helpers/classNames"

import { useTruncatedParagraphHook } from "./hook"

interface Props {
  text: string
}

const TruncatedParagraph: FunctionComponent<Props> = ({ text }) => {
  const { formattedText, formattedTextRef, isOpen, setIsOpen, showButton } =
    useTruncatedParagraphHook(text)
  const { t } = useTranslation()

  return (
    <div className={cn("font-body-medium-regular w-full text-neutral-700")}>
      <div
        className={cn([isOpen ? "line-clamp-none" : "line-clamp-3"])}
        ref={formattedTextRef}
      >
        <p>{formattedText}</p>
      </div>

      {showButton && (
        <Button
          variant="ghost"
          className="hover:bg-transparent font-label-small text-primary-300 hover:text-primary-400"
          type="button"
          size="xs"
          onClick={() => setIsOpen((value) => !value)}
        >
          {isOpen ? t("global.button.showLess") : t("global.button.showMore")}
        </Button>
      )}
    </div>
  )
}

export default TruncatedParagraph
