import { useTranslation } from "react-i18next"
import { LoaderCircle } from "lucide-react"

import { AlertLoadingError } from "@/components/ui/alert"
import { Card } from "@/components/ui/card"
import { formatSecondsToTime } from "@/helpers/dateFormat"

import { formatValue } from "../column-utils"
import { useFetchAgentsPerformance } from "../hooks"

export default function AgentMetricsCardRow({
  agentResult,
}: {
  agentResult: ReturnType<typeof useFetchAgentsPerformance>
}) {
  const { t } = useTranslation()
  const { data, error, isError, isLoading } = agentResult

  if (isLoading) {
    return <LoaderCircle className="animate-spin text-primary-300" />
  }
  if (isError) {
    return (
      <AlertLoadingError error={error as Error} title="Unable to load data" />
    )
  }
  const metric = data?.data?.metrics[0]?.metricData || {}

  return (
    <div className="flex justify-start gap-8">
      <Card className="flex items-center p-4">
        <div className="w-40 text-sm font-light text-neutral-600">
          {t("performance.detail.creditForEntryConfirmedCVR")}
        </div>
        <div className="min-w-4 text-lg font-bold">
          {formatValue(metric.CreditForEntryConfirmedCVR, 2, true)}
        </div>
      </Card>
      <Card className="flex items-center p-4">
        <div className="w-40 text-sm font-light text-neutral-600">
          {t("performance.detail.callsPerHour")}
        </div>
        <div className="min-w-4 text-lg font-bold">
          {formatValue(metric.CallsPerHour, 2)}
        </div>
      </Card>
      <Card className="flex items-center p-4">
        <div className="w-40 text-sm font-light text-neutral-600">
          {t("performance.detail.totalProcessingTime")}
        </div>
        <div className="min-w-4 text-lg font-bold">
          {formatSecondsToTime(metric.TotalProcessingTime)}
        </div>
      </Card>
    </div>
  )
}
