import { FunctionComponent, useEffect, useRef } from "react"

import { cn } from "@/helpers/classNames"

type FileUploaderProps = {
  accept?: string
  className?: string
  disabled?: boolean
  fileName?: string
  onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}

// FileUploader is a component that provides a user interface for uploading files.
// It supports both clicking to select a file and dragging and dropping a file.
// When a file is selected, the handleUpload function is called with the file as its argument.
const FileUploader: FunctionComponent<FileUploaderProps> = ({
  accept,
  className,
  fileName,
  onUpload,
  ...rest
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  // Reset the file input value when the fileName prop changes to an empty string.
  useEffect(() => {
    if (!fileName && fileInputRef.current) {
      fileInputRef.current.value = ""
    }
  }, [fileName])

  return (
    <div
      className={cn(
        "flex cursor-pointer items-center space-x-6 rounded-lg border border-neutral-300",
        className,
      )}
    >
      <input
        type="file"
        accept={accept}
        onChange={(event) => onUpload(event)}
        disabled
        className="w-full
        text-neutral-700 file:mr-4
        file:rounded-l-md file:border-0
        file:bg-neutral-300 file:px-4
        file:py-2
        file:text-sm file:font-semibold
        hover:file:bg-neutral-400
      "
        {...rest}
      />
    </div>
  )
}

export default FileUploader
