/* eslint-disable react-refresh/only-export-components */
import { Dialog } from "@components/ui/dialog"
import { atom, useAtom, useSetAtom } from "jotai"

type ModalInputValue = unknown
type ModalResolvedValue = ModalInputValue | null // a modal can resolve with any type of value, boolean are appropriate for basic OK/Cancel modals
type ResolveFn = (value: ModalResolvedValue) => void

type ModalState<T extends ModalResolvedValue> = {
  renderContent: () => React.ReactNode
  resolve: (value: T) => void
}
const modalAtom = atom<ModalState<ModalResolvedValue> | null>(null)

export function ModalRoot() {
  const [modal, setModal] = useAtom(modalAtom)

  return (
    <Dialog
      open={Boolean(modal?.renderContent)}
      onOpenChange={() => {
        // resolve with `null` when the modal is closed by clicking outside, from the X button, or by pressing ESC
        modal?.resolve(null)
        setModal(null)
      }}
    >
      {modal ? modal.renderContent() : null}
    </Dialog>
  )
}

export function useModal() {
  const setModal = useSetAtom(modalAtom)

  function showModal<T extends ModalInputValue>(
    renderFn: (close: (value: T | null) => void) => React.ReactNode,
  ) {
    return new Promise<T | null>((resolve) => {
      setModal({
        renderContent: () =>
          renderFn((value: T | null) => {
            setModal(null)
            resolve(value)
          }),
        resolve: resolve as ResolveFn, // TODO: fix this type casting
      })
    })
  }

  return {
    showModal,
  }
}
